import React, { useState } from "react";
import "./sidebar.css";
import SideBarItems from "./SidebarItems";
import name from "../../../assets/images/name.svg";
import {
  faTruck,
  faSignOutAlt,
  faColumns,
  faUserAlt,
  faTruckLoading,
  faCog,
  faTicket,
  faTruckFront,
  faTruckFast,
  faMessage,
  faClosedCaptioning,
  faUserGroup,
  faCheckDouble,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideBar = () => {
  const [activeKeys, setActiveKeys] = useState([""]);
  const handleSelect = (eventKey) => setActiveKeys(eventKey);

  return (
    <ul className="sidebar">
      <div className="logo">
        <img src={name} alt="" width="130px" />
      </div>

      <div className="Menu">
        <SideBarItems title="Dashboard" to="" icon={faColumns} />
        <SideBarItems title="live" to="live" icon={faMessage} />

        <Accordion activeKey={activeKeys} onSelect={handleSelect}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <li className="menuitem bar">
                <FontAwesomeIcon icon={faUserAlt} />
                <span className="menuItemtitle">Users</span>
              </li>
            </Accordion.Header>
            <Accordion.Body className="items">
              <SideBarItems
                title="users"
                to="users"
                icon={faUserAlt}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="driver"
                to="driver"
                icon={faTruck}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="groups"
                to="groups"
                icon={faUserGroup}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Messages"
                to="Messages"
                icon={faMessage}
                popIcon={faArrowUpRightFromSquare}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion activeKey={activeKeys} onSelect={handleSelect}>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <li className="menuitem bar">
                <FontAwesomeIcon icon={faTruckLoading} />
                <span className="menuItemtitle">Loads</span>
              </li>
            </Accordion.Header>
            <Accordion.Body className="loadsitems">
              <SideBarItems
                title="loads"
                to="loads"
                icon={faTruckLoading}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Pending 1"
                to="pendingloads"
                icon={faTruckFront}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Pending 2"
                to="pendingloadstwo"
                icon={faTruckFront}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Loading"
                to="Loading"
                icon={faTruckFast}
                popIcon={faArrowUpRightFromSquare}
              />

              <SideBarItems
                title="Closed"
                to="Closed"
                icon={faTruckFast}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Transit"
                to="Transit"
                icon={faTruckFast}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Complete"
                to="Complete"
                icon={faCheckDouble}
                popIcon={faArrowUpRightFromSquare}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion activeKey={activeKeys} onSelect={handleSelect}>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <li className="menuitem bar">
                <FontAwesomeIcon icon={faTicket} />
                <span className="menuItemtitle">Tickets</span>
              </li>
            </Accordion.Header>
            <Accordion.Body className="items">
              <SideBarItems
                title="tickets"
                to="tickets"
                icon={faTicket}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Open"
                to="open"
                icon={faTicket}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Pending"
                to="pending"
                icon={faTicket}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Closed"
                to="closed"
                icon={faTicket}
                popIcon={faArrowUpRightFromSquare}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion activeKey={activeKeys} onSelect={handleSelect}>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <li className="menuitem bar">
                <FontAwesomeIcon icon={faCog} />
                <span className="menuItemtitle">Settings</span>
              </li>
            </Accordion.Header>
            <Accordion.Body>
              <SideBarItems
                title="usertypes"
                to="usertypes"
                icon=""
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="trucks"
                to="trucks"
                icon=""
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="trailers"
                to="trailers"
                icon=""
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="tickettypes"
                to="tickettypes"
                icon=""
                popIcon={faArrowUpRightFromSquare}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <SideBarItems
          title="logout"
          to="/"
          logoutButton={true}
          icon={faSignOutAlt}
        />
        {/* <div className="last-child">
          <Accordion
            activeKey={activeKeys}
            onSelect={handleSelect}
            className="settingsAccordion"
          > */}
        {/* <Accordion.Item eventKey="3"> */}
        {/* <Accordion.Header>
                <li className="menuitem bar">
                  <FontAwesomeIcon icon={faCog} />
                  <span className="menuItemtitle">Settings</span>
                </li>
              </Accordion.Header> */}
        {/* <Accordion.Body>
                <SideBarItems
                  title="usertypes"
                  to="usertypes"
                  icon=""
                  popIcon={faArrowUpRightFromSquare}
                />
                <SideBarItems
                  title="trucks"
                  to="trucks"
                  icon=""
                  popIcon={faArrowUpRightFromSquare}
                />
                <SideBarItems
                  title="trailers"
                  to="trailers"
                  icon=""
                  popIcon={faArrowUpRightFromSquare}
                />
                <SideBarItems
                  title="tickettypes"
                  to="tickettypes"
                  icon=""
                  popIcon={faArrowUpRightFromSquare}
                />
              </Accordion.Body> */}
        {/* </Accordion.Item> */}

        {/* </Accordion> */}

        {/* </div> */}
      </div>
    </ul>
  );
};

export default SideBar;
