import React, { useEffect, useState } from "react";
import { DashCard } from "./DashCard";
import "./content.css";
import "./userCard/ContentComp.css";
import {
  faCheckToSlot,
  faTruckLoading,
  faTicket,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Card from "react-bootstrap/Card";
import UserContent from "./userCard/UserContent";
import TruckData from "./userCard/TruckData";
import {
  getAllUserCount,
  getAssignedDriver,
  getTicketsCount,
  getloadsCount,
} from "../../services/dashboard";
import { registerDevice } from "../../services/users";
import { setFCMToken, getFCMToken, getjwt } from "../../services/authServices";
import { getToken } from "@firebase/messaging";
import { messaging } from "../../../FCM/firebase";
import LoadSpinner from "../../Components/LoaderPage/LoadSpinner";

const MainContent = () => {
  const [gotFCMToken, setGotFCMToken] = useState(false);
  const [loads, setLoads] = useState(0);
  const [users, setUsers] = useState(0);
  const [onGoing, setOnGoing] = useState(0);
  const [assignDriverCount, setAssignDriverCount] = useState(0);

  useEffect(() => {
    async function registerNotificationToken() {
      let data = {};

      data["device_id"] = "chrome";

      const token = getjwt();
      data["token_data"] = JSON.parse(token);

      let fcmToken = getFCMToken();
      if (!fcmToken) {
        const token = await getToken(messaging);
        console.log("FCM Token:", token);
        setFCMToken(token);

        data["fcm_token"] = token;
      } else {
        data["fcm_token"] = JSON.parse(fcmToken);
      }

      console.log(data);

      setGotFCMToken(true);
      registerDevice(data);
    }

    getloadsCount().then((res) => setLoads(res.data.load_count));
    getAllUserCount().then((res) => setUsers(res.data.user_count));
    getTicketsCount().then((res) => setOnGoing(res.data.ticket_count));
    getAssignedDriver().then((res) =>
      setAssignDriverCount(res.data.driver_count)
    );

    registerNotificationToken();
  }, []);

  if (!gotFCMToken) {
    return <LoadSpinner showTitle={true} />;
  }

  return (
    <div className="container-fluid">
      <Card className="mb-3 border-0 shadow-sm">
        <Card.Body>
          <div className="d-sm-flex align-items-center justify-content-between">
            <h1 className="h5 mb-0 text-gray-800 fw-bold">Dashboard</h1>
          </div>
        </Card.Body>
      </Card>
      <div className="row">
        <DashCard
          value={loads}
          text="LOADS"
          icon={faTruckLoading}
          cssStyle="bg-info border-0"
          to="loads"
        />
        <DashCard
          value={assignDriverCount}
          text=" ASSIGNED"
          icon={faCheckToSlot}
          cssStyle="bg-success border-0"
          to="driver"
        />
        <DashCard
          value={users}
          text="USERS"
          icon={faUser}
          cssStyle="bg-warning border-0"
          to="users"
        />
        <DashCard
          value={onGoing}
          text="TICKETS"
          icon={faTicket}
          cssStyle="bg-danger border-0"
          to="tickets"
        />
      </div>
      <div className="row">
        <UserContent />
        <TruckData />
      </div>
    </div>
  );
};

export default MainContent;
