import React from "react";
import { Card, Col, Table } from "react-bootstrap";
import CardHeader from "./../../Components/CardHeader";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getDriverMessageGroupById } from "../../services/DriverGroupMessage";
import Skeleton from "react-loading-skeleton";

const headerButtons = [
  {
    title: "User",
    buttonTitle: "Back to messages",
    buttonUrl: "../Messages",
    icon: faArrowLeft,
  },
];
const DriverMessageDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      getDriverMessageGroupById(id).then((res) => {
        setData(res.data);
        setIsLoading(false);
      });
    }, 1000);
  }, [id]);

  return (
    <React.Fragment>
      {isloading ? (
        <div className="container-fluid">
          <CardHeader
            headerItems={headerButtons}
            title="Group Message Detail"
          />

          <div className="row">
            <Col sm={12} lg={12} xs={12}>
              <Card className="mb-3 p-2 border-0 shadow-sm">
                <Card.Body>
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <Skeleton width={250} height={24} />
                        </td>
                        <td colSpan={2}>
                          <Skeleton width={250} height={24} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton width={250} height={24} />
                        </td>
                        <td colSpan={2}>
                          <Skeleton width={250} height={24} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton width={250} height={24} />
                        </td>
                        <td colSpan={2}>
                          <Skeleton width={250} height={24} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton width={250} height={24} />
                        </td>
                        <td colSpan={2}>
                          <Skeleton width={250} height={24} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <CardHeader
            headerItems={headerButtons}
            title="Group Message Detail"
          />

          <div className="row">
            <Col sm={12} lg={12} xs={12}>
              <Card className="mb-3 p-2 border-0 shadow-sm">
                <Card.Body>
                  <Table borderless hover>
                    <tbody>
                      <tr>
                        <td>
                          <b>Group Name</b>
                        </td>
                        <td colSpan={2}>{data.drivers_groups_name}</td>
                      </tr>

                      <tr>
                        <td>
                          <b>Message Title</b>
                        </td>
                        <td colSpan={2}>{data.title}</td>
                      </tr>

                      <tr>
                        <td>
                          <b>Message</b>
                        </td>
                        <td colSpan={2}>{data.message}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Date</b>
                        </td>
                        <td colSpan={2}>{data.created_at}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DriverMessageDetail;
