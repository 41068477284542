import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import "./forms.css";
import { Col, Image } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useNavigate, useParams } from "react-router-dom";
import { toastSuccess } from "../../Components/ToastNotification";
import CardHeader from "../../Components/CardHeader";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserById, updateUser } from "../../services/users";
import { getUserType } from "../../services/usertypeApi";
import RegisterValidater from "../../Components/validators/RegisterValidater";
import { getjwt } from "../../services/authServices";
import Skeleton from "react-loading-skeleton";
import undraw_profile from "../../../assets/images/undraw_profile.svg";
import { phoneRegex } from "../../utils";

const headerButtons = [
  { buttonTitle: "Back to users", buttonUrl: "../users", icon: faArrowLeft },
];

const UserUpdateForm = () => {
  const { id } = useParams();
  const navgate = useNavigate();
  const [data, setData] = useState([]);

  const [image, setImage] = useState("");
  const [userType, setUserType] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    let inputs = {
      ...data,
      [name]: value,
    };
    setFormErrors(RegisterValidater(inputs));
  };
  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };

  useEffect(() => {
    setTimeout(() => {
      getUserType().then((res) => setUserType(res.data));
      getUserById(id).then((res) => {
        setData(res.data);
        setIsLoading(false);
      });
    }, 1000);
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt();
    let form_data;
    if (
      data.first_name !== "" &&
      data.last_name !== "" &&
      data.phone_number !== "" &&
      phoneRegex.test(data.phone_number) &&
      data.email !== "" &&
      data.status !== "" &&
      data.user_type !== ""
    ) {
      form_data = new FormData();
      if (image) form_data.append("image", image);
      form_data.append("first_name", data.first_name);
      form_data.append("last_name", data.last_name);
      form_data.append("phone_number", data.phone_number);
      form_data.append("email", data.email);
      form_data.append("status", data.status);
      form_data.append(
        "user_type",
        typeof data.user_type === "object" ? data.user_type.id : data.user_type
      );
      form_data.append("token_data", JSON.parse(token));
      updateUser(id, form_data).then((res) => {
        toastSuccess("user edited Successfully");
        navgate("../users");
      });
    } else {
      setFormErrors(RegisterValidater(data));
    }
  };

  return (
    <React.Fragment>
      {isloading ? (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="EDIT USER" />

          <Card className="mb-3 border-0 shadow-sm">
            <Card.Body>
              <div className="form-wrapper">
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">
                        <Skeleton width={80} />
                      </Form.Label>
                      <Skeleton height={40} />
                    </Form.Group>
                    <Form.Group className="mb-3" as={Col}>
                      <Form.Label className="fw-600">
                        <Skeleton width={80} />
                      </Form.Label>
                      <Skeleton height={40} />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">
                        <Skeleton width={80} />
                      </Form.Label>
                      <Skeleton height={40} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label className="fw-600">
                        <Skeleton width={80} />
                      </Form.Label>
                      <Skeleton height={40} />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">
                        <Skeleton width={60} />
                      </Form.Label>
                      <Skeleton height={40} />
                      <Skeleton circle={true} width={100} height={100} />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">
                        <Skeleton width={60} />
                      </Form.Label>
                      <Skeleton height={40} />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">
                        <Skeleton width={60} />
                      </Form.Label>
                      <Skeleton height={40} />
                    </Form.Group>
                  </Row>

                  <Skeleton width={100} height={40} />
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="EDIT USER" />

          <Card className="mb-3 border-0 shadow-sm">
            <Card.Body>
              <div className="form-wrapper">
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group className="mb-3" as={Col}>
                      <Form.Label className="fw-600">Last name</Form.Label>
                      <Form.Control
                        type="text"
                        name="last_name"
                        placeholder="Enter Your Last name"
                        onChange={handleChange}
                        value={data.last_name}
                        isInvalid={formErrors.last_name}
                      />
                      {formErrors.last_name && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.last_name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">First name</Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        placeholder="Enter Your First name"
                        onChange={handleChange}
                        value={data.first_name}
                        isInvalid={formErrors.first_name}
                      />
                      {formErrors.first_name && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.first_name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Phone no:</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone_number"
                        placeholder="Enter the mobile number"
                        onChange={handleChange}
                        value={data.phone_number}
                        isInvalid={formErrors.phone_number}
                      />
                      {formErrors.phone_number && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.phone_number}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label className="fw-600">Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email Address"
                        name="email"
                        onChange={handleChange}
                        value={data.email}
                        isInvalid={formErrors.email}
                      />
                      {formErrors.email && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.email}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Image</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder=""
                        name="image"
                        onChange={handleImage}
                        isInvalid={formErrors.image}
                      />
                      {formErrors.image && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.image}
                        </Form.Control.Feedback>
                      )}
                      <Image
                        className="form_image"
                        alt="No Preview"
                        width={100}
                        height={100}
                        src={data.image || undraw_profile}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Status</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="status"
                        onChange={handleChange}
                        value={data.status}
                        isInvalid={formErrors.status}
                      >
                        <option value="">Select the status</option>
                        <option value="0">Not Active</option>
                        <option value="1">Active</option>
                      </Form.Select>
                      {formErrors.status && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.status}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Usertype</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="user_type"
                        onChange={handleChange}
                        value={data.user_type ? data.user_type["id"] : ""}
                        isInvalid={formErrors.user_type}
                      >
                        <option value="">Select the UserType</option>
                        {userType.map((item, idx) => (
                          <option key={idx} value={item.id}>
                            {item.user_type}
                          </option>
                        ))}
                      </Form.Select>
                      {formErrors.user_type && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.user_type}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Button
                    variant="primary"
                    type="submit"
                    className="shadow rounded-5"
                  >
                    <FontAwesomeIcon icon={faCircleCheck} /> Update user
                  </Button>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </React.Fragment>
  );
};

export default UserUpdateForm;
