import {
  faPaperPlane,
  faPlusCircle,
  faUser,
  faClock,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect, useContext } from "react";
import undraw_profile from "../../../assets/images/undraw_profile.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddModal from "../../Components/Model";
import { useState } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { LOC, APIURL } from "../../../constants/constants";
import { getCurrentUser } from "../../services/authServices";
import { getMessagesByRoomId } from "../../services/chatURLs";
import Skeleton from "react-loading-skeleton";
import getTimeFormated from "../../utils/timeFormat";
import {
  generateUserImageLink,
  placeholderForCompletedLoad,
  placeholderForDeletedDriver,
  placeholderForInactiveDriver,
} from "../../utils";
import { userContext } from "../../context/UserContext";
import { Role } from "../../../constants/role";
import {
  getNotificationList,
  readNotificationByPost,
} from "../../services/users";

const LiveInput = ({
  chatselected,
  ChangeOnlineUser,
  roomId,
  loadId,
  refreshCallback,
}) => {
  const [show, setShow] = useState(false);
  const [inputtext, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState();
  const [isloading, setIsLoading] = useState(true);
  const { role, setNotifications } = useContext(userContext);

  const WSSTART = window.location.protocol === "https:" ? "wss://" : "ws://";

  const WebSocketEndpoint = WSSTART + LOC + "/ws/chat/";

  const user = getCurrentUser();

  const setRef = useCallback((node) => {
    if (node) {
      node.scrollIntoView({ smooth: true });
    }
  }, []);

  const handleClose = (value) => {
    setShow(value);
  };

  const handleTextMessage = (e) => {
    setInputText(e.target.value);
  };

  const handleMessage = (e) => {
    e.preventDefault();

    if (inputtext.trim().length == 0) return;

    const members = [user.user_id, chatselected.user.id];
    const data = {
      room: chatselected.user.id,
      user: user.user_id,
    };
    socket.send(
      JSON.stringify({
        action: "message",
        members: members,
        message: inputtext.trim(),
        roomId: roomId,
        user: data.user,
        room: data.room,
      })
    );

    setTimeout(() => setInputText(""), 300);
  };

  const getChatClassName = (userId) => {
    return user.user_id === userId
      ? "chat-message-right pd-4"
      : "chat-message-left pb-4";
  };

  const getMessagesFromRoom = (id) => {
    if (id !== undefined) {
      getMessagesByRoomId(id).then((res) => {
        setMessages(res.data);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    //read notification
    readNotificationByPost({
      type: "chat_message",
      content: loadId,
    }).then((res) => {
      getNotificationList().then((res) => {
        console.log(res.data.data);
        setNotifications(res.data.data);
      });
    });

    setInputText("");

    getMessagesFromRoom(loadId);

    const newsocket = new WebSocket(WebSocketEndpoint + `${loadId}/`);
    setSocket(newsocket);
    newsocket.onopen = () => {
      console.log("chat connected");
    };
    newsocket.onclose = () => {
      console.log("connection closed");
    };
    newsocket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data.roomId === roomId) {
        if (data.action === "message") {
          setMessages((prevMsg) => [...prevMsg, data]);
        }

        refreshCallback();

        //read notification
        readNotificationByPost({
          type: "chat_message",
          content: loadId,
        }).then((res) => {
          refreshCallback();

          getNotificationList().then((res) => {
            console.log(res.data.data);
            setNotifications(res.data.data);
          });
        });
      }
      if (data.action === "onlineUser") {
        ChangeOnlineUser(data.userList);
      }
    };

    return () => {
      newsocket.close();
    };
  }, [loadId]);

  const getPlaceholder = () => {
    if (!chatselected) return "";

    if (chatselected.user.status == 1) {
      return "Enter Message...";
    } else {
      if (chatselected.original_driver_id > -1) {
        return placeholderForCompletedLoad;
      } else {
        if (chatselected.user.status == 0) {
          return placeholderForInactiveDriver;
        } else {
          return placeholderForDeletedDriver;
        }
      }
    }
  };

  return (
    <React.Fragment>
      <section className="chat">
        <div className="header-chat">
          <Row className="align-items-center">
            <Col md={8} sm={4}>
              <div className="icon">
                <div style={{ display: "flex", width: 50, height: 50 }}>
                  <img
                    src={
                      chatselected.user.image
                        ? generateUserImageLink(chatselected.user.image)
                        : undraw_profile
                    }
                    alt="userImage"
                    style={{
                      objectFit: "cover",
                      width: 50,
                      height: 50,
                      borderRadius: 25,
                    }}
                  />
                </div>
                <div className="name">
                  <b>
                    {chatselected &&
                      `${chatselected.user.first_name} ${chatselected.user.last_name} (${chatselected.user.user_type.user_type})`}
                  </b>
                  <small>Position: {chatselected?.position ?? ""}</small>
                  <small>Load ID: {chatselected?.load_id ?? ""}</small>
                </div>
              </div>
            </Col>
            {(role == Role.Super || role == Role.Admin) && (
              <Col md={4} sm={8}>
                <ul className="chat-list">
                  <li className="chat-list-item">
                    <Button variant="none" className="icon_bubble">
                      <FontAwesomeIcon
                        className=" clickable msg "
                        icon={faPlusCircle}
                        visibility={
                          chatselected.user.status ? "block" : "hidden"
                        }
                        onClick={() => setShow(true)}
                      />
                    </Button>

                    {chatselected && show && (
                      <AddModal
                        showMod={show}
                        onClose={handleClose}
                        roomId={roomId}
                        driver={chatselected.user.id}
                        manager={chatselected.manager.id}
                        member={chatselected.member}
                        refreshCallback={refreshCallback}
                      />
                    )}
                  </li>
                </ul>
              </Col>
            )}
          </Row>
        </div>
        {isloading ? (
          <div className="messagebox">
            <div className="messagebox-wrapper">
              <div className="chat-messages p-4">
                <Skeleton width={70} />
                <Skeleton width={70} />
              </div>
            </div>
          </div>
        ) : (
          <div className="messagebox">
            <div className="messagebox-wrapper">
              <div className="chat-messages p-4">
                {messages.map((obj, idx) => {
                  const lastMessage = messages.length - 1 === idx;
                  return (
                    <React.Fragment key={idx}>
                      <div
                        ref={lastMessage ? setRef : null}
                        className={getChatClassName(obj.sender)}
                      >
                        <div className="chat-avatar">
                          <img
                            src={
                              obj.userImage
                                ? generateUserImageLink(obj.userImage)
                                : undraw_profile
                            }
                            alt="userImage"
                            style={{ objectFit: "cover" }}
                            width="50"
                            height="50"
                          />
                          <div className="text-muted chat_name">
                            {obj.userName ?? "Deleted user"}
                          </div>
                        </div>
                        <div className="chat-right">
                          <div className="flex-shrink-1 rounded py-2 px-3 mr-3 ">
                            <p className="mb-0">{obj.message}</p>
                            <p className="chat-time mb-0">
                              <FontAwesomeIcon
                                icon={faClock}
                                className="align-middle"
                              />
                              <span className="align-middle">
                                {" " + getTimeFormated(obj.timestamp)}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      {user.user_id === obj.sender ? (
                        <div className="chat-read">
                          {obj.is_read ? (
                            <FontAwesomeIcon icon={faCheckDouble} />
                          ) : (
                            <FontAwesomeIcon
                              icon={faCheckDouble}
                              className="tickicon"
                            />
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <div className="footer-chat">
          <Form onSubmit={handleMessage}>
            <Row>
              <Col xs={12} md={10}>
                <Form.Control
                  value={inputtext}
                  placeholder={getPlaceholder()}
                  type="text"
                  disabled={!(chatselected && chatselected.user.status == 1)}
                  onChange={handleTextMessage}
                />
              </Col>
              <Col xs={6} md={1}>
                <Button
                  variant="none"
                  className="send"
                  type="submit"
                  disabled={inputtext.trim().length == 0}
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </section>
    </React.Fragment>
  );
};

export default LiveInput;
