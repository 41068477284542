import React from 'react'

const Welcome = () => {
  return (
 
        <div className='welcome-page'>

        <h1 >No Chats, </h1>
        <h3>Please Select a chat start messaging...</h3>
        </div>

  )
}

export default Welcome