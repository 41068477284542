import React from "react";
import { Card, Col, Image, Table, Row, Badge } from "react-bootstrap";
import { useParams } from "react-router-dom";
import undraw_profile from "../../../src/assets/images/undraw_profile.svg";
import CardHeader from "../Components/CardHeader";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { DashCard } from "../Dashboard/Content/DashCard";
import { faTicket, faTruckLoading } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useEffect } from "react";
import { getDriverDetails } from "../services/users";
import Skeleton from "react-loading-skeleton";
import { getDriverLoads, getDriverTickets } from "../services/dashboard";

const headerButtons = [
  {
    title: "User",
    buttonTitle: "Back",
    buttonUrl: "../driver",
    icon: faArrowLeft,
  },
];

const DriverDetailView = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [driverloads, setDriverLoads] = useState([]);
  const [driverTickets, setDriverTickets] = useState([]);
  const [isloading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      getDriverDetails(id).then((res) => {
        setData(res.data);
        setLoading(false);
      });
      getDriverLoads(id).then((res) => setDriverLoads(res.data.load_count));
      getDriverTickets(id).then((res) =>
        setDriverTickets(res.data.ticket_count)
      );
    }, 1000);
  }, [id]);

  return (
    <React.Fragment>
      {isloading ? (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title=" Driver Details" />

          <Row>
            <Col lg={4} sm={12} xs={12} md={4}>
              <Card className="mb-3 border-0 shadow-sm rounded-2">
                <Card.Body>
                  <Skeleton circle={true} width={100} height={100} />

                  <div className="col-12">
                    <Card.Title className="mt-3 mb-2">
                      <Skeleton width={127} height={24} />
                    </Card.Title>
                    <Card.Text>
                      <Skeleton width={123} height={24} />
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={8} sm={12} xs={12} md={8}>
              <Card className="mb-3 border-0 shadow-sm rounded-2 p-4">
                <Card.Body>
                  <Table borderless>
                    <tbody>
                      <tr>
                        <td>
                          <Skeleton height={24} />
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <Skeleton height={24} />
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <Skeleton height={24} />
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>
                            <Skeleton height={24} />
                          </b>
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            <Skeleton height={24} />
                          </b>
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>
                            <Skeleton height={24} />
                          </b>
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            <Skeleton height={24} />
                          </b>
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            <Skeleton height={24} />
                          </b>
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            <Skeleton height={24} />
                          </b>
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            <Skeleton height={24} />
                          </b>
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <Skeleton height={24} />
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  {/* <Row className="mt-5">
              
                <Skeleton  width={201} height={151}  />
                <Skeleton width={201} height={151} />
                </Row> */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title=" Driver Details" />

          <Row>
            <Col lg={4} sm={12} xs={12} md={4}>
              <Card className="mb-3 border-0 shadow-sm rounded-2">
                <Card.Body>
                  <Card.Img
                    style={{ width: "100px", borderRadius: "10px" }}
                    src={data.image ? data.image : undraw_profile}
                    alt="Generic placeholder image"
                    as={Image}
                    fluid
                  />

                  <div className="col-12">
                    <Card.Title className="mt-3 mb-2">
                      <strong>
                        {data.user &&
                          data.user.last_name + " " + data.user.first_name}
                      </strong>
                    </Card.Title>
                    <Card.Text>
                      <Badge bg="danger p-2  mb-4 shadow">
                        Manager :{" "}
                        {data.manager &&
                          data.manager.status == 1 &&
                          data.manager.last_name +
                            " " +
                            data.manager.first_name}
                      </Badge>
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={8} sm={12} xs={12} md={8}>
              <Card className="mb-3 border-0 shadow-sm rounded-2 p-4">
                <Card.Body>
                  <Table borderless hover>
                    <tbody>
                      <tr>
                        <td>
                          <b>Name</b>
                        </td>
                        <td colSpan={2}>
                          {data.user &&
                            data.user.last_name + " " + data.user.first_name}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Email</b>
                        </td>
                        <td colSpan={2}>{data.user && data.user.email}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>User Type</b>
                        </td>
                        <td colSpan={2}>
                          {data.user && data.user.user_type.user_type}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Phone No</b>
                        </td>
                        <td colSpan={2}>
                          {data.user && data.user.phone_number}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Address</b>
                        </td>
                        <td colSpan={2}>{data.address}</td>
                      </tr>

                      <tr>
                        <td>
                          <b>Truck</b>
                        </td>
                        <td colSpan={2}>
                          {data.truck && data.truck.truck_name}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Plate Number</b>
                        </td>
                        <td colSpan={2}>
                          {data.truck && data.truck.plate_number}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Trailer</b>
                        </td>
                        <td colSpan={2}>
                          {data.trailer && data.trailer.trailer_name}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Plate Number</b>
                        </td>
                        <td colSpan={2}>
                          {data.trailer && data.trailer.plate_number}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Private Number</b>
                        </td>
                        <td colSpan={2}>{data.private_number}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>CNP Number</b>
                        </td>
                        <td colSpan={2}>{data.cnp}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Status</b>
                        </td>
                        <td colSpan={2}>
                          {" "}
                          <Badge bg="success p-2  shadow">
                            {data.user && data.user.status === 1
                              ? "Active"
                              : "Non Active"}
                          </Badge>{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <Row className="mt-5">
                    <DashCard
                      value={driverloads}
                      text=" LOADS"
                      icon={faTruckLoading}
                      cssStyle="bg-info border-0"
                      to="loads"
                    />
                    <DashCard
                      value={driverTickets}
                      text="TICKET"
                      icon={faTicket}
                      cssStyle="bg-danger border-0"
                      to="tickets"
                    />
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  );
};

export default DriverDetailView;
