import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import Table from "../../Components/Tables";
import CardHeader from "./../../Components/CardHeader";
import { faPlaneUp } from "@fortawesome/free-solid-svg-icons";
import TableActionButton from "../../Components/TableActionButton";
import {
  deleteDriverMessageGroup,
  getDriverMessageGroups,
} from "../../services/DriverGroupMessage";

const headerButtons = [
  {
    buttonTitle: "Send Message",
    buttonUrl: "/dashboard/add/Messages",
    icon: faPlaneUp,
  },
];

const DriverGroupMessage = () => {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);

  const groupMessageList = () => {
    getDriverMessageGroups().then((res) => {
      setData(res.data.sort((a, b) => b.id - a.id));
      setPending(false);
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      groupMessageList();
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const columns = [
    {
      name: "No.",
      selector: (row) => data.indexOf(row) + 1,
      sortable: true,
    },
    {
      name: "Group name",
      selector: (row) => row.drivers_groups_name,
      sortable: true,
    },
    {
      name: "Message title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.created_at,
    },
    {
      name: "",
      cell: (row) => (
        <TableActionButton
          detailPath={`/dashboard/detail/Messages/${row.id}`}
          UpdatePath={`/dashboard/update/Messages/${row.id}`}
          deleteFun={deleteDriverMessageGroup}
          deleteId={row.id}
          refreshdata={groupMessageList}
        />
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="Drivers Group Message" />
      <Card className="mb-3 border-0 shadow-sm p-2 rounded-5">
        <Card.Body>
          <div className="row">
            <Table columns={columns} row={data} processDataStatus={pending} />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DriverGroupMessage;
