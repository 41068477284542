import React, { useEffect, useState } from "react";
import SideBar from "../../pages/Dashboard/Sidebar/Sidebar";
import "./AdminLayout.css";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NavbarTop from "./../Dashboard/NavbarTop/NavbarTop";
import NavBarComp from "../context/NavBarContext";
import { userContext } from "./../context/UserContext";
import { getCurrentUser } from "../services/authServices";
import { getUserById } from "../services/users";
import { Role } from "../../constants/role";

function AdminLayout() {
  const [role, setRole] = useState();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const userData = getCurrentUser();
    getUserById(userData.user_id).then((res) => {
      console.log(res.data);
      if (res.data.is_superuser) {
        setRole(Role.Super);
      } else {
        const userType = (
          res.data.user_type?.user_type ?? Role.Manager
        ).toLowerCase();
        setRole(userType);
      }
    });
  }, []);

  return (
    <NavBarComp>
      <userContext.Provider value={{ role, notifications, setNotifications }}>
        <div className="wrapper">
          <SideBar />
          <ToastContainer theme="colored" />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <NavbarTop />
              <Outlet />
            </div>
          </div>
        </div>
      </userContext.Provider>
    </NavBarComp>
  );
}

export default AdminLayout;
