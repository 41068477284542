import React, { useState, useEffect } from "react";
import { Card, Col, Table } from "react-bootstrap";
import CardHeader from "./../../Components/CardHeader";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { getDriverGroupById } from "../../services/DriverGroups";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

const headerButtons = [
  {
    title: "User",
    buttonTitle: "Back to groups",
    buttonUrl: "../groups",
    icon: faArrowLeft,
  },
];

const DriverGroupDetails = () => {
  const { id } = useParams();
  const [driverGroup, setDriverGroup] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      getDriverGroupById(id).then((res) => {
        setDriverGroup(res.data);
        setIsLoading(false);
      });
    }, 1000);
  }, [id]);

  const drivers = driverGroup.drivers;
  const messages = driverGroup.messages;

  return (
    <React.Fragment>
      {isloading ? (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="Group Detail" />

          <div className="row">
            <Col sm={12} lg={12} xs={12}>
              <Card className="mb-3 p-2 border-0 shadow-sm">
                <Card.Body>
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <Skeleton width={250} height={24} />
                        </td>
                        <td colSpan={2}>
                          <Skeleton width={250} height={24} />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <Skeleton width={250} height={24} />
                        </td>
                        <td colSpan={2}>
                          <Skeleton width={250} height={24} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton width={250} height={24} />
                        </td>
                        <td colSpan={2}>
                          <Skeleton width={250} height={24} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={12} lg={12} xs={12}>
              <Card className="mb-3 p-2 border-0 shadow-sm">
                <Card.Header className="text-start">
                  <h5>
                    <Skeleton width={200} height={16} />
                  </h5>
                </Card.Header>
                <Card.Body>
                  <Table striped bordered>
                    <tbody>
                      <tr>
                        <td>
                          <Skeleton height={100} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={12} lg={12} xs={12}>
              <Card className="mb-3 p-2 border-0 shadow-sm">
                <Card.Header className="text-start">
                  <h5>
                    <Skeleton width={200} height={16} />
                  </h5>
                </Card.Header>
                <Card.Body>
                  <Table striped bordered>
                    <tbody>
                      <tr>
                        <td>
                          <Skeleton height={100} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="Group Detail" />

          <div className="row">
            <Col sm={12} lg={12} xs={12}>
              <Card className="mb-3 p-2 border-0 shadow-sm">
                <Card.Body>
                  <Table borderless hover>
                    <tbody>
                      <tr>
                        <td>
                          <b>Group Name</b>
                        </td>
                        <td colSpan={2}>{driverGroup.title}</td>
                      </tr>

                      <tr>
                        <td>
                          <b>Total members</b>
                        </td>
                        <td colSpan={2}>
                          {driverGroup.drivers && driverGroup.drivers.length}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Total messages sent</b>
                        </td>
                        <td colSpan={2}>
                          {driverGroup.messages && driverGroup.messages.length}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={12} lg={12} xs={12}>
              <Card className="mb-3 p-2 border-0 shadow-sm">
                <Card.Header className="text-start">
                  <h5>
                    <b>Drivers</b>(
                    {driverGroup.drivers && driverGroup.drivers.length})
                  </h5>
                </Card.Header>
                <Card.Body>
                  <Table striped bordered hover>
                    <tbody>
                      <tr key="drivers-header">
                        <th>ID</th>
                        <th>Name</th>
                      </tr>
                      {drivers &&
                        drivers.map((item, idx) => (
                          <tr key={idx}>
                            <td className="fw-700">
                              {drivers.indexOf(item) + 1}
                            </td>
                            <td>
                              {item.user.first_name + " " + item.user.last_name}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={12} lg={12} xs={12}>
              <Card className="mb-3 p-2 border-0 shadow-sm">
                <Card.Header className="text-start">
                  <h5>
                    <b>Sent Messages</b>(
                    {driverGroup.messages && driverGroup.messages.length})
                  </h5>
                </Card.Header>
                <Card.Body>
                  <Table striped bordered hover>
                    <tbody>
                      <tr key="messages-header">
                        <th>ID</th>
                        <th>Title</th>
                        <th>Message</th>
                        <th>Creator</th>
                        <th>Created Date</th>
                      </tr>
                      {messages && messages.length == 0 && (
                        <tr>
                          <td colSpan={5}>No messages!</td>
                        </tr>
                      )}
                      {messages &&
                        messages
                          .sort((a, b) => b.id - a.id)
                          .map((item, idx) => (
                            <tr key={idx}>
                              <td className="fw-700">
                                {messages.indexOf(item) + 1}
                              </td>
                              <td>{item.title}</td>
                              <td>{item.message}</td>
                              <td>
                                {item.created_by.first_name +
                                  " " +
                                  item.created_by.last_name}
                              </td>
                              <td>
                                {moment(item.created_at).format(
                                  "YYYY-MM-DD h:mm a"
                                )}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DriverGroupDetails;
