import React from "react";
import { Alert } from "react-bootstrap";

const ErrorMessage = ({ err, neterr = " ", onClose }) => {
  return (
    <Alert variant="danger" onClose={onClose} dismissible>
      <p>{err.email}</p>
      <p>{err.password}</p>
      {neterr && <p>{neterr}</p>}
    </Alert>
  );
};

export default ErrorMessage;
