import React, { useContext, useEffect, useState } from "react";
import undraw_profile from "../../../assets/images/undraw_profile.svg";
import { getRooms } from "../../services/chatURLs";
import { generateUserImageLink } from "../../utils";
import { getCurrentUser } from "../../services/authServices";
import { userContext } from "../../context/UserContext";
import { Role } from "../../../constants/role";

const LiveUserList = ({
  chatChange,
  onlineUsers,
  chatRoom,
  chatLoad,
  refreshList,
}) => {
  const [inputtext, setInputText] = useState("");
  const [currentSelected, setCurrentSelected] = useState();
  const [roomlist, setRoomList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const user = getCurrentUser();
  const { role } = useContext(userContext);

  const handleSearch = (e) => {
    setInputText(e.target.value);
  };

  const changeCurrentChat = (item) => {
    chatChange(
      item.load && {
        ...item.load.driver,
        load_id: item.load.id,
        position: item.load.identification_position,
        goods_type: item.load.goods_type,
        member: item.member,
      }
    );
    chatRoom(item.id);
    chatLoad(item.load.id);
    setCurrentSelected(item.id);
  };

  const getList = () => {
    getRooms().then((res) => {
      console.log(res.data);
      if (role == Role.Super || role == Role.Admin) {
        setRoomList(res.data);
      } else {
        const finalList = res.data.filter((item) => {
          if (item.load.driver.manager.id == user.user_id) {
            return true;
          } else {
            const existing = item.member.filter(
              (eachUser) => eachUser.id == user.user_id
            );
            return existing.length > 0 ? true : false;
          }
        });
        setRoomList(finalList);
      }
    });
  };

  useEffect(() => {
    getList();
  }, [refreshList]);

  useEffect(() => {
    currentSelected &&
      roomlist &&
      roomlist.length > 0 &&
      roomlist.map((eachRoom) => {
        if (eachRoom.id == currentSelected) {
          chatChange(
            eachRoom.load && {
              ...eachRoom.load.driver,
              load_id: eachRoom.load.id,
              position: eachRoom.load.identification_position,
              goods_type: eachRoom.load.goods_type,
              member: eachRoom.member,
            }
          );
          chatRoom(eachRoom.id);
          chatLoad(eachRoom.load.id);
        }
      });

    setFilteredData(
      roomlist.filter(
        (item) =>
          JSON.stringify(item.load && item.load.driver.user.last_name)
            .toLowerCase()
            .indexOf(inputtext.toLowerCase()) !== -1
      )
    );
  }, [roomlist]);

  return (
    <section className="discussions">
      <div className="px-4 pt-4 d-none d-md-block">
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <input
              type="text"
              className="form-control my-3"
              placeholder="Search..."
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>
      <h5 className="mb-3 px-3 font-size-16">Recent Chat</h5>
      {filteredData.map((item, idx) => (
        <div key={idx}>
          <div
            className={`discussion ${
              currentSelected === item.id ? "message-active" : " "
            }`}
            onClick={() => changeCurrentChat(item)}
          >
            <div className="photo">
              {onlineUsers.includes(item.load && item.load.driver.user.id) ? (
                <div className="online" />
              ) : (
                ""
              )}

              <img
                src={
                  (item.load &&
                    item.load.driver.user.image &&
                    generateUserImageLink(item.load.driver.user.image)) ||
                  undraw_profile
                }
                alt=""
                width={40}
                height={40}
              />
            </div>
            <div
              className={`text-start ${
                currentSelected === item.id
                  ? "desc-contact-active"
                  : "desc-contact"
              }`}
            >
              <p className="name">
                {item.load &&
                  item.load.driver.user.last_name +
                    " " +
                    item.load.driver.user.first_name}
              </p>
              <p className="message">{item?.latest_message ?? ""}</p>
            </div>
            {/* <Badge bg="danger">5</Badge> */}
          </div>
        </div>
      ))}
    </section>
  );
};

export default LiveUserList;
