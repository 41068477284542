import { faArrowLeft, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import TableActionButton from "../Components/TableActionButton";
import Table from "../Components/Tables";
import CardHeader from "./../Components/CardHeader";
import "../Tickets/tickects.css";
import { deleteTicket, getTickets } from "../services/tickets";
import { useParams } from "react-router-dom";
import { getDriverDetails } from "../services/users";

const DriverTicketsPage = () => {
  const { id } = useParams();
  const [driverInfo, setDriverInfo] = useState(null);

  const headerButtons = [
    {
      title: "User",
      buttonTitle: "Back",
      buttonUrl: `../detail/driver/${id}`,
      icon: faArrowLeft,
    },
  ];

  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);

  const TicketColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Manager",
      selector: (row) =>
        row.manager !== null
          ? row.manager.last_name + " " + row.manager.first_name
          : "No manager",
      sortable: true,
    },
    {
      name: "Driver",
      selector: (row) =>
        row.driver !== null
          ? row.driver.user.last_name + " " + row.driver.user.first_name
          : "No Driver",
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row.note,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.ticket_type.ticket_type,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.ticket_status,
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row) => row.ticket_status.toLowerCase() === "open",
          style: {
            backgroundColor: "rgba(255, 184, 177, 0.9)",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => row.ticket_status.toLowerCase() === "pending",
          style: {
            backgroundColor: "rgba(236, 161, 56, 0.9)",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => row.ticket_status.toLowerCase() === "closed",
          style: {
            backgroundColor: "rgba(115, 236, 174, 0.9)",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },

    {
      name: "",
      cell: (row) => (
        <TableActionButton
          detailPath={`/dashboard/detail/tickets/${row.id}`}
          UpdatePath={`/dashboard/update/tickets/${row.id}`}
          deleteId={row.id}
          refreshdata={refreshTicket}
          deleteFun={deleteTicket}
        />
      ),
    },
  ];

  const refreshTicket = () => {
    getTickets().then((res) => {
      setData(res.data.filter((item) => item.driver && item.driver.id == id));
      setPending(false);
    });
  };

  useEffect(() => {
    if (!id) return;

    const timeout = setTimeout(() => {
      refreshTicket();

      getDriverDetails(id).then((res) => {
        setDriverInfo(res.data);
      });
    }, 300);
    return () => clearTimeout(timeout);
  }, [id]);

  return (
    <div className="container-fluid">
      <CardHeader
        headerItems={headerButtons}
        title={
          driverInfo
            ? `Tickets (Driver: ${driverInfo.user.last_name} ${driverInfo.user.first_name})`
            : "Tickets"
        }
      />
      <Card className="mb-3 border-0 shadow-sm p-2 rounded-5">
        <Card.Body>
          <div className="row">
            <Table
              columns={TicketColumns}
              row={data}
              processDataStatus={pending}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DriverTicketsPage;
