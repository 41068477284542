import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import CardHeader from "./../../Components/CardHeader";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toastError, toastSuccess } from "../../Components/ToastNotification";
import { getTicketById, updateTicket } from "../../services/tickets";
import { getDriver, getManager } from "../../services/users";
import { getTicketTypes } from "../../services/ticketType";
import { getjwt } from "../../services/authServices";
import Skeleton from "react-loading-skeleton";
import Select from "react-select";

const headerButtons = [
  {
    buttonTitle: "Back to tickets",
    buttonUrl: -1,
    icon: faArrowLeft,
  },
];

const ticketValidator = (value) => {
  let errors = {};
  if (!value["title"]) {
    errors.title = "Ticket Name is required";
  }
  if (!value["ticket_status"]) {
    errors.ticket_status = "Ticket Status is required";
  }
  if (!value["driver"]) {
    errors.driver = "driver is required";
  }
  if (!value["manager"]) {
    errors.manager = "manager is required";
  }
  if (!value["ticket_type"]) {
    errors.ticket_type = "Ticket Type is required";
  }
  if (!value["note"]) {
    errors.note = "Note is required";
  }
  return errors;
};

const TicketUpdateForm = () => {
  const { id } = useParams("");
  const navgate = useNavigate();
  const [data, setData] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectedDrivers, setSelectedDrivers] = useState();
  const [manager, setManager] = useState([]);
  const [selectedManager, setSelectedManager] = useState();
  const [formErrors, setFormErrors] = useState([]);
  const [ticketType, setTicketType] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [ticketStatus, setTicketStatus] = useState();

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "red",
      // overwrittes hover style
      "&:hover": {
        borderColor: state.isFocused
          ? "#ddd"
          : isValid
          ? "#ddd"
          : "form-control is-invalid",
      },
    }),
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const inputs = {
      ...data,
      [name]: value,
    };
    setData({ ...data, [name]: value });
    inputs["driver"] = selectedDrivers;
    inputs["manager"] = selectedManager;
    setFormErrors(ticketValidator(inputs));
  };

  useEffect(() => {
    setTimeout(() => {
      getTicketById(id).then((res) => {
        setIsLoading(false);
        setData(res.data);
        setSelectedDrivers(res.data.driver);
        setSelectedManager(res.data.manager);
      });
      getDriver().then((res) =>
        setDrivers(res.data.filter((item) => item.user.status == 1))
      );
      getManager().then((res) =>
        setManager(res.data.filter((item) => item.status == 1))
      );
      getTicketTypes().then((res) => setTicketType(res.data));
    }, 1000);
  }, [id]);

  useEffect(() => {
    if (formErrors && formErrors.driver) {
      setIsValid(false);
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt();
    if (selectedDrivers !== undefined) {
      data["driver"] = selectedDrivers.id;
    }
    if (selectedManager !== undefined) {
      data["manager"] = selectedManager.id;
    }
    if (
      data.title !== "" &&
      data.ticket_status !== "" &&
      data.driver !== "" &&
      data.manager !== "" &&
      data.ticket_type !== "" &&
      data.note !== ""
    ) {
      data["token_data"] = JSON.parse(token);
      data["driver"] = data.driver.id || data.driver;
      data["manager"] = data.manager.id || data.manager;
      data["ticket_type"] = data.ticket_type.id || data.ticket_type;
      data["ticket_status"] = data.ticket_status;
      updateTicket(id, data)
        .then((res) => {
          console.log(res.data);
          toastSuccess("succesfully Updated Tickets");
          navgate("../tickets");
        })
        .catch((err) => {
          let errObj = err.response.data;
          if (errObj) {
            errObj.errors.map((err) => toastError(err.error));
          }
        });
    } else {
      setFormErrors(ticketValidator(data));
    }
  };

  return (
    <React.Fragment>
      {isloading ? (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="UPDATE Tickets" />

          <Card className="mb-3 border-0 shadow-sm rounded-5">
            <Card.Body>
              <div className="form-wrapper">
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Skeleton width={87} height={24} />
                      <Skeleton height={38} />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Skeleton width={87} height={24} />
                      <Skeleton height={38} />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Skeleton width={87} height={24} />
                      <Skeleton height={38} />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Skeleton width={87} height={24} />
                      <Skeleton height={38} />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Skeleton width={87} height={24} />
                      <Skeleton height={38} />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group className="mb-3" as={Col}>
                      <Skeleton width={87} height={24} />
                      <Skeleton height={101} />
                    </Form.Group>
                  </Row>
                  <hr></hr>
                  <Skeleton width={148} height={38} />
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="UPDATE Tickets" />
          <Card className="mb-3 border-0 shadow-sm rounded-5">
            <Card.Body>
              <div className="form-wrapper">
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Title</Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        placeholder="Enter the Ticket Name"
                        onChange={handleChange}
                        value={data.title}
                        className="border-2"
                        isInvalid={formErrors.title}
                      />
                      {formErrors.title && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.title}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Ticket Status</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="ticket_status"
                        onChange={handleChange}
                        defaultValue={data.ticket_status}
                        isInvalid={formErrors.ticket_status}
                      >
                        <option value="open">open</option>
                        <option value="pending">pending</option>
                        <option value="closed">closed</option>
                      </Form.Select>
                      {formErrors.ticket_status && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.ticket_status}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Driver</Form.Label>
                      <Select
                        options={drivers}
                        getOptionLabel={(option) =>
                          `${option.user.last_name} ${option.user.first_name}`
                        }
                        isOptionSelected={(option) =>
                          selectedDrivers === option.id ? true : false
                        }
                        placeholder="Choose Driver"
                        onChange={(value) => {
                          const inputs = {
                            ...data,
                            driver: value,
                          };

                          setData({ ...data, driver: value });
                          inputs["driver"] = value;
                          setFormErrors(ticketValidator(inputs));

                          setSelectedDrivers(value);
                        }}
                        styles={customStyles}
                        defaultValue={data.driver}
                      />

                      {formErrors.driver && (
                        <span className="text-danger">{formErrors.driver}</span>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Manager</Form.Label>
                      <Select
                        options={manager}
                        getOptionLabel={(option) =>
                          `${option.last_name} ${option.first_name}`
                        }
                        isOptionSelected={(option) =>
                          selectedManager === option.id ? true : false
                        }
                        placeholder="Choose Manager"
                        onChange={(value) => {
                          const inputs = {
                            ...data,
                            manager: value,
                          };

                          setData({ ...data, manager: value });
                          inputs["manager"] = value;
                          setFormErrors(ticketValidator(inputs));

                          setSelectedManager(value);
                        }}
                        styles={customStyles}
                        defaultValue={data.manager}
                      />
                      {formErrors.manager && (
                        <span className="text-danger">
                          {formErrors.manager}
                        </span>
                      )}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Ticket Type</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="ticket_type"
                        onChange={handleChange}
                        value={data.ticket_type && data.ticket_type.id}
                        isInvalid={formErrors.ticket_type}
                      >
                        {ticketType.map((item, idx) => (
                          <option key={idx} value={item.id}>
                            {item.ticket_type}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group className="mb-3" as={Col}>
                      <Form.Label className="fw-600">Note</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="note"
                        placeholder="Add Note for ticket "
                        onChange={handleChange}
                        value={data.note}
                        style={{ height: "100px" }}
                        isInvalid={formErrors.note}
                      />
                      {formErrors.note && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.note}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>
                  <hr></hr>
                  <Button
                    variant="primary"
                    type="submit"
                    className="shadow rounded-5"
                  >
                    <FontAwesomeIcon icon={faCircleCheck} /> Update Ticket
                  </Button>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </React.Fragment>
  );
};

export default TicketUpdateForm;
