// firebase.js
import { initializeApp } from "@firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";
import { setFCMToken, getjwt } from "../pages/services/authServices";
import { registerDevice } from "../pages/services/users";
import { useEffect } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyD1gJqU9UVMGRfivsYvoUbRoxP13wFLujs",
  authDomain: "lcl-app-baf1f.firebaseapp.com",
  projectId: "lcl-app-baf1f",
  storageBucket: "lcl-app-baf1f.appspot.com",
  messagingSenderId: "261289825121",
  appId: "1:261289825121:web:77b5ff1ede13f8fb3e6b20",
  measurementId: "G-F16WFTXFLR",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const setupNotifications = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      console.log("Notification permission granted.");
      // Get the FCM token
      setTimeout(async () => {
        const token = await getToken(messaging);
        console.log("FCM Token:", token);
        setFCMToken(token);

        const jwtToken = getjwt();
        if (jwtToken) {
          let data = {};
          data["device_id"] = "chrome";
          data["fcm_token"] = token;
          data["token_data"] = JSON.parse(jwtToken);

          if (token && JSON.parse(jwtToken)) {
            registerDevice(data);
          }
        }
      }, 300);
    } else {
      console.log("Notification permission denied.");
      setTimeout(async () => {
        const token = "not_available";
        console.log("FCM Token:", token);
        setFCMToken(token);

        const jwtToken = getjwt();
        if (jwtToken) {
          let data = {};
          data["device_id"] = "chrome";
          data["fcm_token"] = token;
          data["token_data"] = JSON.parse(jwtToken);

          if (token && JSON.parse(jwtToken)) {
            registerDevice(data);
          }
        }
      }, 300);
    }
    // Handle foreground notifications
  } catch (error) {
    console.error("Error setting up notifications:", error);
  }
};
export { messaging, setupNotifications };
