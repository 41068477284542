import { toast } from "react-toastify";

const toastDark = (text) => toast.dark(text);
const toastInfo = (text) => toast.info(text);
const toastSuccess = (text) => toast.success(text);
const toastWarn = (text) => toast.warn(text);
const toastError = (text) => toast.error(text);
const toastNotification = (title, description, data, onNotificationClick) => toast.info(<div style={{textAlign: 'left'}} onClick={(e) => {
    e.preventDefault();
    onNotificationClick(data);
}}><b>{title}</b><br />{description}</div>)
export { toastInfo, toastSuccess, toastError,toastDark,toastWarn, toastNotification };
