import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Table from "../../Components/Tables";
import CardHeader from "./../../Components/CardHeader";
import { faGroupArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import TableActionButton from "../../Components/TableActionButton";
import {
  deleteDriverGroups,
  getDriverGroups,
} from "../../services/DriverGroups";

const headerButtons = [
  {
    buttonTitle: "Add Group",
    buttonUrl: "/dashboard/add/groups",
    icon: faGroupArrowsRotate,
  },
];

const DriverGroup = () => {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);

  const driverGroupList = () => {
    getDriverGroups().then((res) => {
      setData(res.data);

      setPending(false);
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      driverGroupList();
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const columns = [
    {
      name: "No.",
      selector: (row) => data.indexOf(row) + 1,
      sortable: true,
    },
    {
      name: "Group name",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Drivers Count",
      selector: (row) => row.drivers.length,
    },
    {
      name: "Sent Messages Count",
      selector: (row) => row.messages.length,
    },
    {
      name: "",
      cell: (row) => (
        <TableActionButton
          detailPath={`/dashboard/detail/groups/${row.id}`}
          UpdatePath={`/dashboard/update/groups/${row.id}`}
          deleteId={row.id}
          deleteFun={deleteDriverGroups}
          refreshdata={driverGroupList}
        />
      ),
    },
  ];

  /*
  const deleteHandle = (row) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteTrucks(row.id).then((res) => {
              toastSuccess(`truck ${row.truck_name} deleted.`);
              retrieveTrucks();
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  */

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="Drivers Group" />
      <Card className="mb-3 border-0 shadow-sm p-2 rounded-5">
        <Card.Body>
          <div className="row">
            <Table columns={columns} row={data} processDataStatus={pending} />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DriverGroup;
