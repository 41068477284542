import React,{ useState,useEffect } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TruckApexChart from "./TruckApexChart";
import { getLoadedTruck, getUnloadedTruck } from "../../../services/dashboard";

const TruckData = () => {
  const [loadedTruck, setLoadedTruck] = useState(0);
  const [unloadedTruck, setUnLoadedTruck] = useState(0);

  useEffect(() => {
    getLoadedTruck().then((res) => setLoadedTruck(res.data.completed_count));
    getUnloadedTruck().then((res) => setUnLoadedTruck(res.data.completed_count));
  }, []);

  return (
    <div className="box-col-6 col-lg-12 col-xxl-8">
      <Card className="card-recent border-0 shadow-sm">
        <Card.Header className="card-no-border p-3">
          <Card.Title className="fw-bold text-left">Overall Balance</Card.Title>
        </Card.Header>
        <Card.Body className="pt-0">
          <Row className="recent-wrapper">
            <Col>
              <TruckApexChart loadCount={loadedTruck} unloadCount={unloadedTruck}/>
            </Col>
            <Col>
              <ul className="order-content list-group mt-3 ">
                <li className="list-group-item border-0">
                  {" "}
                  <span className="recent-circle bg-primary"></span>
                  <div>
                    <span className="f-light f-w-500">Loaded truck </span>
                    <h4 className="mt-1 mb-0 fw-bold fs-2">{loadedTruck}</h4>
                  </div>
                </li>
                <li className="list-group-item border-0">
                  {" "}
                  <span className="recent-circle unload-truck"></span>
                  <div>
                    <span className="f-light f-w-500">Unloaded truck</span>
                    <h4 className="mt-1 mb-0 fw-bold fs-2">{unloadedTruck}</h4>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TruckData;
