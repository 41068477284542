import axios from "axios";
import { toastError } from "../Components/ToastNotification";
import { getjwt, removeToken } from "./authServices";

axios.interceptors.request.use(
  async (config) => {
    const token = getjwt();
    if (token) {
      // Add the access token to the request headers
      config.headers.Authorization = `Bearer ${token.replaceAll('"', "")}`;
    }
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(null, (error) => {
  const unexpectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!unexpectedError) {
    toastError("No Network,Check connection...");
  }

  if (
    (error.response && error.response.status === 401) ||
    (error.response && error.response.status === 403)
  ) {
    removeToken();
    window.location.href = "/";
  }

  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.patch,
  update: axios.put,
  delete: axios.delete,
};
