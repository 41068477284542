import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import CardHeader from "../../../Components/CardHeader";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  toastError,
  toastSuccess,
} from "../../../Components/ToastNotification";
import {
  getDriverMessageGroupById,
  updateDriverMessageGroup,
} from "../../../services/DriverGroupMessage";
import { useNavigate, useParams } from "react-router-dom";
import { getDriverGroups } from "../../../services/DriverGroups";
import { getjwt } from "../../../services/authServices";
import Skeleton from "react-loading-skeleton";

const headerButtons = [
  {
    buttonTitle: "Back to messages",
    buttonUrl: "../Messages",
    icon: faArrowLeft,
  },
];

const groupMessageValidator = (value) => {
  let errors = {};
  if (!value["title"]) {
    errors.title = "Message Title is required";
  }
  if (!value["drivers_groups"]) {
    errors.drivers_groups = "Select a driver Group";
  }
  if (!value["message"]) {
    errors.message = "message is required";
  }
  return errors;
};

const UpdateDriverMessage = () => {
  const { id } = useParams();
  const navgate = useNavigate();
  const [data, setData] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [driverGroup, setDriverGroup] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      getDriverMessageGroupById(id).then((res) => {
        setData(res.data);
        setIsLoading(false);
      });
      getDriverGroups().then((res) => setDriverGroup(res.data));
    }, 1000);
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    let inputs = {
      ...data,
      [name]: value,
    };
    setFormErrors(groupMessageValidator(inputs));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt();
    if (
      data.title !== "" &&
      data.drivers_groups !== "" &&
      data.message !== ""
    ) {
      data["token_data"] = JSON.parse(token);
      updateDriverMessageGroup(id, data)
        .then((res) => {
          toastSuccess("Successfully send  message again");
          navgate("../Messages");
        })
        .catch((err) => toastError(err.response.error));
    } else {
      setFormErrors(groupMessageValidator(data));
    }
  };

  return (
    <React.Fragment>
      {isloading ? (
        <div className="container-fluid">
          <CardHeader
            headerItems={headerButtons}
            title="UPDATE AND SEND GROUP MESSAGE AGAIN"
          />

          <Card className="mb-3 border-0 shadow-sm rounded-5">
            <Card.Body>
              <div className="form-wrapper">
                <Form
                  noValidate
                  className="needs-validation"
                  onSubmit={handleSubmit}
                >
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Skeleton width={75} height={24} />
                      <Skeleton height={30} />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Skeleton width={75} height={24} />
                      <Skeleton height={30} />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Skeleton width={75} height={24} />
                      <Skeleton height={300} />
                    </Form.Group>
                  </Row>

                  <Skeleton width={276} height={40} borderRadius={10} />
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <div className="container-fluid">
          <CardHeader
            headerItems={headerButtons}
            title="UPDATE AND SEND GROUP MESSAGE AGAIN"
          />

          <Card className="mb-3 border-0 shadow-sm rounded-5">
            <Card.Body>
              <div className="form-wrapper">
                <Form
                  noValidate
                  className="needs-validation"
                  onSubmit={handleSubmit}
                >
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label htmlFor="text" className="fw-600">
                        Message Title
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        placeholder="Enter Your Message Title"
                        onChange={handleChange}
                        value={data.title}
                        className="border-2"
                        isInvalid={formErrors.title}
                      />
                      {formErrors.title && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.title}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Driver Groups</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="drivers_groups"
                        onChange={handleChange}
                        value={data.drivers_groups}
                        isInvalid={formErrors.drivers_groups}
                      >
                        {driverGroup.map((item, idx) => (
                          <option key={idx} value={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </Form.Select>
                      {formErrors.drivers_groups && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.drivers_groups}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="message"
                        placeholder="Message"
                        onChange={handleChange}
                        value={data.message}
                        style={{ height: "300px" }}
                        isInvalid={formErrors.message}
                      />
                      {formErrors.message && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.message}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Button
                    variant="primary"
                    type="submit"
                    className="shadow rounded-5"
                  >
                    <FontAwesomeIcon icon={faCircleCheck} />
                    Send Message Again
                  </Button>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </React.Fragment>
  );
};

export default UpdateDriverMessage;
