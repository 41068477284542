import { APIURL } from "../../constants/constants";
import { readNotificationByPost } from "../services/users";

export const phoneRegex = new RegExp(/^\+(?:[0-9]●?){9,14}[0-9]$/i);
export const emailRegex = new RegExp(
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
);
export const dateRegex = new RegExp(
  /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
);
export const placeholderForDeletedDriver =
  "You can't send message because this driver is deleted!";
export const placeholderForInactiveDriver =
  "You can't send message because this driver is inactive!";
export const placeholderForCompletedLoad =
  "You can't send message because this load is completed!";

export const generateUserImageLink = (userImage) => {
  if (userImage.includes("http") || userImage.includes("https")) {
    return userImage;
  } else {
    return `${APIURL}${userImage}`;
  }
};

export const makeNotificationStatus = (str) => {
  const updatedStr = str.replace(/_/g, " ");
  return updatedStr
    .split(" ") // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words back into a string
};

export const removeDuplicatedNotifications = (array) => {
  return array.reduce((acc, current) => {
    const x = acc.find(
      (item) => item.type == current.type && item.content == current.content
    );
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
};

export const processNotification = (payload) => {
  readNotificationByPost({
    type: payload.type,
    content: payload.content_id,
  });

  setTimeout(() => {
    if (payload.type == "chat_message") {
      window.location.href = `${window.location.origin}/dashboard/liveloads/${payload.content_id}`;
    } else if (payload.type == "ticket_replied") {
      if (
        !window.location.pathname.includes(
          `detail/tickets/${payload.content_id}`
        )
      ) {
        window.location.href = `${window.location.origin}/dashboard/detail/tickets/${payload.content_id}`;
      }
    } else if (
      payload.type == "ticket_created" ||
      payload.type == "ticket_updated"
    ) {
      window.location.href = `${window.location.origin}/dashboard/detail/tickets/${payload.content_id}`;
    } else if (payload.type == "load_completed") {
      window.location.href = `${window.location.origin}/dashboard/detail/loads/${payload.content_id}`;
    } else if (payload.type == "load_status_updated") {
      window.location.href = `${window.location.origin}/dashboard/detail/loads/${payload.content_id}`;
    }
  }, 600);
};
