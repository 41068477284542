import { faTruckArrowRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Card from "react-bootstrap/Card";
import Table from "../Components/Tables";
import CardHeader from "../Components/CardHeader";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useEffect, useContext } from "react";
import { deleteTrucks, getTrucks } from "../services/trucks";
import { useState } from "react";
import { toastSuccess } from "../Components/ToastNotification";
import { userContext } from "../context/UserContext";
import { Role } from "../../constants/role";

const headerButtons = [
  {
    buttonTitle: "Add ",
    buttonUrl: "/dashboard/add/trucks",
    icon: faTruckArrowRight,
  },
];

function Trucks() {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const { role } = useContext(userContext);

  const retrieveTrucks = () => {
    getTrucks().then((res) => {
      setData(res.data);
      setPending(false);
    });
  };

  const getTruckTypeName = (type) => {
    if (type === "S") {
      return "Standard";
    }
    if (type === "M") {
      return "Mega";
    }
    if (type === "V") {
      return "Vario";
    }
  };

  const deleteHandle = (row) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteTrucks(row.id).then((res) => {
              toastSuccess(`truck ${row.truck_name} deleted.`);
              retrieveTrucks();
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      retrieveTrucks();
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const columns = [
    {
      name: "serialNo",
      key: "index",
      selector: (row) => data.indexOf(row) + 1,
      sortable: true,
    },
    {
      name: "Truck",
      selector: (row) => row.truck_name,
      sortable: true,
    },
    {
      name: "Truck Type",
      selector: (row) => getTruckTypeName(row.truck_type),
      sortable: true,
    },
    {
      name: "Truck Department",
      selector: (row) => row.truck_department,
      sortable: true,
    },
    {
      name: "Truck Weight",
      selector: (row) => row.truck_weight,
      sortable: true,
    },
    {
      name: "Truck Height",
      selector: (row) => row.truck_weight,
      sortable: true,
    },
    {
      name: "Platenumber",
      selector: (row) => row.plate_number,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    (role == Role.Super || role == Role.Admin) && {
      name: "",
      cell: (row) => (
        <>
          <Link
            className="btn btn-info mr-3 br-50"
            to={`/dashboard/update/trucks/${row.id}`}
          >
            <FontAwesomeIcon icon={faPen} />
          </Link>
          <button
            className="btn btn-danger mr-3 br-50"
            onClick={() => deleteHandle(row)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="TRUCKS" />

      <Card className="mb-3 border-0 shadow-sm p-2 rounded-5">
        <Card.Body>
          <div className="row">
            <Table columns={columns} row={data} processDataStatus={pending} />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Trucks;
