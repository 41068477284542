import React from "react";

const UserTableBody = ({ img, name, time, timeData }) => {
  return (
    <tbody>
      <tr >
        <td >
          <img
            src={img}
            alt="user"
            className="img-fluid img-40 rounded-circle media"
          />
        </td>
        <td className="img-content-box">
          <p className="d-block f-w-500" >
            {name}
          </p>
        </td>
        <td className="text-end">
          <span className="f-light">{time}</span>
          <p className="m-0 font-success">{timeData}</p>
        </td>
      </tr>
    </tbody>
  );
};

export default UserTableBody;
