import { faTruckArrowRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Card from "react-bootstrap/Card";
import Table from "../Components/Tables";
import CardHeader from "../Components/CardHeader";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useState, useEffect, useContext } from "react";
import { deleteTrailers, getTrailers } from "./../services/trailer";
import { toastSuccess } from "../Components/ToastNotification";
import { userContext } from "../context/UserContext";
import { Role } from "../../constants/role";

const headerButtons = [
  {
    buttonTitle: "Add ",
    buttonUrl: "/dashboard/add/trailers",
    icon: faTruckArrowRight,
  },
];

function Trailers() {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const { role } = useContext(userContext);

  const retrieveTrailers = () => {
    getTrailers().then((res) => {
      setData(res.data);
      setPending(false);
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      retrieveTrailers();
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const getTruckTypeName = (type) => {
    if (type === "S") {
      return "Standard";
    }
    if (type === "M") {
      return "Mega";
    }
    if (type === "V") {
      return "Vario";
    }
  };

  const deleteHandle = (row) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteTrailers(row.id).then((res) => {
              toastSuccess(`trailers ${row.trailer_name} deleted.`);
              retrieveTrailers();
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const columns = [
    {
      name: "serialNo",
      selector: (row) => data.indexOf(row) + 1,
      sortable: true,
    },
    {
      name: "Trailer",
      selector: (row) => row.trailer_name,
      sortable: true,
    },
    {
      name: "Trailer Type",
      selector: (row) => getTruckTypeName(row.trailer_type),
      sortable: true,
    },
    {
      name: "Trailer Length",
      selector: (row) => row.trailer_length,
      sortable: true,
    },
    {
      name: "Trailer Width",
      selector: (row) => row.trailer_width,
      sortable: true,
    },
    {
      name: "Trailer height",
      selector: (row) => row.trailer_height,
      sortable: true,
    },
    {
      name: "Working Load",
      selector: (row) => row.working_load,
      sortable: true,
    },
    {
      name: "own_weight",
      selector: (row) => row.own_weight,
      sortable: true,
    },
    {
      name: "max_weight",
      selector: (row) => row.max_weight,
      sortable: true,
    },
    {
      name: "number_of_axis",
      selector: (row) => row.number_of_axis,
      sortable: true,
    },
    {
      name: "Platenumber",
      selector: (row) => row.plate_number,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        if (row.status === "A") {
          return "Available";
        }
        if (row.status === "U") {
          return "Unavilable";
        }
        if (row.status === "H") {
          return "Holiday";
        }
      },
      sortable: true,
    },
    (role == Role.Super || role == Role.Admin) && {
      name: "",
      cell: (row) => (
        <>
          <Link
            className="btn btn-info mr-3 br-50"
            to={`/dashboard/update/trailers/${row.id}`}
          >
            <FontAwesomeIcon icon={faPen} />
          </Link>
          <button
            className="btn btn-danger mr-3 br-50"
            onClick={() => deleteHandle(row)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="TRAILERS" />

      <Card className="mb-3 border-0 shadow-sm p-2 rounded-5">
        <Card.Body>
          <div className="row">
            <Table columns={columns} row={data} processDataStatus={pending} />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Trailers;
