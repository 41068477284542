const RegisterValidater = (value) => {
  const errors = {};
  let regex = new RegExp(/^\+(?:[0-9]●?){9,14}[0-9]$/i);
  if (!value["first_name"]) {
    errors.first_name = "first name is required";
  }
  if (!value["last_name"]) {
    errors.last_name = "last name is required";
  }

  if (!value["phone_number"]) {
    errors.phone_number = "phone Number is required";
  } else if (!regex.test(value["phone_number"])) {
    errors.phone_number = "Please Add Country Code Eg: +41";
  } else if (value["phone_number"].length > 13) {
    errors.phone_number = "No more then 13 digit";
  }

  if (!value["email"]) {
    errors.email = "Enter a Vaild Email Id";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value["email"])) {
    errors.email = "Invalid email address";
  } else {
    errors.email = "";
  }

  if (!value["password"]) {
    errors.password = "please provide Password";
  } else if (value["password"].length < 8) {
    errors.password = "Password must be longer than 8 character";
  } else {
    errors.password = "";
  }
  if (!value["confirm_password"]) {
    errors.confirm_password = "please provide ConfirmPassword";
  } else if (value["confirm_password"] !== value["password"]) {
    errors.confirm_password = "Password does not match confirm password";
  } else {
    errors.confirm_password = "";
  }

  if (!value["status"]) {
    errors.status = "status is required";
  }
  if (!value["user_type"]) {
    errors.user_type = "user type is required";
  }

  return errors;
};

export default RegisterValidater;
