import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { toastSuccess } from "../../Components/ToastNotification";
import { useNavigate, useParams } from "react-router-dom";
import CardHeader from "../../Components/CardHeader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { getByIdTrucks, updateTrucks } from "../../services/trucks";
import { getjwt } from "../../services/authServices";
import Skeleton from "react-loading-skeleton";

const headerButtons = [
  { buttonTitle: "Back", buttonUrl: "../trucks", icon: faArrowLeft },
];

const Validater = (value) => {
  const errors = {};
  if (!value["truck_name"]) {
    errors.truck_name = "please add the truck name";
  }
  if (!value["truck_department"]) {
    errors.truck_department = "please add truck department";
  }
  if (!value["truck_weight"]) {
    errors.truck_weight = "please add Wegiht";
  }
  if (!value["truck_type"]) {
    errors.truck_type = "please add type";
  }
  if (!value["truck_height"]) {
    errors.truck_height = "please add height";
  }
  if (!value["plate_number"]) {
    errors.plate_number = "please add plateNumber";
  }
  if (!value["status"]) {
    errors.status = " add status";
  }
  return errors;
};

function TruckUpdate() {
  const { id } = useParams();
  const navgate = useNavigate();
  const [data, setData] = useState([]);
  const [formErrors, setformErrors] = useState({});
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      getByIdTrucks(id).then((res) => {
        setData(res.data);
        setIsLoading(false);
      });
    }, 1000);
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let inputs = {
      ...data,
      [name]: value,
    };
    setData({ ...data, [name]: value });
    setformErrors(Validater(inputs));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt();
    if (
      data.truck_name !== "" &&
      data.truck_type !== "" &&
      data.truck_weight !== "" &&
      data.truck_height !== "" &&
      data.status !== "" &&
      data.plate_number !== ""
    ) {
      data["token_data"] = JSON.parse(token);
      updateTrucks(id, data).then((res) => {
        if (res.status === 200) {
          toastSuccess("Successfully updated trucks");
          navgate("../trucks");
        }
      });
    } else {
      setformErrors(Validater(data));
    }
  };

  return (
    <React.Fragment>
      {isloading ? (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="Edit Truck" />
          <Card className="mb-3 border-0 shadow-sm rounded-2">
            <Card.Body>
              <div className="form-wrapper">
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col lg={6}>
                      <Form.Group>
                        <Skeleton width={87} height={24} />
                        <Skeleton height={38} />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group>
                        <Skeleton width={87} height={24} />
                        <Skeleton height={38} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3  mt-3">
                    <Col lg={6}>
                      <Form.Group>
                        <Skeleton width={87} height={24} />
                        <Skeleton height={38} />
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group>
                        <Skeleton width={87} height={24} />
                        <Skeleton width={327} height={38} />
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group>
                        <Skeleton width={87} height={24} />
                        <Skeleton width={327} height={38} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3  mt-3">
                    <Col lg={6}>
                      <Form.Group>
                        <Skeleton width={87} height={24} />
                        <Skeleton height={38} />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group>
                        <Skeleton width={87} height={24} />
                        <Skeleton height={38} />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Skeleton width={138} height={37} />
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="Edit Truck" />
          <Card className="mb-3 border-0 shadow-sm rounded-2">
            <Card.Body>
              <div className="form-wrapper">
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label className="fw-600">Truck Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="truck_name"
                          placeholder="Enter Truck Name"
                          onChange={handleChange}
                          value={data.truck_name}
                          className="border-2"
                          isInvalid={formErrors.truck_name}
                        />
                        {formErrors.truck_name && (
                          <Form.Control.Feedback type="invalid">
                            {formErrors.truck_name}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label className="fw-600">
                          Truck Department
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="truck_department"
                          placeholder="Enter Truck Department"
                          onChange={handleChange}
                          value={data.truck_department}
                          className="border-2"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3  mt-3">
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label className="fw-600"> Truck Type</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="truck_type"
                          onChange={handleChange}
                          value={data.truck_type}
                          isInvalid={formErrors.truck_type}
                        >
                          <option value="">Select</option>
                          <option value="S">Standard</option>
                          <option value="V">Vario</option>
                          <option value="M">Mega</option>
                        </Form.Select>
                        {formErrors.truck_type && (
                          <Form.Control.Feedback type="invalid">
                            {formErrors.truck_type}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group>
                        <Form.Label className="fw-600">Truck Weight</Form.Label>
                        <Form.Control
                          type="text"
                          name="truck_weight"
                          placeholder="3.4ton"
                          onChange={handleChange}
                          value={data.truck_weight}
                          className="border-2 h-50"
                          isInvalid={formErrors.truck_weight}
                        />
                        {formErrors.truck_weight && (
                          <Form.Control.Feedback type="invalid">
                            {formErrors.truck_weight}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group>
                        <Form.Label className="fw-600">Truck height</Form.Label>
                        <Form.Control
                          type="text"
                          name="truck_height"
                          placeholder="40.5m"
                          onChange={handleChange}
                          value={data.truck_height}
                          className="border-2 h-50"
                          isInvalid={formErrors.truck_height}
                        />
                        {formErrors.truck_height && (
                          <Form.Control.Feedback type="invalid">
                            {formErrors.truck_height}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3  mt-3">
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label className="fw-600">
                          Truck Platenumber
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="plate_number"
                          placeholder="Platenumber"
                          onChange={handleChange}
                          value={data.plate_number}
                          className="border-2 h-50"
                          isInvalid={formErrors.plate_number}
                        />
                        {formErrors.plate_number && (
                          <Form.Control.Feedback type="invalid">
                            {formErrors.plate_number}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label className="fw-600"> Status</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="status"
                          onChange={handleChange}
                          value={data.status}
                          isInvalid={formErrors.status}
                        >
                          <option value="">Select the status</option>
                          <option value="Available">Available</option>
                          <option value="Unavilable">Unavailable</option>
                          <option value="Holiday/Inactive">
                            Holiday/Inactive
                          </option>
                        </Form.Select>
                        {formErrors.status && (
                          <Form.Control.Feedback type="invalid">
                            {formErrors.status}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    variant="primary"
                    type="submit"
                    className="shadow rounded-5 mt-4"
                  >
                    <FontAwesomeIcon icon={faCircleCheck} /> Update truck
                  </Button>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </React.Fragment>
  );
}

export default TruckUpdate;
