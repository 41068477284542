import React, { useEffect, useState } from "react";
import "../assets/css/login.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import logo from "../assets/images/logo.svg";
import logoname from "../assets/images/logo-name.svg";
import Form from "react-bootstrap/Form";
import LoginValidator from "./Components/validators/LoginValidator";
import { authlogin, setToken } from "./services/authServices";
import ErrorMessage from "./Components/messagebox/ErrorMessage";
import LoadSpinner from "./Components/LoaderPage/LoadSpinner";
import { useNavigate } from "react-router-dom";
import { toastSuccess } from "./Components/ToastNotification";
import { removeToken } from "./services/authServices";

const INITIAL_DATA = {
  email: "",
  password: "",
};

function Login() {
  const navgate = useNavigate();
  const [data, setData] = useState(INITIAL_DATA);
  const [onAlert, setAlert] = useState(false);
  const [errors, setErrors] = useState({});
  const [neterrors, setNetErrors] = useState(" ");
  const [isLoading, setLoading] = useState(false);
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    isMounted && removeToken();
  }, [isMounted]);

  const InputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleAlertClose = () => {
    setAlert(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      (Object.keys(errors).length === 0 &&
        data.email !== "" &&
        data.password !== "") ||
      neterrors !== ""
    ) {
      setTimeout(() => {
        setLoading(true);
        authlogin(data.email, data.password)
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              toastSuccess("login Successfully");
              console.log(res.data.token["access_token"]);
              setToken(res.data.token["access_token"]);
              navgate("dashboard");
            }
          })
          .catch((err) => {
            try {
              if (err.response.status === 400) {
                if (err.response?.data?.non_field_errors) {
                  setNetErrors(err.response.data.non_field_errors[0]);
                } else {
                  setNetErrors("wrong email and password");
                }
                setAlert(true);
                setLoading(false);
              } else {
                setLoading(false);
              }
            } catch {
              setNetErrors("No Network, Check Internet");
              setErrors(LoginValidator(data));
              setAlert(true);
              setLoading(false);
            }
            if (err.response.data.user) {
              err.response.data.user.map((e) => {
                setNetErrors(e);
                setErrors(LoginValidator(data));
                setAlert(true);
                setLoading(false);
              });
            }
          });
      }, 500);
    } else {
      setErrors(LoginValidator(data));
      setAlert(true);
    }
  };

  useEffect(() => {
    document.body.classList.add("bg-img");

    return function cleanup() {
      document.body.classList.remove("bg-img");
    };
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadSpinner />
      ) : (
        <div>
          <section id="section-01">
            <Container className="py-5 min-vh-100">
              <Row className=" d-flex justify-content-center align-items-center h-100">
                <Col xl="4" md="4" lg="4">
                  {onAlert && (
                    <ErrorMessage
                      onClose={handleAlertClose}
                      neterr={neterrors}
                      err={errors}
                    />
                  )}

                  <Card className=" login-card">
                    <Row>
                      <div className="formstye">
                        <div className="mt-5">
                          <img src={logo} width="100" height="100" alt="" />
                        </div>
                        <div className="mt-m-25">
                          <img src={logoname} width="100" height="100" alt="" />
                        </div>
                      </div>

                      <Col
                        md="12"
                        lg="12"
                        className="d-flex align-items-center"
                      >
                        <Card.Body className="text-black">
                          <Form onSubmit={handleSubmit}>
                            <Form.Group
                              className="mb-3 mt-3 text-right fs-label"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="m-l-10 fw-600">
                                USERNAME
                              </Form.Label>
                              <Form.Control
                                type="username"
                                placeholder="USERNAME"
                                name="email"
                                className="border-radius-zero  fs-input"
                                onChange={InputChange}
                                value={data.email}
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-4 text-right fs-label"
                              controlId="formBasicPassword"
                            >
                              <Form.Label className="m-l-10 fw-600">
                                PASSWORD
                              </Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="PASSWORD"
                                name="password"
                                className="border-radius-zero  fs-input"
                                onChange={InputChange}
                                value={data.password}
                              />
                            </Form.Group>

                            <Button
                              type="submit"
                              className="mb-3 shadow border-radius-zero  btn-block w-100-p loginbtn btn"
                            >
                              LOGIN <i className="fa fa-chevron-right"></i>
                            </Button>
                          </Form>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      )}
    </React.Fragment>
  );
}

export default Login;
