import React, { useEffect, useState } from "react";
import UserTableBody from "./UserTableBody";
import undraw_profile from "../../../../assets/images/undraw_profile.svg";
import { getLatestLoads } from "../../../services/dashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const UserContent = () => {
  const [loads, setLoads] = useState([]);

  useEffect(() => {
    getLatestLoads().then((res) => setLoads(res.data));
  }, []);

  const getDateFormat = (date) => {
    let selected_date = new Date(date);
    const month = selected_date.getMonth() + 1;
    const day = selected_date.getDate();
    const year = selected_date.getFullYear();
    let format = `${month}-${day}-${year}`;
    return format;
  };
  const getTime = (date) => {
    let selected_date = new Date(date);
    let format = selected_date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return format;
  };

  return (
    <div className="appointment-sec box-col col col-xxl-4 ">
      <div className="appointment">
        <div className="card-recent shadow-sm">
          <div className="card-no-border card-header p-3">
            <div className="header-top">
              <h5 className="m-0 fw-bold">Recent Loadings</h5>
            </div>
          </div>
          <div className="pt-0 card-body">
            <div className="appointment-table">
              <div className="table-responsive">
                <table className="table ">
                  {loads.slice(0, 5).map((item, idx) => (
                    <UserTableBody
                      key={idx}
                      img={undraw_profile || item.driver.image}
                      name={
                        item.driver !== null
                          ? item.driver.user.first_name +
                            " " +
                            item.driver.user.last_name
                          : "No driver selected"
                      }
                      time={getDateFormat(item.created_at)}
                      timeData={getTime(item.created_at)}
                    />
                  ))}
                </table>
              </div>
              <div className="card-button_loads">
                <Link to="loads">
                  More Loads <FontAwesomeIcon icon={faCircleArrowRight} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserContent;
