import { APIURL } from "../../constants/constants";
import http from "./ServerApi";

export const getUser = async () => {
  return await http.get(APIURL + "/dashboard/load/");
};
export const getChatByDriver = async (id) => {
  return await http.get(APIURL + `/rooms/${id}/chats`);
};
export const getMessagesByRoomId = async (id) => {
  return await http.get(APIURL + `/chats/${id}/messages`);
};
export const getRoomById = async (id) => {
  return await http.get(APIURL + `/room/${id}/`);
};
export const getRooms = async () => {
  return await http.get(APIURL + `/rooms/`);
};

export const addRoomMember = async (id, data) => {
  return await http.update(APIURL + `/rooms/${id}/chats`, data);
};
