import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import CardHeader from "./../../Components/CardHeader";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { toastSuccess, toastError } from "../../Components/ToastNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { getTicketTypeById, updateTicketType } from "../../services/ticketType";
import { getjwt } from "../../services/authServices";
import Skeleton from "react-loading-skeleton";

const headerButtons = [
  { buttonTitle: "Back", buttonUrl: "../tickettypes", icon: faArrowLeft },
];

const Validater = (value) => {
  const errors = {};
  if (!value["ticket_type"]) {
    errors.ticket_type = "tickettype is required";
  }
  return errors;
};

const TicketTypeUpadate = () => {
  const { id } = useParams();
  const navgate = useNavigate();
  const [data, setData] = useState([]);
  const [formErrors, setformErrors] = useState({});
  const [isloading, setIsLoading] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    let inputs = {
      ...data,
      [name]: value,
    };
    setformErrors(Validater(inputs));
  };

  useEffect(() => {
    setTimeout(() => {
      getTicketTypeById(id).then((res) => {
        setIsLoading(false);
        setData(res.data);
      });
    }, 1000);
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt();
    if (data.ticket_type !== "") {
      data["token_data"] = JSON.parse(token);
      updateTicketType(id, data)
        .then((res) => {
          if (res.status === 200) {
            toastSuccess("Successfully updated TicketType");
            navgate("../tickettypes");
          }
        })
        .catch((err) => {
          err.response.data.errors.map((errors) =>
            toastError(`Failed to updata: ${errors.error}`)
          );
        });
    } else {
      setformErrors(Validater(data));
    }
  };
  
  return (
    <React.Fragment>
      {isloading ? (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="UPDATE " />
          <Card className="mb-3 border-0 shadow-sm rounded-2">
            <Card.Body>
              <div className="form-wrapper">
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Skeleton width={70} />
                      <Skeleton height={24} />
                    </Form.Group>
                  </Row>

                  <Skeleton width={138} height={24} />
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="UPDATE" />
          <Card className="mb-3 border-0 shadow-sm rounded-2">
            <Card.Body>
              <div className="form-wrapper">
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">TicketType</Form.Label>
                      <Form.Control
                        type="text"
                        name="ticket_type"
                        placeholder="TicketType"
                        onChange={handleChange}
                        value={data.ticket_type}
                        className="border-2"
                        isInvalid={formErrors.ticket_type}
                      />
                      {formErrors.ticket_type && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.ticket_type}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Button
                    variant="primary"
                    type="submit"
                    className="shadow rounded-5 mt-4"
                  >
                    <FontAwesomeIcon icon={faCircleCheck} /> Update TicketType
                  </Button>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </React.Fragment>
  );
};

export default TicketTypeUpadate;
