import React, { useEffect, useState } from "react";
import { Card, Col, Image, Table, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import undraw_profile from "../../../src/assets/images/undraw_profile.svg";
import "./users.css";
import Badge from "react-bootstrap/Badge";
import CardHeader from "../Components/CardHeader";
import { faArrowLeft, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DriverMangerModal from "./../Components/DriverMangerModal";
import { getDriversByManagerId, getUserById } from "../services/users";
import Skeleton from "react-loading-skeleton";

const headerButtons = [
  {
    title: "User",
    buttonTitle: "Back to users",
    buttonUrl: "../users",
    icon: faArrowLeft,
  },
];
const UserDetailView = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  const handleClose = (value) => {
    setShow(value);
  };

  const managerId = data.id;
  const driverList = drivers.map((item) => item.id);

  useEffect(() => {
    const refreshdata = () => {
      setTimeout(() => {
        getUserById(id).then((res) => {
          setData(res.data);
          setIsLoading(false);
        });
      }, 1000);
    };
    refreshdata();
    if (managerId !== undefined) {
      getDriversByManagerId(managerId).then((res) => setDrivers(res.data));
    }
  }, [id, managerId]);

  return (
    <div>
      {isloading ? (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="USER" />

          <div className="row">
            <Col sm={4} lg={4} xs={4}>
              <Card className="mb-3 p-5 border-0 shadow-sm">
                <Card.Body>
                  <div className="row">
                    <div className="col-12">
                      <Skeleton
                        circle={true}
                        width="100px"
                        height={100}
                        borderRadius="10px"
                      />
                    </div>
                    <div className="col-12">
                      <Card.Title className="mt-3 mb-2">
                        <strong>
                          <Skeleton width={150} />
                        </strong>
                      </Card.Title>
                      <Card.Text>
                        <Skeleton width={50} />
                      </Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={8} lg={8} xs={8}>
              <Card className="mb-3 p-2 border-0 shadow-sm">
                <Card.Body>
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <b>
                            <Skeleton height={24} />
                          </b>
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>
                            <Skeleton height={24} />
                          </b>
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            <Skeleton height={24} />
                          </b>
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            <Skeleton height={24} />
                          </b>
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            <Skeleton height={24} />
                          </b>
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            <Skeleton height={24} />
                          </b>
                        </td>
                        <td colSpan={2}>
                          <Skeleton height={24} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
                <p className="text-left">
                  <b>
                    <Skeleton height={24} />
                  </b>
                </p>
              </Card>
            </Col>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="USER" />

          <div className="row">
            <Col sm={4} lg={4} xs={4}>
              <Card className="mb-3 p-5 border-0 shadow-sm">
                <Card.Body>
                  <div className="row">
                    <div className="col-12">
                      <Card.Img
                        style={{
                          width: "144px",
                          borderRadius: "50%",
                          height: "144px",
                          borderColor: "#dadada",
                          borderStyle: "solid",
                          objectFit: "cover",
                          borderWidth: 2,
                        }}
                        src={data.image || undraw_profile}
                        alt="Generic placeholder image"
                        as={Image}
                        fluid
                      />
                    </div>
                    <div className="col-12">
                      <Card.Title className="mt-3 mb-2">
                        <strong>
                          {data.last_name + " " + data.first_name}
                        </strong>
                      </Card.Title>
                      <Card.Text>
                        <Badge bg="danger p-2  shadow">
                          {data.is_superuser
                            ? "Super Administrator"
                            : data.user_type && data["user_type"].user_type}
                        </Badge>
                      </Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={8} lg={8} xs={8}>
              <Card className="mb-3 p-2 border-0 shadow-sm">
                <Card.Body>
                  <Table borderless hover>
                    <tbody>
                      <tr>
                        <td>
                          <b>Name</b>
                        </td>
                        <td colSpan={2}>
                          {data.last_name + " " + data.first_name}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Email</b>
                        </td>
                        <td colSpan={2}>{data.email || "Email@gmail.com"}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Phone No</b>
                        </td>
                        <td colSpan={2}>
                          {data.phone_number || "12345678890"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>User Type</b>
                        </td>
                        <td colSpan={2}>
                          {data.is_superuser
                            ? "Super Administrator"
                            : data.user_type && data["user_type"].user_type}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Status</b>
                        </td>
                        <td colSpan={2}>
                          {" "}
                          <Badge bg="success p-2  shadow">
                            {data.status === 1 ? "Active" : "Not Active"}
                          </Badge>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Created </b>
                        </td>
                        <td colSpan={2}>{data.created_at}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
                <p className="text-left">
                  Last Login <b>{data.last_login}</b>
                </p>
              </Card>
            </Col>
            {drivers.length > 0 ? (
              <Col sm={8} lg={12} xs={8}>
                <Card className="mb-3 p-2 border-0 shadow">
                  <Card.Header>
                    <Row>
                      <Col sm={12} lg={6} xs={12} md={6}>
                        <p className="h6 text-start">
                          <b>Assigned Drivers List</b>
                        </p>
                        <p className="h6 text-start">
                          {/* <Link
                      to="/dashboard/assignDriver"
                      relative="users"
                      className=" btn  btn-sm btn-primary shadow rounded-5 w-25 ml-3"
                    >
                      <FontAwesomeIcon icon={faCircleUser} className="mr-3" />
                      Manage
                    </Link> */}
                          <Link
                            onClick={() => setShow(true)}
                            relative="users"
                            className="btn  btn-sm btn-primary shadow rounded-5 px-3"
                          >
                            <FontAwesomeIcon
                              diverId={id}
                              icon={faCircleUser}
                              className="mr-3"
                            />
                            Change Manager
                          </Link>
                          {show && (
                            <DriverMangerModal
                              driverid={driverList}
                              showMod={show}
                              onClose={handleClose}
                              onCloseCallback={() =>
                                getDriversByManagerId(managerId).then((res) =>
                                  setDrivers(res.data)
                                )
                              }
                            />
                          )}
                        </p>
                      </Col>
                    </Row>
                  </Card.Header>

                  <Card.Body>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th> Name</th>
                          <th>Truck</th>
                          <th>Platenumber</th>
                          <th>Trailer</th>
                          <th>Platenumber</th>
                        </tr>
                      </thead>
                      <tbody>
                        {drivers.map((item, idx) => (
                          <tr key={idx}>
                            <td>{drivers.indexOf(item) + 1}</td>
                            <td>
                              {item.user.first_name + " " + item.user.last_name}
                            </td>
                            <td>
                              {item.truck ? item.truck?.truck_name ?? "" : ""}
                            </td>
                            <td>
                              {item.truck ? item.truck?.plate_number ?? "" : ""}
                            </td>
                            <td>
                              {item.trailer
                                ? item.trailer?.trailer_name ?? ""
                                : ""}
                            </td>
                            <td>
                              {item.trailer
                                ? item.trailer?.plate_number ?? ""
                                : ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetailView;
