import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./datatable.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-loading-skeleton/dist/skeleton.css";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";
import { register } from "./serviceWorker";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <PrimeReactProvider>
    <App />
  </PrimeReactProvider>
  // </React.StrictMode>
);

register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
