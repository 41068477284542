import React, { useState, useEffect, useContext } from "react";
import Form from "react-bootstrap/Form";
import CardHeader from "./../Components/CardHeader";
import { Table, Card, Col, Image, Badge, Button, Row } from "react-bootstrap";
import undraw_profile from "../../assets/images/undraw_profile.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import "./tickects.css";
import CloseTicket from "../Components/CloseTicket";
import { useParams } from "react-router-dom";
import {
  addTicketComments,
  getTicketById,
  getTicketComments,
} from "../services/tickets";
import Skeleton from "react-loading-skeleton";
import { getCurrentUser } from "./../services/authServices";
import { onMessage } from "@firebase/messaging";
import { messaging } from "../../FCM/firebase";
import { toastNotification } from "../Components/ToastNotification";
import {
  generateUserImageLink,
  placeholderForDeletedDriver,
  placeholderForInactiveDriver,
  processNotification,
} from "../utils";
import TicketDetailsChat from "./TicketDetailsChat";
import { getNotificationList, readNotificationByPost } from "../services/users";
import { userContext } from "../context/UserContext";

const headerButtons = [
  {
    title: "TICKETS",
    buttonTitle: "Back to tickets",
    buttonUrl: "../tickets" || -1,
    icon: faArrowLeft,
  },
];
const getTruckTypeName = (type) => {
  if (type === "S") {
    return "Standard";
  }
  if (type === "M") {
    return "Mega";
  }
  if (type === "V") {
    return "Vario";
  }
};
const getStatusName = (status) => {
  if (status === "A") {
    return "Available";
  }
  if (status === "U") {
    return "Unavilable";
  }
  if (status === "H") {
    return "Holiday";
  }
};

const TicketDetials = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [reply, setReply] = useState("");
  const [isloading, setIsLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const { setNotifications } = useContext(userContext);

  const handleClose = (value) => {
    setShow(value);
  };

  const user = getCurrentUser();

  useEffect(() => {
    //read notification
    readNotificationByPost({
      type: "ticket_replied",
      content: parseInt(id),
    }).then((res) => {
      getNotificationList().then((res) => {
        console.log(res.data.data);
        setNotifications(res.data.data);
      });
    });

    readNotificationByPost({
      type: "ticket_updated",
      content: parseInt(id),
    }).then((res) => {
      getNotificationList().then((res) => {
        console.log(res.data.data);
        setNotifications(res.data.data);
      });
    });

    readNotificationByPost({
      type: "ticket_created",
      content: parseInt(id),
    }).then((res) => {
      getNotificationList().then((res) => {
        console.log(res.data.data);
        setNotifications(res.data.data);
      });
    });

    return;
  }, []);

  onMessage(messaging, (payload) => {
    console.log(
      "Foreground Message in ticket:",
      payload,
      window.location.pathname
    );
    console.log(payload.data);
    if (payload.data.type == "ticket_replied") {
      console.log(payload.data.sender, user.id);
      if (Number(payload.data.sender) === user.id) return;
      setComments((prev) => [
        ...prev,
        {
          author: payload.data.sender,
          created: new Date(),
          message: payload.data.content,
          ticket: payload.data.content_id,
          id: prev.length + 1,
        },
      ]);

      //read notification
      readNotificationByPost({
        type: "ticket_replied",
        content: payload.data.content_id,
      });
    } else {
      getNotificationList().then((res) => {
        console.log(res.data.data);
        setNotifications(res.data.data);
      });
    }

    if (!window.location.pathname.includes("liveloads")) {
      toastNotification(
        payload.notification.title,
        payload.notification.body,
        payload.data,
        onNotificationClick
      );
    }
  });

  const onNotificationClick = (payload) => {
    processNotification(payload);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (reply !== " ") {
      const data = {
        message: reply,
        ticket: id,
        author: user.user_id,
      };
      addTicketComments(data).then((res) => {
        setComments((prev) => [...prev, res.data]);
        setReply("");
      });
    }
    //getComments(id);
  };

  const getComments = (id) => {
    getTicketComments(id).then((res) => {
      console.log("comments - ", res.data);
      setComments(res.data.sort((a, b) => a.id - b.id));
    });
  };

  useEffect(() => {
    getComments(id);

    setTimeout(() => {
      getTicketById(id).then((res) => {
        setData(res.data);

        let tempUsers = res.data.admins;
        if (res.data.driver) {
          tempUsers = [...tempUsers, res.data.driver.user];
        }
        if (res.data.manager) {
          tempUsers = [...tempUsers, res.data.manager];
        }
        setAllUsers(tempUsers);
        setIsLoading(false);
      });
    }, 1000);
  }, [id]);

  return (
    <React.Fragment>
      {isloading ? (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title=" Ticket Details " />

          <Row>
            <Col sm={12} lg={5} xs={12}>
              <Card className="mb-3 p-4 border-0 shadow-sm">
                <Card.Body>
                  <Skeleton circle={true} width={80} height={80} />
                  <Skeleton width={162} height={24} />

                  <Card.Text>
                    <Skeleton width={190} height={24} />
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card className="mb-3 mt-3 p-4 border-0 shadow-sm">
                <Card.Header className="text-start">
                  <Skeleton width={140} height={23} />
                </Card.Header>
                <Card.Body>
                  <Row className="mb-3 ">
                    <Skeleton height={200} />
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={12} lg={7} xs={12}>
              <Card className="mb-3 p-2 border-0 shadow-sm">
                <Card.Body>
                  <Row className="mb-3">
                    <Form.Group>
                      <Table bordered className="">
                        <tbody>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>

                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <Table bordered className="">
                        <tbody>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">
                              <Skeleton height={40} />
                            </td>
                            <td>
                              <Skeleton height={40} />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Form.Group>
                  </Row>

                  <Table bordeless="true" className="mt-4">
                    <tbody>
                      <tr className="text-start">
                        <td className="fw-700">
                          <Skeleton width={140} height={40} />
                        </td>
                        <td>
                          <Skeleton width={145} height={40} />
                        </td>
                      </tr>
                      <tr className="text-start">
                        <td className="fw-700">
                          <Skeleton width={140} height={40} />
                        </td>
                        <td>
                          <Skeleton width={145} height={40} />
                        </td>
                      </tr>
                      <tr className="text-start">
                        <td className="fw-700">
                          <Skeleton width={140} height={40} />
                        </td>
                        <td>
                          <Skeleton width={145} height={40} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* <Row>
          <Col sm={12} lg={12} xs={12}>
            <Card className="mb-3 p-4 border-0 shadow-sm">
              <Card.Header className="text-start">
                <b>Messages</b>
              </Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <Card className="mt-3  w-50 driver-message border-0 shadow-sm">
                    <Card.Body className="text-start ">
                      <p className="driver-fs-st">
                        This is response from driver.
                      </p>
                      <small>28/03/2023 10:30</small>
                    </Card.Body>
                  </Card>
                </Row>
                <Row className=" mb-3">
                  <Card className="mt-3 driver-card   w-50 manager-message  border-0 shadow-sm">
                    <Card.Body className="text-end">
                      <img
                        src={undraw_profile}
                        alt="Driver"
                        className="img-circle avatar"
                      />
                      <p className="driver-fs-st">
                        This is response from driver.
                      </p>
                      <small>28/03/2023 10:30</small>
                    </Card.Body>
                  </Card>
                </Row>
  
                <Row className="mb-3">
                  <Card className="mt-3 w-50 driver-message border-0 shadow-sm">
                    <Card.Body className="text-start ">
                      <p className="driver-fs-st">
                        This is response from driver.
                      </p>
                      <small>28/03/2023 10:30</small>
                    </Card.Body>
                  </Card>
                </Row>
  
                <Row className="mb-3 mt-5">
                  <hr />
                  <Form.Group as={Col} className="p-0">
                    <Form.Control
                      as="textarea"
                      name="lastName"
                      placeholder="Add Reply"
                      value={reply}
                      onChange={(e) => setReply(e.target.value)}
                      style={{ height: "100px" }}
                    />
                  </Form.Group>
                </Row>
                <Row className="mt-2">
                  <Button
                    variant="primary"
                    type="submit"
                    className="shadow rounded-5"
                  >
                    <FontAwesomeIcon icon={faCircleCheck} /> Reply to driver
                  </Button>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
        </div>
      ) : (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title=" Ticket Details " />

          <Row>
            <Col sm={12} lg={5} xs={12}>
              <Card className="mb-3 p-4 border-0 shadow-sm">
                <Card.Body>
                  <Card.Img
                    style={{ width: "80px", borderRadius: "10px" }}
                    src={undraw_profile}
                    alt="Generic placeholder image"
                    as={Image}
                    fluid
                  />
                  <Card.Title className="mt-3 mb-2">
                    Driver :
                    <strong>
                      {(data.driver &&
                        data.driver.user.last_name +
                          " " +
                          data.driver.user.first_name) ||
                        "No driver"}
                    </strong>
                  </Card.Title>
                  <Card.Text>
                    <Badge bg="danger p-2  shadow">
                      Manager :{" "}
                      {(data.manager &&
                        data.manager.last_name +
                          " " +
                          data.manager.first_name) ||
                        "No driver"}
                    </Badge>
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card className="mb-3 mt-3 p-4 border-0 shadow-sm">
                <Card.Header className="text-start">
                  <strong>Ticket information</strong>
                </Card.Header>
                <Card.Body>
                  <Row className="mb-3 ">
                    <p className="text-start">{data.note}</p>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={12} lg={7} xs={12}>
              <Card className="mb-3 p-2 border-0 shadow-sm">
                <Card.Body>
                  <Row className="mb-3">
                    <Form.Group>
                      <Table striped bordered hover className="">
                        <tbody>
                          <tr>
                            <td className="fw-700">Truck</td>
                            <td>
                              {data.driver && data.driver.truck.truck_name}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">Truck Platenumber</td>
                            <td>
                              {data.driver && data.driver.truck.plate_number}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">Truck Type</td>
                            <td>
                              {data.driver &&
                                getTruckTypeName(data.driver.truck.truck_type)}
                            </td>
                          </tr>

                          <tr>
                            <td className="fw-700">Truck Department</td>
                            <td>
                              {data.driver &&
                                data.driver.truck.truck_department}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">Truck Weight</td>
                            <td>
                              {data.driver && data.driver.truck.truck_weight}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">Truck Height</td>
                            <td>
                              {data.driver && data.driver.truck.truck_height}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">Status</td>
                            <td>{data.driver && data.driver.truck.status}</td>
                          </tr>
                        </tbody>
                      </Table>

                      <Table striped bordered hover className="">
                        <tbody>
                          <tr>
                            <td className="fw-700">Trailer</td>
                            <td>
                              {data.driver && data.driver.trailer.trailer_name}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">Trailer Platenumber</td>
                            <td>
                              {data.driver && data.driver.trailer.plate_number}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">Trailer Type</td>
                            <td>
                              {data.driver &&
                                getTruckTypeName(
                                  data.driver.trailer.trailer_type
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">Working Load </td>
                            <td>
                              {data.driver && data.driver.trailer.working_load}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">Owm Weight</td>
                            <td>
                              {data.driver && data.driver.trailer.own_weight}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">No Of Axis</td>
                            <td>
                              {data.driver &&
                                data.driver.trailer.number_of_axis}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">Max Weight authorized</td>
                            <td>
                              {data.driver && data.driver.trailer.max_weight}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">Trailer Length</td>
                            <td>
                              {data.driver &&
                                data.driver.trailer.trailer_length}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">Trailer Width</td>
                            <td>
                              {data.driver && data.driver.trailer.trailer_width}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">Trailer Height</td>
                            <td>
                              {data.driver &&
                                data.driver.trailer.trailer_height}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-700">Trailer Status</td>
                            <td>
                              {data.driver &&
                                getStatusName(data.driver.trailer.status)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Form.Group>
                  </Row>

                  <Table bordeless="true" hover className="mt-4">
                    <tbody>
                      <tr className="text-start">
                        <td className="pl-3">
                          <b>Ticket title </b>
                        </td>
                        <td>{data.title}</td>
                      </tr>
                      <tr className="text-start">
                        <td className="pl-3">
                          <b>Ticket type </b>
                        </td>
                        <td>
                          {data.ticket_type && data.ticket_type.ticket_type}
                        </td>
                      </tr>
                      <tr className="text-start">
                        <td className="pl-3">
                          <b>Ticket created</b>
                        </td>
                        <td>{data.created_at}</td>
                      </tr>

                      {data.ticket_status === "closed" ? (
                        <>
                          {/* <tr className="text-start">
                          <td className="pl-3">
                            <b>Ticket closed at</b>
                          </td>
                          <td></td>
                        </tr> */}
                          <tr className="text-start">
                            <td className="pl-3" colSpan={2}>
                              <b>
                                Ticket closed notes :{" "}
                                {data.closed_note || "no Notes"}
                              </b>
                            </td>
                          </tr>
                        </>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </Table>
                  {data.ticket_status !== "closed" ? (
                    <Button
                      onClick={() => setShow(true)}
                      className="text-start w-100"
                    >
                      Close the ticket
                    </Button>
                  ) : (
                    ""
                  )}
                  {show ? (
                    <CloseTicket
                      ticketId={id}
                      showMod={show}
                      onClose={handleClose}
                    />
                  ) : (
                    <CloseTicket showMod={false} />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm={12} lg={12} xs={12}>
              <Card className="mb-3 p-4 border-0 shadow-sm">
                <Card.Header className="text-start">
                  <b>Messages</b>
                </Card.Header>
                <Card.Body>
                  {comments.map((item, idx) => (
                    <div key={idx}>
                      <TicketDetailsChat item={item} />
                    </div>
                  ))}

                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-3 mt-5">
                      <hr />
                      <Form.Group as={Col} className="p-0">
                        <Form.Control
                          as="textarea"
                          name="lastName"
                          placeholder={
                            data && data.driver.user.status == 1
                              ? "Add Reply"
                              : data.driver.user.status == 0
                              ? placeholderForInactiveDriver
                              : placeholderForDeletedDriver
                          }
                          value={reply}
                          disabled={!(data && data.driver.user.status == 1)}
                          onChange={(e) => setReply(e.target.value)}
                          style={{ height: "100px" }}
                        />
                      </Form.Group>
                    </Row>

                    <Row className="mt-2">
                      <Button
                        variant="primary"
                        type="submit"
                        className="shadow rounded-5"
                        disabled={reply.trim().length == 0}
                      >
                        <FontAwesomeIcon icon={faCircleCheck} /> Reply to driver
                      </Button>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  );
};

export default TicketDetials;
