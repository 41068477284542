import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Card from "react-bootstrap/Card";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const DashCard = ({ text, value, icon, cssStyle,to }) => {
  const titleStyle = {
    float: "left",
    fontSize: "2rem",
    fontWeight: "800",
  };
  return (
    <div className="col-xl-3 col-md-6 mb-4">
      <Card className={"shadow-sm " + cssStyle}>
        <Card.Body>
          <div className="content align-items-center">
            <div className="d-flex flex-column">
              <Card.Title style={titleStyle}>
                <b>{value}</b>
              </Card.Title>
              <Card.Subtitle className="fw-bold">{text}</Card.Subtitle>
            </div>
            <FontAwesomeIcon className="icon" icon={icon} />
          </div>
        </Card.Body>
        <div className="card-button">
           <Link to={to}>
           More info <FontAwesomeIcon icon={faCircleArrowRight} />
           </Link>
        </div>
      </Card>
    </div>
  );
};
