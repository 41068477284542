import React from "react";
import { Link, useResolvedPath, useMatch } from "react-router-dom";

function NavTopItems({ nav, onClose, onUrlPath }) {
  const resolvedPath = useResolvedPath(nav.url);
  const isactive = useMatch({ path: resolvedPath.pathname, end: true });
  const navurl = localStorage.getItem("url");
  let urlchecked = "";
  if (navurl !== null) {
    urlchecked = navurl.includes(nav.title) ? navurl : "";
  }

  return (
    <>
      {nav ? (
        <div
          className={
            isactive
              ? "topbar-item active rounded-pill p-3 d-flex justify-content-space-between align-item-center"
              : "topbar-item rounded-pill p-3 d-flex justify-content-space-between align-item-center"
          }
        >
          <div onClick={() => onUrlPath(nav.title, nav.url)}>
            <Link className="top-bar" to={urlchecked || nav.url}>
              <span className="menuItemtitle">{nav.title}</span>
            </Link>
          </div>
          <div className="mx-1">
            <p className="closebtn " onClick={(e) => onClose(nav.id)}>
              x
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default NavTopItems;
