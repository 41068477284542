import React, { useState, useEffect, useContext } from "react";
import { Card } from "react-bootstrap";
import Table from "../Components/Tables";
import CardHeader from "../Components/CardHeader";
import TableActionButton from "../Components/TableActionButton";
import "./loadspage.css";
import { deleteLoads, getByLoadStatus } from "../services/loads";
import { getCurrentUser } from "../services/authServices";
import { userContext } from "../context/UserContext";
import { Role } from "../../constants/role";

const headerButtons = [];

// const conditionalRowStyles = [
//   {
//     when: (row) => row.Status.toLowerCase() === "pending 2",
//     style: {
//       backgroundColor: "rgb(191, 128, 255)",
//       color: "black",
//       "&:hover": {
//         cursor: "pointer",
//       },
//     },
//   },
// ];

function PendingLoadTwo() {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);

  const userData = getCurrentUser();
  const { role } = useContext(userContext);

  const getloaddata = () => {
    getByLoadStatus("pending2").then((res) => {
      setData(res.data);
      /*
      if (role == Role.Admin || role == Role.Super) {
        setData(res.data);
      } else {
        setData(
          res.data.filter((item) => item.driver.manager.id == userData.user_id)
        );
      }
      */

      setPending(false);
    });
  };

  const TableColumn = [
    {
      name: "Truck Plate Nr",
      selector: (row) =>
        row.driver !== null
          ? row.driver.truck && row.driver.truck.plate_number
          : "No Data",
      sortable: true,
    },
    {
      name: "Trailer Plate Nr",
      selector: (row) =>
        row.driver !== null
          ? row.driver.trailer && row.driver.trailer.plate_number
          : "No Data",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.loading_status,
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row) => row.loading_status.toLowerCase() === "pending2",
          style: {
            backgroundColor: "rgba(115, 236, 174, 0.9)",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Loading Country",
      selector: (row) => row.loading_country,
      sortable: true,
    },
    {
      name: "Loading Country Code",
      selector: (row) => row.loading_country_code,
      sortable: true,
    },

    {
      name: "Loading Point",
      selector: (row) => row.loading_location,
      sortable: true,
    },
    {
      name: "Loading Date",
      selector: (row) => row.loading_date,
      sortable: true,
    },
    {
      name: "Identif Number",
      selector: (row) => row.identification_position,
      sortable: true,
    },
    {
      name: "Loading Reference",
      selector: (row) => row.loading_reference,
      sortable: true,
    },
    {
      name: "Unloading Country",
      selector: (row) => row.unloading_country,
      sortable: true,
    },
    {
      name: "Unloading County Code",
      selector: (row) => row.unloading_country_code,
      sortable: true,
    },
    {
      name: "Unloading Point",
      selector: (row) => row.unloading_location,
      sortable: true,
    },
    {
      name: "Unloading Date",
      selector: (row) => row.unloading_date,
      sortable: true,
    },
    {
      name: "Delivery Notes",
      selector: (row) => row.delivery_notes,
      sortable: true,
    },

    {
      name: "Truck Type",
      selector: (row) => {
        if (row.driver && row.driver.truck) {
          if (row.driver && row.driver.truck.truck_type === "S") {
            return "Standard";
          }
          if (row.driver && row.driver.truck.truck_type === "M") {
            return "Mega";
          }
          if (row.driver && row.driver.truck.truck_type === "V") {
            return "Vario";
          }
        } else {
          return "No data";
        }
      },
      sortable: true,
    },
    {
      name: "Department",
      selector: (row) =>
        row.driver !== null
          ? row.driver.truck && row.driver.truck.truck_department
          : "No Data",
      sortable: true,
    },
    {
      name: "Driver",
      selector: (row) =>
        row.driver !== null
          ? row.driver.user.last_name + " " + row.driver.user.first_name
          : "No Data",
      sortable: true,
    },
    {
      name: "Manager",
      selector: (row) =>
        row.driver && row.driver.manager !== null
          ? row.driver.manager.last_name + " " + row.driver.manager.first_name
          : "No Data",
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <TableActionButton
          detailPath={`/dashboard/detail/loads/${row.id}`}
          UpdatePath={`/dashboard/update/loads/${row.id}`}
          deleteId={row.id}
          deleteFun={deleteLoads}
          refreshdata={getloaddata}
        />
      ),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      getloaddata();
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="Pending Loads 2" />

      <Card className="mb-3 border-0 shadow-sm p-2 rounded-5">
        <Card.Body>
          <div className="row">
            <Table
              columns={TableColumn}
              row={data}
              processDataStatus={pending}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default PendingLoadTwo;
