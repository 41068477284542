import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { getManager } from "../services/users";
import undraw_profile from "../../assets/images/undraw_profile.svg";
import { addRoomMember } from "../services/chatURLs";
import { toastSuccess } from "./ToastNotification";

const AddModal = ({
  showMod,
  onClose,
  roomId,
  driver,
  manager,
  member,
  refreshCallback,
}) => {
  const [inputtext, setInputText] = useState("");
  const [managers, setManagers] = useState([]);
  const [selMembers, setSelMembers] = useState(
    member?.map((item) => item.id) ?? []
  );

  const addMemberClickHandler = (id) => {
    let msg = "Added member";
    let data = [];

    if (selMembers.includes(id)) {
      msg = "Removed member";
      data = { members: selMembers.filter((item) => item != id) };
    } else {
      data = {
        members: [...selMembers, id],
      };
    }

    addRoomMember(roomId, data)
      .then((res) => {
        setSelMembers([...data.members]);
        toastSuccess(msg);
        refreshCallback();
      })
      .catch((err) => console.log(err.data));
  };

  useEffect(() => {
    getManager().then((res) =>
      setManagers(
        res.data.filter((item) => item.id != manager && item.status == 1)
      )
    );
    !selMembers.includes(manager) && setSelMembers([...selMembers]);
  }, []);

  const handleSearch = (e) => {
    setInputText(e.target.value);
  };

  const filteredData = managers.filter((data) => {
    return (
      data.first_name.toLowerCase().includes(inputtext.toLowerCase()) ||
      data.last_name.toLowerCase().includes(inputtext.toLowerCase())
    );
  });

  const handleClose = (showMod) => onClose(!showMod);
  return (
    <Modal className="modal-xl" show={showMod} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Add Participants</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="assign_search">
          <div className="searchbar">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearch}
            />
          </div>
        </div>

        {filteredData.length > 0 ? (
          filteredData?.map((user) => (
            <div
              key={user.id}
              className="d-flex align-items-start pt-1 pb-1 d-flex align-items-center model-manager"
            >
              <img
                src={user.image || undraw_profile}
                className="rounded-circle mr-1"
                alt={user.last_name + " " + user.first_name}
                width="40"
                height="40"
              />
              <div className="flex-grow-1 ml-2 mr-5 modal-title">
                {user.last_name + " " + user.first_name}
              </div>
              <button
                onClick={() => addMemberClickHandler(user.id)}
                className={`btn btn-sm ${
                  selMembers.includes(user.id) ? "btn-danger" : "btn-success"
                }`}
              >
                {selMembers.includes(user.id) ? "Remove" : "Add"}
              </button>
            </div>
          ))
        ) : (
          <h3>No More User Found</h3>
        )}
        {/* </tbody>
        </table> */}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleClose} variant="secondary">
          Close
        </Button>
        <Button onClick={handleClose} variant="primary">
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddModal;
