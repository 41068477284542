import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { toastError, toastSuccess } from "../../Components/ToastNotification";
import "./form.css";
import CardHeader from "../../Components/CardHeader";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCircleCheck,
  faTruckArrowRight,
  faTruckLoading,
  faUser,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getByIdLoads, updateLoads } from "../../services/loads";
import { getDriver, getDriverDetails } from "../../services/users";
import { UnpdateloadlocationsModal } from "../../Components/UnpdateloadlocationsModal";
import { UnpdateUnloadlocationsModal } from "../../Components/UnpdateUnloadlocationsModal";
import Skeleton from "react-loading-skeleton";
import LoadsValidator from "../../Components/validators/LoadsValidator";
import Select from "react-select";

const headerButtons = [
  {
    buttonTitle: "Back to loads",
    buttonUrl: "../loads" || -1,
    icon: faArrowLeft,
  },
];

function LoadUpdate() {
  const { id } = useParams();
  const navgate = useNavigate();
  const [data, setData] = useState([]);
  const [date1, setdate] = useState(data.loading_date);
  const [date2, setdate2] = useState(data.unloading_date);
  const [drivers, setDrivers] = useState([]);
  const [driverDetials, setDriverDetials] = useState();
  const [secondloaddata, setSecLoadData] = useState();
  const [secondunloaddata, setSecUnLoadData] = useState();
  const [modalshow, setModalShow] = useState(false);
  const [modalshowtwo, setModalShowTwo] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState();

  const singleDriver = data.driver && data.driver;

  const formatDate = (date) => {
    let d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = "" + d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };

  const loadedLocData = {
    second_loading_country: data.second_loading_country ?? "",
    second_loading_location: data.second_loading_location ?? "",
    second_loading_country_code: data.second_loading_country_code ?? "",
    second_loading_reference: data.second_loading_reference ?? "",
    second_loading_date: data.second_loading_date ?? "",
  };

  const UnloadedLocData = {
    second_unloading_country: data.second_unloading_country ?? "",
    second_unloading_location: data.second_unloading_location ?? "",
    second_unloading_country_code: data.second_unloading_country_code ?? "",
    second_unloading_reference: data.second_unloading_reference ?? "",
    second_unloading_date: data.second_unloading_date ?? "",
  };

  useEffect(() => {
    setTimeout(() => {
      getByIdLoads(id).then((res) => {
        setData(res.data);
        setIsLoading(false);
      });
      getDriver().then((res) => {
        setDrivers(res.data.filter((item) => item.user.status == 1));
      });
    }, 1000);
    if (selected && selected) {
      getDriverDetails(selected.id).then((res) => {
        setDriverDetials(res.data);
      });
    }
  }, [id, selected]);

  const handleClose = (value) => {
    setModalShow(value);
  };

  const handleClosetwo = (value) => {
    setModalShowTwo(value);
  };

  const secLoadData = (data) => {
    setSecLoadData(data);
  };

  const secUnLoadData = (data) => {
    setSecUnLoadData(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let inputs = {
      ...data,
      [name]: value,
    };
    setData({ ...data, [name]: value });
    inputs["driver"] = selected;
    setFormErrors(LoadsValidator(inputs));
  };

  if (secondloaddata) {
    if (secondloaddata["second_loading_country"].trim().length > 0)
      data["second_loading_country"] = secondloaddata["second_loading_country"];

    if (secondloaddata["second_loading_location"].trim().length > 0)
      data["second_loading_location"] =
        secondloaddata["second_loading_location"];

    if (secondloaddata["second_loading_country_code"].trim().length > 0)
      data["second_loading_country_code"] =
        secondloaddata["second_loading_country_code"];

    if (secondloaddata["second_loading_reference"].trim().length > 0)
      data["second_loading_reference"] =
        secondloaddata["second_loading_reference"];

    if (secondloaddata["second_loading_date"].trim().length > 0)
      data["second_loading_date"] = secondloaddata["second_loading_date"];
  }

  if (secondunloaddata) {
    if (secondunloaddata["second_unloading_country"].trim().length > 0)
      data["second_unloading_country"] =
        secondunloaddata["second_unloading_country"];

    if (secondunloaddata["second_unloading_location"].trim().length > 0)
      data["second_unloading_location"] =
        secondunloaddata["second_unloading_location"];

    if (secondunloaddata["second_unloading_country_code"].trim().length > 0)
      data["second_unloading_country_code"] =
        secondunloaddata["second_unloading_country_code"];

    if (secondunloaddata["second_unloading_reference"].trim().length > 0)
      data["second_unloading_reference"] =
        secondunloaddata["second_unloading_reference"];

    if (secondunloaddata["second_unloading_date"].trim().length > 0)
      data["second_unloading_date"] = secondunloaddata["second_unloading_date"];
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (date1 !== undefined) {
      const dateOne = formatDate(date1._d);
      data["loading_date"] = dateOne;
    }
    if (date2 !== undefined) {
      const datatwo = formatDate(date2._d);
      data["unloading_date"] = datatwo;
    }
    if (
      data.driver !== "" &&
      data.loading_location !== "" &&
      data.loading_country !== "" &&
      data.loading_country_code !== "" &&
      data.loading_reference !== "" &&
      data.unloading_location !== "" &&
      data.unloading_country !== "" &&
      data.unloading_country_code !== "" &&
      data.unloading_reference !== "" &&
      data.loading_status !== "" &&
      data.identification_position !== "" &&
      data.delivery_notes !== "" &&
      data.goods_type !== "" &&
      data.indicative !== "" &&
      data.loading_status !== ""
    ) {
      data["driver"] = selected && selected.id;
      updateLoads(id, data)
        .then((res) => {
          toastSuccess("loads successfully updated");
          navgate("../loads");
        })
        .catch((err) => {
          let errObj = err.response.data;
          toastError(errObj.error);
        });
    } else {
      setFormErrors(LoadsValidator(data));
    }
  };

  return (
    <React.Fragment>
      {isloading ? (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="Update" />
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6} sm={6} xs={6} md={6}>
                <Card className="mb-3 border-0 shadow-sm rounded-2">
                  <Card.Body>
                    <div className="form-wrapper">
                      <Row className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            <Skeleton width={24} />
                          </Form.Label>
                          <Form.Group className="mb-3">
                            <Skeleton width={184} height={24} />
                            <Skeleton height={38} />
                          </Form.Group>
                          <Form.Group>
                            <Skeleton width={184} height={24} />
                            <Skeleton height={38} />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Skeleton width={184} height={24} />
                            <Skeleton height={38} />
                          </Form.Group>
                          <Form.Group>
                            <Skeleton width={184} height={24} />
                            <Skeleton height={38} />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Skeleton width={184} height={24} />
                            <Skeleton height={38} />
                          </Form.Group>
                        </Form.Group>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={6} sm={6} xs={6} md={6}>
                <Card className="mb-3 border-0 shadow-sm rounded-2">
                  <Card.Body>
                    <div className="form-wrapper">
                      <Row className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            <Skeleton width={24} />
                          </Form.Label>
                          <Form.Group className="mb-3">
                            <Skeleton width={184} height={24} />
                            <Skeleton height={38} />
                          </Form.Group>
                          <Form.Group>
                            <Skeleton width={184} height={24} />
                            <Skeleton height={38} />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Skeleton width={184} height={24} />
                            <Skeleton height={38} />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Skeleton width={184} height={24} />
                            <Skeleton height={38} />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Skeleton width={184} height={24} />
                            <Skeleton height={38} />
                          </Form.Group>
                        </Form.Group>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={6} sm={6} xs={6} md={6}>
                <Card className="mb-3 border-0 shadow-sm rounded-2">
                  <Card.Body>
                    <div className="form-wrapper">
                      <Row className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            <Skeleton width={24} />
                          </Form.Label>

                          <Form.Group className="mb-3">
                            <Form.Label className="fw-600">
                              <Skeleton width={100} />
                            </Form.Label>
                            <Skeleton height={38} />
                          </Form.Group>

                          <Row className="mb-3">
                            <Form.Group>
                              {singleDriver === undefined ? (
                                ""
                              ) : (
                                <>
                                  <h5>
                                    <Skeleton height={44} />
                                  </h5>
                                  <Table className="mt-4">
                                    <tbody>
                                      <tr>
                                        <td className="fw-700">
                                          <Skeleton height={144} />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <Table className="mt-5">
                                    <tbody>
                                      <tr>
                                        <td className="fw-700">
                                          <Skeleton height={144} />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </>
                              )}
                            </Form.Group>
                          </Row>
                        </Form.Group>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={6} sm={6} xs={6} md={6}>
                <Card className="mb-3 border-0 shadow-sm rounded-2">
                  <Card.Body>
                    <div className="form-wrapper">
                      <Row className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            <Skeleton width={199} height={24} />
                          </Form.Label>

                          <Form.Group className="mb-3">
                            <Skeleton width={160} height={24} />
                            <Skeleton height={100} />
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Skeleton width={184} height={24} />
                            <Skeleton height={38} />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Skeleton width={184} height={24} />
                            <Skeleton height={38} />
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Skeleton width={184} height={24} />
                            <Skeleton height={38} />
                          </Form.Group>
                        </Form.Group>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={12} sm={12} xs={12} md={12}>
                <Card className="mb-3 border-0 shadow-sm rounded-2 p-0 m-0 ">
                  <Card.Body className="text-start">
                    <Skeleton width={355} height={38} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="Update" />
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6} sm={6} xs={6} md={6}>
                <Card className="mb-3 border-0 shadow-sm rounded-2">
                  <Card.Body>
                    <div className="form-wrapper">
                      <Row className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            {" "}
                            <p className="h6 fw-700 mb-3 text-danger">
                              {" "}
                              <FontAwesomeIcon icon={faTruckLoading} /> From
                            </p>
                          </Form.Label>

                          <Form.Group className="mb-3">
                            <Form.Label className="fw-600">
                              Loading Location
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="loading_location"
                              onChange={handleChange}
                              value={data.loading_location}
                              className="border-2"
                              placeholder="Enter the location"
                              isInvalid={formErrors.loading_location}
                            />
                            {formErrors.loading_location && (
                              <Form.Control.Feedback type="invalid">
                                {formErrors.loading_location}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>

                          <Form.Group>
                            <Form.Label className="fw-600">
                              Loading Date
                            </Form.Label>
                            <Datetime
                              onChange={(date) => setdate(date)}
                              name="loading_date"
                              initialViewMode={("years", "months", "days")}
                              value={date1 || new Date(data.loading_date)}
                              timeFormat={false}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-600">
                              Loading Country
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="loading_country"
                              onChange={handleChange}
                              value={data.loading_country}
                              className="border-2"
                              placeholder="Enter the Country"
                              isInvalid={formErrors.loading_country}
                            />
                            {formErrors.loading_country && (
                              <Form.Control.Feedback type="invalid">
                                {formErrors.loading_country}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>

                          <Form.Group>
                            <Form.Label className="fw-600">
                              Loading Country Code
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="loading_country_code"
                              onChange={handleChange}
                              value={data.loading_country_code}
                              className="border-2"
                              placeholder="Enter the Country Code"
                              isInvalid={formErrors.loading_country_code}
                            />
                            {formErrors.loading_country_code && (
                              <Form.Control.Feedback type="invalid">
                                {formErrors.loading_country_code}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-600">
                              Loading Reference{" "}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="loading_reference"
                              onChange={handleChange}
                              value={data.loading_reference}
                              className="border-2"
                              placeholder="Enter the Reference"
                              isInvalid={formErrors.loading_reference}
                            />
                            {formErrors.loading_reference && (
                              <Form.Control.Feedback type="invalid">
                                {formErrors.loading_reference}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Form.Group>
                        <Button onClick={() => setModalShow(true)}>
                          Add Second Loading Location
                        </Button>
                        {modalshow ? (
                          <UnpdateloadlocationsModal
                            title="Loading"
                            showMod={modalshow}
                            onClose={handleClose}
                            loaddata={loadedLocData}
                            onSubmit={secLoadData}
                          />
                        ) : (
                          <UnpdateloadlocationsModal showMod={false} />
                        )}
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={6} sm={6} xs={6} md={6}>
                <Card className="mb-3 border-0 shadow-sm rounded-2">
                  <Card.Body>
                    <div className="form-wrapper">
                      <Row className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            {" "}
                            <p className="h6 fw-700  mb-3 text-danger">
                              {" "}
                              <FontAwesomeIcon
                                icon={faTruckArrowRight}
                              /> To{" "}
                            </p>{" "}
                          </Form.Label>
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-600">
                              UnLoading Location
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="unloading_location"
                              onChange={handleChange}
                              value={data.unloading_location}
                              className="border-2"
                              placeholder="Enter the Unload location"
                              isInvalid={formErrors.unloading_location}
                            />
                            {formErrors.unloading_location && (
                              <Form.Control.Feedback type="invalid">
                                {formErrors.unloading_location}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>

                          <Form.Group>
                            <Form.Label className="fw-600">
                              UnLoading Date
                            </Form.Label>
                            <Datetime
                              onChange={(date) => setdate2(date)}
                              name="unloading_date"
                              value={date2 || new Date(data.unloading_date)}
                              timeFormat={false}
                              input
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-600">
                              UnLoading Country
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="unloading_country"
                              onChange={handleChange}
                              value={data.unloading_country}
                              className="border-2"
                              placeholder="Enter the Unload Country"
                              isInvalid={formErrors.unloading_country}
                            />
                            {formErrors.unloading_country && (
                              <Form.Control.Feedback type="invalid">
                                {formErrors.unloading_country}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-600">
                              UnLoading Country Code
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="unloading_country_code"
                              onChange={handleChange}
                              value={data.unloading_country_code}
                              className="border-2"
                              placeholder="Enter the Unload Country Code"
                              isInvalid={formErrors.unloading_country_code}
                            />
                            {formErrors.unloading_country_code && (
                              <Form.Control.Feedback type="invalid">
                                {formErrors.unloading_country_code}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-600">
                              UnLoading Reference
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="unloading_reference"
                              onChange={handleChange}
                              value={data.unloading_reference}
                              className="border-2"
                              placeholder="Enter the UnLoading Reference"
                              isInvalid={formErrors.unloading_reference}
                            />
                            {formErrors.unloading_reference && (
                              <Form.Control.Feedback type="invalid">
                                {formErrors.unloading_reference}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Form.Group>
                        <Button onClick={() => setModalShowTwo(true)}>
                          Add Second UnLoading Location
                        </Button>
                        {modalshowtwo ? (
                          <UnpdateUnloadlocationsModal
                            title="UnLoading"
                            showMod={modalshowtwo}
                            onClose={handleClosetwo}
                            loaddata={UnloadedLocData}
                            onSubmit={secUnLoadData}
                          />
                        ) : (
                          <UnpdateUnloadlocationsModal showMod={false} />
                        )}
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={6} sm={6} xs={6} md={6}>
                <Card className="mb-3 border-0 shadow-sm rounded-2">
                  <Card.Body>
                    <div className="form-wrapper">
                      <Row className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            {" "}
                            <p className="h6 fw-700 mb-3 text-danger">
                              {" "}
                              <FontAwesomeIcon icon={faUser} /> Driver
                            </p>
                          </Form.Label>

                          <Form.Group className="mb-3">
                            <Form.Label className="fw-600">Driver</Form.Label>
                            <Select
                              getOptionLabel={(option) =>
                                `${option.user.last_name} ${option.user.first_name}`
                              }
                              isOptionSelected={(option) =>
                                selected === option.id ? true : false
                              }
                              options={drivers}
                              defaultValue={singleDriver}
                              placeholder="Choose driver"
                              onChange={(value) => {
                                let inputs = {
                                  ...data,
                                  driver: value,
                                };
                                setData({ ...data, driver: value });
                                inputs["driver"] = selected;
                                setFormErrors(LoadsValidator(inputs));
                                setSelected(value);
                              }}
                            />
                          </Form.Group>

                          <Row className="mb-3">
                            <Form.Group>
                              {driverDetials ? (
                                <>
                                  <Table
                                    striped
                                    bordered
                                    hover
                                    className="mt-4"
                                  >
                                    <tbody>
                                      <tr>
                                        <td className="fw-700">Truck</td>
                                        <td>
                                          <b>
                                            {driverDetials &&
                                            typeof driverDetials.truck !==
                                              "undefined" &&
                                            driverDetials.truck === null
                                              ? "truck name empty"
                                              : driverDetials &&
                                                driverDetials.truck.truck_name}
                                          </b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="fw-700">
                                          Truck Plate Number
                                        </td>
                                        <td>
                                          <b>
                                            {driverDetials &&
                                            typeof driverDetials.truck !==
                                              "undefined" &&
                                            driverDetials.truck === null
                                              ? "truck plate number empty"
                                              : driverDetials &&
                                                driverDetials.truck
                                                  .plate_number}
                                          </b>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>

                                  <Table
                                    striped
                                    bordered
                                    hover
                                    className="mt-5"
                                  >
                                    <tbody>
                                      <tr>
                                        <td className="fw-700">Trailer</td>
                                        <td>
                                          <b>
                                            {driverDetials &&
                                            typeof driverDetials.trailer !==
                                              "undefined" &&
                                            driverDetials.trailer === null
                                              ? "trailer name empty"
                                              : driverDetials &&
                                                driverDetials.trailer
                                                  .trailer_name}
                                          </b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="fw-700">
                                          Trailer Plate Number
                                        </td>
                                        <td>
                                          <b>
                                            {driverDetials &&
                                            typeof driverDetials.trailer !==
                                              "undefined" &&
                                            driverDetials.trailer === null
                                              ? "trailer plate number empty"
                                              : driverDetials &&
                                                driverDetials.trailer
                                                  .plate_number}
                                          </b>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </>
                              ) : (
                                ""
                              )}

                              {singleDriver === undefined ||
                              singleDriver === null ? (
                                ""
                              ) : (
                                <>
                                  <h5 className="textEsist">Existing Data</h5>
                                  <Table
                                    striped
                                    bordered
                                    hover
                                    className="mt-4"
                                  >
                                    <tbody>
                                      <tr>
                                        <td className="fw-700">Truck</td>
                                        <td>
                                          <p>
                                            {singleDriver.truck &&
                                              singleDriver.truck.truck_name}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="fw-700">
                                          Truck Plate Number
                                        </td>
                                        <td>
                                          <p>
                                            {singleDriver.truck &&
                                              singleDriver.truck.plate_number}
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <Table
                                    striped
                                    bordered
                                    hover
                                    className="mt-5"
                                  >
                                    <tbody>
                                      <tr>
                                        <td className="fw-700">Trailer</td>
                                        <td>
                                          <p>
                                            {singleDriver.trailer &&
                                              singleDriver.trailer.trailer_name}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="fw-700">
                                          Trailer Plate Number
                                        </td>
                                        <td>
                                          <p>
                                            {singleDriver.trailer &&
                                              singleDriver.trailer.plate_number}
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </>
                              )}
                            </Form.Group>
                          </Row>
                        </Form.Group>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={6} sm={6} xs={6} md={6}>
                <Card className="mb-3 border-0 shadow-sm rounded-2">
                  <Card.Body>
                    <div className="form-wrapper">
                      <Row className="mb-3">
                        <Form.Group>
                          <Form.Label>
                            {" "}
                            <p className="h6 fw-700 mb-3 text-danger">
                              {" "}
                              <FontAwesomeIcon icon={faStickyNote} /> Additional
                              information
                            </p>
                          </Form.Label>

                          <Form.Group className="mb-3">
                            <Form.Label className="fw-600">
                              Delivery notes
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              name="delivery_notes"
                              onChange={handleChange}
                              placeholder="Add Delivery Notes"
                              value={data.delivery_notes}
                              style={{ height: "100px" }}
                              isInvalid={formErrors.delivery_notes}
                            />
                            {formErrors.delivery_notes && (
                              <Form.Control.Feedback type="invalid">
                                {formErrors.delivery_notes}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label className="fw-600">
                              Goods Type
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="goods_type"
                              onChange={handleChange}
                              value={data.goods_type}
                              placeholder="Enter the Goods Type"
                              isInvalid={formErrors.goods_type}
                            />
                            {formErrors.goods_type && (
                              <Form.Control.Feedback type="invalid">
                                {formErrors.goods_type}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-600">
                              Identification Position
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="identification_position"
                              onChange={handleChange}
                              placeholder="Enter Identification Position"
                              value={data.identification_position}
                              isInvalid={formErrors.identification_position}
                            />
                            {formErrors.identification_position && (
                              <Form.Control.Feedback type="invalid">
                                {formErrors.identification_position}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-600">
                              Indicative
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="indicative"
                              onChange={handleChange}
                              placeholder="Enter a Indicative"
                              value={data.indicative}
                              isInvalid={formErrors.indicative}
                            />
                            {formErrors.indicative && (
                              <Form.Control.Feedback type="invalid">
                                {formErrors.indicative}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label className="fw-600">
                              Load Status
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="loading_status"
                              onChange={handleChange}
                              value={data.loading_status}
                              isInvalid={formErrors.loading_status}
                            >
                              <option value="pending1">Pending 1</option>
                              <option value="pending2">Pending 2</option>
                              <option value="loading">Loading</option>
                              <option value="closed">Closed</option>
                              <option value="complete">Complete</option>
                              <option value="transit">Transit</option>
                            </Form.Select>
                            {formErrors.loading_status && (
                              <Form.Control.Feedback type="invalid">
                                {formErrors.loading_status}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Form.Group>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={12} sm={12} xs={12} md={12}>
                <Card className="mb-3 border-0 shadow-sm rounded-2 p-0 m-0 ">
                  <Card.Body className="text-start">
                    <Button
                      variant="primary"
                      type="submit"
                      className="rounded-5 shadow-sm w-25"
                    >
                      <FontAwesomeIcon icon={faCircleCheck} /> Update Load
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </React.Fragment>
  );
}

export default LoadUpdate;
