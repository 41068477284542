import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { LOC } from "../../../constants/constants";
import { getjwt } from "../../services/authServices";
import { Link } from "react-router-dom";
import { onMessage } from "@firebase/messaging";
import { messaging } from "../../../FCM/firebase";
import {
  getNotificationList,
  readNotification,
  readNotificationByPost,
} from "../../services/users";
import { toastNotification } from "../../Components/ToastNotification";
import { makeNotificationStatus, processNotification } from "../../utils";
import getTimeFormated from "../../utils/timeFormat";
import { userContext } from "../../context/UserContext";

export const Notification = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { notifications, setNotifications } = useContext(userContext);

  const token = getjwt();

  const [isTabVisible, setIsTabVisible] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setIsTabVisible(document.visibilityState === "visible");
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    isTabVisible && console.log("notification loading");
    isTabVisible &&
      getNotificationList().then((res) => {
        console.log(res.data.data);
        setNotifications(res.data.data);
      });
  }, [window?.location?.pathname, isTabVisible]);

  onMessage(messaging, (payload) => {
    console.log("Foreground Message:", payload, window.location.pathname);

    if (
      payload.data.type == "chat_message" &&
      window.location.pathname.includes("liveloads")
    ) {
      console.log("we are in chat page");
    } else {
      toastNotification(
        payload.notification.title,
        payload.notification.body,
        payload.data,
        onNotificationClick
      );

      getNotificationList().then((res) => {
        setNotifications(res.data.data);
      });
    }
  });

  const onNotificationClick = (payload) => {
    processNotification(payload);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  /*
  useEffect(() => {
    const newsocket = new WebSocket(WebSocketEndpoint + `?token=${token}`);
    newsocket.onopen = (e) => {
      console.log("connected");
    };
    newsocket.onmessage = (e) => {
      setNotifications((prev) => [...prev, JSON.parse(e.data)]);
    };
  }, [WebSocketEndpoint, token]);
  */

  const getFulldateTime = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const time = getTimeFormated(date);
    const withHyphens = [year, month, day].join("-");
    return withHyphens + " " + time;
  };

  const handleReadOne = (notification) => {
    //const newArr = notifications.filter((item) => notifications[item] !== idx);
    //setNotifications(newArr);
    readNotification(notification.id).then((res) => console.log(res.data));
    toggleDropdown();

    setTimeout(() => {
      if (notification.type == "load_status_updated") {
        window.location.href = `${window.location.origin}/dashboard/detail/loads/${notification.content}`;
      } else if (
        notification.type == "ticket_created" ||
        notification.type == "ticket_updated" ||
        notification.type == "ticket_replied"
      ) {
        window.location.href = `${window.location.origin}/dashboard/detail/tickets/${notification.content}`;
      } else if (notification.type == "chat_message") {
        window.location.href = `${window.location.origin}/dashboard/liveloads/${notification.content}`;
      }
    }, 300);
  };

  return (
    <>
      <button className="notification-btn" onClick={toggleDropdown}>
        {notifications.length === 0 ? "" : <div className="pulse-css" />}
        <FontAwesomeIcon width={25} height={25} icon={faBell} />
      </button>

      {showDropdown &&
        (notifications.length === 0 ? (
          <div className="dropdown-noti">
            <div className="timeline-panel">
              <div className="media-body">
                <h6>Notifications</h6>
                <p className="mb-1">there is no notifications</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="dropdown-noti">
            {notifications.map((notitfys, idx) => (
              <ul key={idx}>
                <Link
                  to={
                    notitfys.type === "TICKET CREATED" ? "tickets" : "Complete"
                  }
                >
                  <li>
                    <div
                      onClick={() => handleReadOne(notitfys)}
                      className="timeline-panel"
                    >
                      <div className="media-body">
                        <h6>{makeNotificationStatus(notitfys.type)}</h6>
                        <p className="mb-1">{notitfys.message}</p>
                        <p className="mb-1 notification-time">
                          {getFulldateTime(notitfys.updated_at)}
                        </p>
                      </div>
                    </div>
                  </li>
                </Link>
              </ul>
            ))}
          </div>
        ))}
    </>
  );
};
