import { APIURL } from "../../constants/constants";
import http from "./ServerApi";

export const getTickets = async () => {
  return await http.get(APIURL + "/ticket/");
};
export const addTicket = async (data) => {
  return await http.post(APIURL + "/ticket/add/", data);
};
export const getTicketById = async (id) => {
  return await http.get(APIURL + `/ticket/${id}/`);
};
export const updateByTicketStatus = async (id, data) => {
  return await http.put(APIURL + `/ticket/status/update/${id}/`, data);
};
export const getByTicketStatus = async (id) => {
  return await http.get(APIURL + `/ticket/?ticket_status=${id}`);
};

export const updateTicket = async (id, data) => {
  return await http.update(APIURL + `/ticket/update/${id}/`, data);
};

export const deleteTicket = async (id) => {
  return await http.delete(APIURL + `/ticket/delete/${id}/`);
};

export const getTicketComments = async (id) => {
  return await http.get(APIURL + `/ticket/messages/${id}/`);
};

export const addTicketComments = async (data) => {
  return await http.post(APIURL + "/ticket/messages/", data);
};
