import {
  faPaperPlane,
  faPlusCircle,
  faUser,
  faClock,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect, useContext } from "react";
import undraw_profile from "../../../assets/images/undraw_profile.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddModal from "../../Components/Model";
import { useState } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { LOC, APIURL } from "../../../constants/constants";
import { getCurrentUser } from "../../services/authServices";
import { getMessagesByRoomId, getRoomById } from "../../services/chatURLs";
import Skeleton from "react-loading-skeleton";
import getTimeFormated from "../../utils/timeFormat";
import {
  generateUserImageLink,
  placeholderForCompletedLoad,
  placeholderForDeletedDriver,
  placeholderForInactiveDriver,
} from "../../utils";
import {
  getNotificationList,
  readNotificationByPost,
} from "../../services/users";
import { userContext } from "../../context/UserContext";

const LiveInput = ({ selectedUser, driverId, roomId }) => {
  const [show, setShow] = useState(false);
  const [inputtext, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState();
  const [isloading, setIsLoading] = useState(true);
  const [onlineUser, setOnlineUser] = useState([]);
  const { setNotifications } = useContext(userContext);

  const [roomInfo, setRoomInfo] = useState(null);

  const WSSTART = window.location.protocol === "https:" ? "wss://" : "ws://";
  console.log("Live input = ", selectedUser);

  const WebSocketEndpoint = WSSTART + LOC + "/ws/chat/";
  const user = getCurrentUser();

  const setRef = useCallback((node) => {
    if (node) {
      node.scrollIntoView({ smooth: true });
    }
  }, []);

  const handleClose = (value) => {
    setShow(value);
  };

  const handleTextMessage = (e) => {
    setInputText(e.target.value);
  };

  const handleMessage = (e) => {
    e.preventDefault();

    if (inputtext.trim().length == 0) return;

    const members = [user.user_id, driverId];
    const data = {
      room: driverId,
      user: user.user_id,
    };
    socket.send(
      JSON.stringify({
        action: "message",
        members: members,
        message: inputtext,
        roomId: roomId,
        user: data.user,
        room: data.room,
      })
    );

    setTimeout(() => setInputText(""));
  };

  const getChatClassName = (userId) => {
    return user.user_id === userId
      ? "chat-message-right pd-4"
      : "chat-message-left pb-4";
  };

  const getMessagesFromRoom = (id) => {
    if (id !== undefined) {
      getMessagesByRoomId(parseInt(id)).then((res) => {
        setMessages(res.data);
        setIsLoading(false);
      });
    }
  };

  const getRoomInfo = (id) => {
    if (id !== undefined) {
      getRoomById(id).then((res) => {
        setRoomInfo(res.data);
      });
    }
  };

  useEffect(() => {
    //read notification
    readNotificationByPost({
      type: "chat_message",
      content: parseInt(roomId),
    }).then((res) => {
      getNotificationList().then((res) => {
        console.log(res.data.data);
        setNotifications(res.data.data);
      });
    });

    setInputText("");

    getMessagesFromRoom(roomId);
    getRoomInfo(roomId);

    const newsocket = new WebSocket(WebSocketEndpoint + `${roomId}/`);
    setSocket(newsocket);
    newsocket.onopen = () => {
      console.log("chat connected");
    };
    newsocket.onclose = () => {
      console.log("connection closed");
    };
    newsocket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      const loadIdInt = parseInt(roomId);
      if (data.loadId === loadIdInt) {
        if (data.action === "message") {
          setMessages((prevMsg) => [...prevMsg, data]);
        }

        //read notification
        readNotificationByPost({
          type: "chat_message",
          content: loadIdInt,
        }).then((res) => {
          getNotificationList().then((res) => {
            console.log(res.data.data);
            setNotifications(res.data.data);
          });
        });
      }
      if (data.action === "onlineUser") {
        setOnlineUser(data.userList);
      }
    };

    return () => {
      newsocket.close();
    };
  }, [roomId]);

  const getPlaceholder = () => {
    if (!selectedUser) return "";

    if (selectedUser.user.status == 1) {
      return "Enter Message...";
    } else {
      if (selectedUser.original_driver_id > -1) {
        return placeholderForCompletedLoad;
      } else {
        if (selectedUser.user.status == 0) {
          return placeholderForInactiveDriver;
        } else {
          return placeholderForDeletedDriver;
        }
      }
    }
  };

  return (
    <React.Fragment>
      <section className="chat">
        <div className="header-chat">
          <Row className="align-items-center">
            <Col md={8} sm={4}>
              <div className="icon">
                <FontAwesomeIcon icon={faUser} />
                <div className="name">
                  <b>
                    {selectedUser &&
                      selectedUser.user.last_name +
                        " " +
                        selectedUser.user.first_name}
                  </b>
                  <small>{"online"}</small>{" "}
                </div>
              </div>
            </Col>
            <Col md={4} sm={8}>
              <ul className="chat-list">
                <li className="chat-list-item">
                  <Button variant="none" className="icon_bubble">
                    <FontAwesomeIcon
                      className=" clickable msg "
                      icon={faPlusCircle}
                      visibility={
                        selectedUser && selectedUser.user.status
                          ? "block"
                          : "hidden"
                      }
                      onClick={() => setShow(true)}
                    />
                  </Button>

                  {show && roomInfo && (
                    <AddModal
                      showMod={show}
                      onClose={handleClose}
                      roomId={roomInfo.id}
                      driver={roomId}
                      manager={selectedUser.manager.id}
                      member={roomInfo.member}
                      refreshCallback={() => getRoomInfo(roomId)}
                    />
                  )}
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        {isloading ? (
          <div className="otherMessagebox">
            <div className="messagebox-wrapper">
              <div className="chat-messages p-4">
                <Skeleton width={70} />
                <Skeleton width={70} />
              </div>
            </div>
          </div>
        ) : (
          <div className="otherMessagebox">
            <div className="messagebox-wrapper">
              <div className="chat-messages p-4">
                {messages.length === 0 ? (
                  <div>
                    <p>Please initate first msg and refresh</p>
                  </div>
                ) : (
                  ""
                )}
                {messages.map((obj, idx) => {
                  const lastMessage = messages.length - 1 === idx;
                  return (
                    <React.Fragment key={idx}>
                      <div
                        ref={lastMessage ? setRef : null}
                        className={getChatClassName(obj.sender)}
                      >
                        <div className="chat-avatar">
                          {onlineUser.includes(
                            selectedUser && selectedUser.user.id
                          ) ? (
                            <div className="online" />
                          ) : (
                            ""
                          )}
                          <img
                            src={
                              obj.userImage
                                ? generateUserImageLink(obj.userImage)
                                : undraw_profile
                            }
                            alt="userImage"
                            style={{ objectFit: "cover" }}
                            width="50"
                            height="50"
                          />

                          <div className="text-muted chat_name">
                            {obj.userName}
                          </div>
                        </div>
                        <div className="chat-right">
                          <div className="flex-shrink-1 rounded py-2 px-3 mr-3 ">
                            <p className="mb-0">{obj.message}</p>
                            <p className="chat-time mb-0">
                              <FontAwesomeIcon
                                icon={faClock}
                                className="align-middle"
                              />
                              <span className="align-middle">
                                {" " + getTimeFormated(obj.timestamp)}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      {user.user_id === obj.sender ? (
                        <div className="chat-read">
                          {obj.is_read ? (
                            <FontAwesomeIcon icon={faCheckDouble} />
                          ) : (
                            <FontAwesomeIcon
                              icon={faCheckDouble}
                              className="tickicon"
                            />
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <div className="footer-chat">
          <Form onSubmit={handleMessage}>
            <Row>
              <Col xs={12} md={10}>
                <Form.Control
                  value={inputtext}
                  placeholder={getPlaceholder()}
                  type="text"
                  disabled={!(selectedUser && selectedUser.user.status == 1)}
                  onChange={handleTextMessage}
                />
              </Col>
              <Col xs={6} md={1}>
                <Button
                  variant="none"
                  className="send"
                  type="submit"
                  disabled={inputtext.trim().length == 0}
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </section>
    </React.Fragment>
  );
};

export default LiveInput;
