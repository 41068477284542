import { dateRegex } from "../../utils";

const secondLoadingLoationValidator = (value) => {
  let errors = {};

  if (!value["second_loading_location"]) {
    errors.second_loading_location = "Loading location is required";
  }

  if (!value["second_loading_date"]) {
    errors.second_loading_date = "Loading date is required";
  } else if (!dateRegex.test(value["second_loading_date"])) {
    errors.second_loading_date = "Invalid date format";
  }

  if (!value["second_loading_country"]) {
    errors.second_loading_country = "Loading country is required";
  }

  if (!value["second_loading_country_code"]) {
    errors.second_loading_country_code = "Loading country code is required";
  }

  if (!value["second_loading_reference"]) {
    errors.second_loading_reference = "Loading reference is required";
  }

  return errors;
};
export default secondLoadingLoationValidator;
