import React from "react";
import Chart from "react-apexcharts";

const TruckApexChart = ({ loadCount, unloadCount }) => {
  const series = [loadCount, unloadCount];
  const options = {
    labels: ["loaded", "unload"],
    chart: {
      width: 380,
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: "right",
      offsetY: 0,
      height: 230,
    },
  };

  return (
    <div>
      <div className="chart-wrap">
        <div id="chart">
          <Chart options={options} series={series} type="donut" width={380} />
        </div>
      </div>
    </div>
  );
};

export default TruckApexChart;
