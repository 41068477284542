const getTimeFormated = (date) => {
    let dateObj = new Date(date);
    let hour = dateObj.getHours();
    let mintue = dateObj.getMinutes();
    let merdian = "am";
    if (hour > 12) {
      hour -= 12;
      merdian = "pm";
    }
    if (hour === 0) {
      hour = 12;
    }
    if (mintue < 10) {
      mintue = "0" + mintue;
    }
    return hour + ":" + mintue + " " + merdian;
  };

  export default getTimeFormated;