import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import ClosedLiveInput from "./ClosedLiveInput";
import { getUserById } from "../../services/users";
import CardHeader from "../../Components/CardHeader";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { getByIdLoads } from "../../services/loads";

const headerButtons = [
  {
    buttonTitle: "Back",
    buttonUrl: -1,
    icon: faBackward,
  },
];

function ClosedChat() {
  const { id } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    getByIdLoads(id).then((res) => {
      setData(res.data);
    });
  }, [id]);
  const driverId = data && data.driver.user.id;

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title={`LOAD ${id}`} />
      <Row className="Chat-DM">
        <Col>
          {data && (
            <ClosedLiveInput
              selectedUser={data.driver}
              driverId={driverId}
              roomId={id}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default ClosedChat;
