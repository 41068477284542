import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import CardHeader from "../../../Components/CardHeader";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toastSuccess } from "../../../Components/ToastNotification";
import { getDriverGroups } from "../../../services/DriverGroups";
import { getjwt } from "../../../services/authServices";
import { addDriverMessageGroup } from "../../../services/DriverGroupMessage";
import { useNavigate } from "react-router-dom";

const groupMessageValidator = (value) => {
  let errors = {};
  if (!value["title"]) {
    errors.title = "Message Title is required";
  }
  if (!value["drivers_groups"]) {
    errors.drivers_groups = "Select a driver Group";
  }
  if (!value["message"]) {
    errors.message = "message is required";
  }
  return errors;
};

const headerButtons = [
  {
    buttonTitle: "Back to messages",
    buttonUrl: "../Messages",
    icon: faArrowLeft,
  },
];

const INITIAL_DATA = {
  title: "",
  drivers_groups: "",
  message: "",
};

const AddDriverMessage = () => {
  const navgate = useNavigate();
  const [data, setData] = useState(INITIAL_DATA);
  const [formErrors, setFormErrors] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getDriverGroups().then((res) => setGroups(res.data));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    let inputs = {
      ...data,
      [name]: value,
    };
    setFormErrors(groupMessageValidator(inputs));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt();
    if (
      data.title !== "" &&
      data.drivers_groups !== "" &&
      data.message !== ""
    ) {
      data["token_data"] = JSON.parse(token);
      addDriverMessageGroup(data)
        .then((res) => {
          toastSuccess("Successfully created Driver Group Message");
          navgate("../Messages");
        })
        .catch((err) => console.log(err));
    } else {
      setFormErrors(groupMessageValidator(data));
    }
  };

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="SEND GROUP MESSAGE" />

      <Card className="mb-3 border-0 shadow-sm rounded-5">
        <Card.Body>
          <div className="form-wrapper">
            <Form
              noValidate
              className="needs-validation"
              onSubmit={handleSubmit}
            >
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label htmlFor="text" className="fw-600">
                    Message Title
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Message Title"
                    onChange={handleChange}
                    value={data.title}
                    className="border-2"
                    isInvalid={formErrors.title}
                  />
                  {formErrors.title && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.title}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className="fw-600"> Drivers group</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="drivers_groups"
                    onChange={handleChange}
                    value={data.drivers_groups}
                    isInvalid={formErrors.drivers_groups}
                  >
                    <option value="">Select group</option>
                    {groups.map((item, idx) => (
                      <option key={idx} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </Form.Select>
                  {formErrors.drivers_groups && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.drivers_groups}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="message"
                    placeholder="Enter Message For driver group"
                    onChange={handleChange}
                    value={data.message}
                    style={{ height: "300px" }}
                    isInvalid={formErrors.message}
                  />
                  {formErrors.message && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>

              <Button
                variant="primary"
                type="submit"
                className="shadow rounded-5"
              >
                <FontAwesomeIcon icon={faCircleCheck} /> Send Message
              </Button>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddDriverMessage;
