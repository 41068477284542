import React, { useState, useMemo, useEffect, useRef } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import CardHeader from "../../../Components/CardHeader";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  toastError,
  toastSuccess,
} from "../../../Components/ToastNotification";
import {
  getDriverGroupById,
  updateDriversGroup,
} from "../../../services/DriverGroups";
import { useNavigate, useParams } from "react-router-dom";
import { getjwt } from "../../../services/authServices";
import Skeleton from "react-loading-skeleton";
import { getDriver } from "../../../services/users";
import FilterComponent from "../../../SearchBox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";

const groupValidator = (value, diverlist) => {
  let errors = {};
  if (!value["title"]) {
    errors.title = "Group Name is required";
  }
  if (diverlist && diverlist.length > 0) {
    errors.diverlist = "manager is required";
  }
  if (!value["drivers"]) {
    errors.drivers = "manager is required";
  }
  return errors;
};

const headerButtons = [
  { buttonTitle: "Back", buttonUrl: "../groups", icon: faArrowLeft },
];

const UpdateDriversGroup = () => {
  const { id } = useParams();
  const navgate = useNavigate();
  const [isMounted, setMounted] = useState(false);
  const [data, setData] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" },
      truck: { value: "", matchMode: "contains" },
      trailer: { value: "", matchMode: "contains" },
    },
  });
  const [drivers, setDrivers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const listDriver = () => {
    setTimeout(() => {
      setIsLoading(true);
      getDriverGroupById(id).then((res) => {
        setSelectedDrivers(res.data.drivers);
        setData(res.data);
        setIsLoading(false);
      });
    }, 1000);
  };

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (isMounted && id) {
      listDriver();
      getDriver().then((res) => {
        const tempData = res.data.map((item) => ({
          ...item,
          name: item.user.first_name + " " + item.user.last_name,
          truck: item.truck?.truck_name ?? "",
          trailer: item.trailer?.trailer_name ?? "",
        }));

        setDrivers(tempData.filter((item) => item.user.status == 1));
      });
    }
  }, [isMounted, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    let inputs = {
      ...data,
      [name]: value,
    };
    setFormErrors(groupValidator(inputs));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedDrivers.length == 0) {
      toastError("One driver should be selected at least!");
      return;
    }

    const token = getjwt();
    data["token_data"] = JSON.parse(token);
    if (data.title !== "" && data.drivers !== "" && drivers.length > 0) {
      data["drivers"] = selectedDrivers.map((item) => item.id);
      updateDriversGroup(id, data).then((res) => {
        toastSuccess("Successfully Updated DriverGroup");
        navgate("../groups");
      });
    }
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.name}</span>
      </div>
    );
  };

  const truckBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.truck}</span>
      </div>
    );
  };
  const trailerBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.trailer}</span>
      </div>
    );
  };

  const onPage = (event) => {
    setlazyState(event);
  };

  const onSort = (event) => {
    setlazyState(event);
  };

  const onSelectionChange = (event) => {
    const value = event.value;

    setSelectedDrivers(value);
    setSelectAll(value.length === drivers.length);
  };

  const onSelectAllChange = (event) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedDrivers([...drivers]);
    } else {
      setSelectAll(false);
      setSelectedDrivers([]);
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          style={{ width: "50%" }}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      {isloading ? (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="Update GROUP" />

          <Card className="mb-3 border-0 shadow-sm rounded-5">
            <Card.Body>
              <div className="form-wrapper">
                <Form
                  noValidate
                  className="needs-validation"
                  onSubmit={handleSubmit}
                >
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Skeleton width={100} height={24} />
                      <Skeleton height={37} />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="multiselect_field">
                      <Form.Label className="fw-600">
                        <Skeleton width={100} height={24} />
                      </Form.Label>
                      <table id="customers">
                        <tbody>
                          <tr>
                            <td>
                              <Skeleton height={200} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Form.Group>
                  </Row>
                  <hr></hr>
                  <Skeleton width={150} height={30} />
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="Update GROUP" />

          <Card className="mb-3 border-0 shadow-sm rounded-5">
            <Card.Body>
              <div className="form-wrapper">
                <Form
                  noValidate
                  className="needs-validation"
                  onSubmit={handleSubmit}
                >
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label htmlFor="text" className="fw-600">
                        Group Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        placeholder="Add a Group Name"
                        onChange={handleChange}
                        value={data.title}
                        className="border-2"
                        isInvalid={formErrors.title}
                      />
                      {formErrors.title && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.title}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="multiselect_field">
                      <Form.Label className="fw-600">Select Drivers</Form.Label>
                      <div>
                        {formErrors.diverlist && (
                          <Form.Control.Feedback type="invalid">
                            {formErrors.diverlist}
                            {console.log(formErrors)}
                          </Form.Control.Feedback>
                        )}
                      </div>

                      <DataTable
                        value={drivers}
                        dataKey="id"
                        paginator
                        removableSort
                        first={lazyState.first}
                        rows={10}
                        totalRecords={drivers.length}
                        onPage={onPage}
                        onSort={onSort}
                        sortField={lazyState.sortField}
                        sortOrder={lazyState.sortOrder}
                        header={renderHeader()}
                        filters={filters}
                        globalFilterFields={["name", "truck", "trailer"]}
                        loading={isloading}
                        style={{ marginTop: 12 }}
                        selection={selectedDrivers}
                        onSelectionChange={onSelectionChange}
                        selectAll={selectAll}
                        onSelectAllChange={onSelectAllChange}
                      >
                        <Column
                          selectionMode="multiple"
                          headerStyle={{ width: "3rem" }}
                        />
                        <Column
                          field="name"
                          sortable
                          body={nameBodyTemplate}
                          header="Name"
                        />
                        <Column
                          field="truck"
                          sortable
                          body={truckBodyTemplate}
                          header="Truck"
                        />
                        <Column
                          field="trailer"
                          sortable
                          body={trailerBodyTemplate}
                          header="Trailer"
                        />
                      </DataTable>
                    </Form.Group>
                  </Row>
                  <hr></hr>
                  <Button
                    variant="primary"
                    type="submit"
                    className="shadow rounded-5"
                  >
                    <FontAwesomeIcon icon={faCircleCheck} /> Update Group
                  </Button>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </React.Fragment>
  );
};

export default UpdateDriversGroup;
