import React, { useState, useMemo, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import CardHeader from "../../../Components/CardHeader";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  toastError,
  toastSuccess,
} from "../../../Components/ToastNotification";
import FilterComponent from "../../../SearchBox";
import { getDriver } from "../../../services/users";
import { addDriverGroup } from "../../../services/DriverGroups";
import { useNavigate } from "react-router-dom";
import { getjwt } from "../../../services/authServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";

const groupValidator = (value, diverlist) => {
  let errors = {};
  if (!value["title"]) {
    errors.title = "Group Name is required";
  }
  if (diverlist && diverlist.length > 0) {
    errors.diverlist = "manager is required";
  }
  if (!value["drivers"]) {
    errors.drivers = "manager is required";
  }
  return errors;
};

const headerButtons = [
  { buttonTitle: "Back", buttonUrl: "../groups", icon: faArrowLeft },
];

const INITIAL_DATA = {
  title: "",
  drivers: "",
};

const AddDriversGroup = () => {
  const navgate = useNavigate();
  const [data, setData] = useState(INITIAL_DATA);
  const [formErrors, setFormErrors] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectValue, setSelectedValue] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isloading, setIsLoading] = useState(true);

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" },
      truck: { value: "", matchMode: "contains" },
      trailer: { value: "", matchMode: "contains" },
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  useEffect(() => {
    setIsLoading(true);

    getDriver().then((res) => {
      setIsLoading(false);

      const tempData = res.data.map((item) => ({
        ...item,
        name: item.user.first_name + " " + item.user.last_name,
        truck: item.truck?.truck_name ?? "",
        trailer: item.trailer?.trailer_name ?? "",
      }));

      setDrivers(tempData.filter((item) => item.user.status == 1));
      /*
      setDrivers(
        tempData.filter(
          (item) =>
            item.manager && item.user.status == 1 && item.manager.status == 1
        )
      );
      */
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
    let inputs = {
      ...data,
      [name]: value,
    };
    setFormErrors(groupValidator(inputs, selectValue));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectValue.length == 0) {
      toastError("One driver should be selected at least!");
      return;
    }

    const token = getjwt();
    data["drivers"] = selectValue.map((item) => item.id);
    data["token_data"] = JSON.parse(token);
    if (data.title !== "" && data.drivers !== "") {
      addDriverGroup(data)
        .then((res) => {
          toastSuccess("Successfully created Driver Group");
          navgate("../groups");
        })
        .catch((err) => toastError(err.response.drivers));
    } else {
      setFormErrors(groupValidator(data, selectValue));
    }
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.name}</span>
      </div>
    );
  };

  const truckBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.truck}</span>
      </div>
    );
  };
  const trailerBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.trailer}</span>
      </div>
    );
  };

  const onPage = (event) => {
    setlazyState(event);
  };

  const onSort = (event) => {
    setlazyState(event);
  };

  const onSelectionChange = (event) => {
    const value = event.value;

    setSelectedValue(value);
    setSelectAll(value.length === drivers.length);
  };

  const onSelectAllChange = (event) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedValue([...drivers]);
    } else {
      setSelectAll(false);
      setSelectedValue([]);
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          style={{ width: "50%" }}
        />
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="ADD GROUP" />

      <Card className="mb-3 border-0 shadow-sm rounded-5">
        <Card.Body>
          <div className="form-wrapper">
            <Form
              noValidate
              className="needs-validation"
              onSubmit={handleSubmit}
            >
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label htmlFor="text" className="fw-600">
                    Group Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Add a Group Name"
                    onChange={handleChange}
                    value={data.title}
                    className="border-2"
                    isInvalid={formErrors.title}
                  />
                  {formErrors.title && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.title}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="multiselect_field">
                  <Form.Label className="fw-600">Select Drivers</Form.Label>
                  <div>
                    {formErrors.diverlist && (
                      <Form.Control.Feedback type="invalid">
                        {formErrors.diverlist}
                        {console.log(formErrors)}
                      </Form.Control.Feedback>
                    )}
                  </div>

                  <DataTable
                    value={drivers}
                    dataKey="id"
                    paginator
                    removableSort
                    first={lazyState.first}
                    rows={10}
                    totalRecords={drivers.length}
                    onPage={onPage}
                    onSort={onSort}
                    sortField={lazyState.sortField}
                    sortOrder={lazyState.sortOrder}
                    header={renderHeader()}
                    filters={filters}
                    loading={isloading}
                    globalFilterFields={["name", "truck", "trailer"]}
                    style={{ marginTop: 12 }}
                    selection={selectValue}
                    onSelectionChange={onSelectionChange}
                    selectAll={selectAll}
                    onSelectAllChange={onSelectAllChange}
                  >
                    <Column
                      selectionMode="multiple"
                      headerStyle={{ width: "3rem" }}
                    />
                    <Column
                      field="name"
                      sortable
                      body={nameBodyTemplate}
                      header="Name"
                    />
                    <Column
                      field="truck"
                      sortable
                      body={truckBodyTemplate}
                      header="Truck"
                    />
                    <Column
                      field="trailer"
                      sortable
                      body={trailerBodyTemplate}
                      header="Trailer"
                    />
                  </DataTable>
                </Form.Group>
              </Row>
              <hr></hr>
              <Button
                variant="primary"
                type="submit"
                className="shadow rounded-5"
              >
                <FontAwesomeIcon icon={faCircleCheck} /> Create Group
              </Button>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddDriversGroup;
