import React, { useEffect, useState } from "react";
import { Navbar, NavDropdown } from "react-bootstrap";
import undraw_profile from "../../../assets/images/undraw_profile.svg";
import NavTopItems from "./NavTopItems";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./navtop.css";
import { NavBarContext } from "../../context/NavBarContext";
import { useContext } from "react";
import {
  getCurrentUser,
  getUserDetails,
  removeToken,
} from "../../services/authServices";
import { Notification } from "./notification";

const NavbarTop = () => {
  const [tablist, setTab] = useContext(NavBarContext);
  const [user, setUser] = useState([]);
  const loc = useLocation();

  const navigate = useNavigate();

  const handleUrlpath = (title, url) => {
    if (url !== loc.pathname) {
      localStorage.setItem("url", loc.pathname);
    }
  };

  const handleClose = (id) => {
    const tabIndex = tablist.findIndex((tab) => {
      return tab.id === id;
    });
    const tabs = tablist.filter((item) => item.id !== id);
    setTab(tabs);
    let tabarr = [];
    const tabstored = localStorage.getItem("tabs");
    if (tabstored !== null) tabarr = JSON.parse(tabstored);
    for (let i = 0; i < tabarr.length; i++) {
      localStorage.setItem("tabs", JSON.stringify(tabs));
    }
    localStorage.removeItem("url");
    const prevTab = tabs[tabIndex - 1] || tabs[tabIndex + 1] || {};
    navigate(prevTab.url);
  };

  const logoutApp = () => {
    removeToken();
    localStorage.removeItem("url");
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("tabs"));
    if (data !== null) setTab(data);
    const userId = getCurrentUser();
    getUserDetails(userId["user_id"]).then((res) => setUser(res.data));
  }, [setTab]);

  return (
    <>
      <Navbar
        expand="lg"
        bg="white"
        className="topbar gap-3 px-6 mb-4 static-top "
      >
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <div className="nav-bar">
            {tablist
              ? tablist.map((nav, index) => {
                  return (
                    <NavTopItems
                      key={index}
                      index={index}
                      nav={nav}
                      onClose={handleClose}
                      TabNavs={tablist}
                      onUrlPath={handleUrlpath}
                    />
                  );
                })
              : ""}
          </div>
          <Notification />
          <NavDropdown.Divider className="topbar-divider" />
          <NavDropdown
            id="basic-nav-dropdown"
            title={
              <div className="nav-link ">
                <div className="m-3">
                  <span className="mr-3 align-bottom text-gray-600 small">
                    {`${user?.first_name ?? ""} ${user?.last_name ?? ""}` ||
                      "user"}
                  </span>
                </div>
                <img
                  className="img-profile rounded-circle"
                  src={user.image || undraw_profile}
                  alt="user pic"
                ></img>
              </div>
            }
          >
            <NavDropdown.Item className="dropdown-item">
              <Link to={`/dashboard/users/detail/${user.id}`}>
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-800"></i>
                Profile
              </Link>
            </NavDropdown.Item>

            <NavDropdown.Item
              onClick={logoutApp}
              className="dropdown-item"
              href="/"
            >
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              logout
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
      {/* )} */}
    </>
  );
};

export default NavbarTop;
