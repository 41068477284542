import "./App.css";
import { BrowserRouter, useRoutes, useLocation } from "react-router-dom";
import MainRoutes from "./pages/Router/MainRoutes";
import { useEffect, useState } from "react";
import { onMessage } from "@firebase/messaging";
import { setupNotifications, messaging } from "./FCM/firebase";
import useVisibilityChange from "./FCM/useVisibilityChange";
import { toastNotification } from "./pages/Components/ToastNotification";
import { removeToken } from "./pages/services/authServices";

function App() {
  const [isMounted, setMounted] = useState(false);

  const isForeground = useVisibilityChange();

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    if (window.Location.pathname == "/") {
      removeToken();
    }

    setupNotifications();
  }, [isMounted]);

  return (
    <div className="App">
      <BrowserRouter>
        <MainRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
