import { APIURL } from "../../constants/constants";
import http from "./ServerApi";

export const getloadsCount = async () => {
  return await http.get(APIURL + "/dashboard/load/");
};
export const getAllUserCount = async () => {
  return await http.get(APIURL + "/dashboard/user_count/");
};
export const getTicketsCount = async () => {
  return await http.get(APIURL + "/dashboard/ticket_count/");
};
export const getAssignedDriver = async () => {
  return await http.get(APIURL + "/dashboard/driver_count/");
};
export const getLoadedTruck = async () => {
  return await http.get(APIURL + "/dashboard/loaded_truck/");
};
export const getUnloadedTruck = async () => {
  return await http.get(APIURL + "/dashboard/unloaded_truck/");
};
export const getLatestLoads = async () => {
  return await http.get(APIURL + "/dashboard/recent_load/");
};
export const getDriverLoads = async (id) => {
  return await http.get(APIURL + `/drivers/${id}/loads/`);
};
export const getDriverTickets = async (id) => {
  return await http.get(APIURL + `/drivers/${id}/tickets/`);
};
