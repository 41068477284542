import { faEye, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { toastSuccess, toastError } from "../Components/ToastNotification";
import { userContext } from "../context/UserContext";
import { Role } from "./../../constants/role";

const deleteHandle = (id, delfun, getDataFun) => {
  confirmAlert({
    title: "Confirm to delete",
    message: "Are you sure to do this.",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          delfun(id)
            .then((res) => {
              toastSuccess("Successfully Deleted");
              getDataFun();
            })
            .catch((err) => toastError(`Failed to delete ${err.message}`));
        },
      },
      {
        label: "No",
      },
    ],
  });
};

const TableActionButton = ({
  detailPath,
  UpdatePath,
  deleteId,
  deleteFun,
  refreshdata,
  rowType = "",
  isEditable = true,
}) => {
  const { role } = useContext(userContext);

  if (
    role &&
    (role.toLowerCase() === Role.Admin || role.toLowerCase() === Role.Super)
  ) {
    return (
      <div className="d-flex justify-conent-evenly p-3">
        <Link className="btn btn-primary mr-3 br-50 shadow" to={detailPath}>
          <FontAwesomeIcon icon={faEye} />
        </Link>
        {isEditable && (
          <Link className="btn btn-info mr-3 br-50 shadow" to={UpdatePath}>
            <FontAwesomeIcon icon={faPen} />
          </Link>
        )}
        {rowType.toLowerCase() != Role.Admin && (
          <button
            className="btn btn-primary mr-3 br-50 shadow"
            onClick={() => deleteHandle(deleteId, deleteFun, refreshdata)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        )}
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-conent-evenly p-3">
        <Link className="btn btn-primary mr-3 br-50 shadow" to={detailPath}>
          <FontAwesomeIcon icon={faEye} />
        </Link>
        {isEditable && (
          <Link className="btn btn-info mr-3 br-50 shadow" to={UpdatePath}>
            <FontAwesomeIcon icon={faPen} />
          </Link>
        )}
      </div>
    );
  }
};

export default TableActionButton;
