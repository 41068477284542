import { emailRegex, phoneRegex } from "../../utils";

const driverValidator = (value) => {
  let errors = {};

  if (!value["first_name"]) {
    errors.first_name = "first name is required";
  }
  if (!value["last_name"]) {
    errors.last_name = "last name is required";
  }

  if (!value["phone_number"]) {
    errors.phone_number = "phone Number is required";
  } else if (!phoneRegex.test(value["phone_number"])) {
    errors.phone_number = "Add Country Code like it +41";
  } else if (value["phone_number"].length > 13) {
    errors.phone_number = "No more then 13 digit";
  }

  if (!value["email"]) {
    errors.email = "Enter a Vaild Email Id";
  } else if (!emailRegex.test(value["email"])) {
    errors.email = "Invalid email address";
  } else {
    errors.email = "";
  }

  if (!value["password"]) {
    errors.password = "please provide Password";
  } else if (value["password"].length < 8) {
    errors.password = "Password must be longer than 8 character";
  } else {
    errors.password = "";
  }
  if (!value["confirm_password"]) {
    errors.confirm_password = "please provide ConfirmPassword";
  } else if (value["confirm_password"] !== value["password"]) {
    errors.confirm_password = "Password does not match confirm password";
  } else {
    errors.confirm_password = "";
  }

  if (!value["status"]) {
    errors.status = "status is required";
  }
  if (!value["user_type"]) {
    errors.user_type = "user type is required";
  }

  if (!value["address"]) {
    errors.address = "Address is required";
  }
  if (!value["private_number"]) {
    errors.private_number = "Private number is required";
  }
  if (!value["cnp"]) {
    errors.cnp = "CNP number is required";
  }
  if (!value["truck"]) {
    errors.truck = "truck is required";
  }
  if (!value["trailer"]) {
    errors.trailer = "trailer is required";
  }
  if (!value["manager"]) {
    errors.manager = "manager is required";
  }

  return errors;
};
export default driverValidator;
