import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import "./forms.css";
import { Col, Image } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import CardHeader from "../../Components/CardHeader";
import { toastSuccess } from "../../Components/ToastNotification";
import { getByIdTrucks, getTrucks } from "../../services/trucks";
import {
  UpdateDriver,
  getDriverDetails,
  getManager,
} from "../../services/users";
import { getTrailers, getTrailersByid } from "../../services/trailer";
import { useEffect } from "react";
import { getjwt } from "../../services/authServices";
import Skeleton from "react-loading-skeleton";
import undraw_profile from "../../../assets/images/undraw_profile.svg";
import Select from "react-select";
import driverValidator from "../../Components/validators/DriverValidator";
import { emailRegex, phoneRegex } from "../../utils";

const headerButtons = [
  { buttonTitle: "Back", buttonUrl: "../driver", icon: faArrowLeft },
];

const DriverUpdateForm = () => {
  const { id } = useParams();
  const navgate = useNavigate();
  const [data, setData] = useState([]);
  const [user, setUser] = useState();
  const [image, setImage] = useState("");
  const [truck, setTruck] = useState([]);
  const [truckDetials, setTruckDetials] = useState();
  const [selectedTruck, setSelectedTruck] = useState();
  const [trailer, setTrailer] = useState([]);
  const [trailerDetials, setTrailerDetials] = useState();
  const [selectedTrailer, setSelectedTrailer] = useState();
  const [manager, setManager] = useState([]);
  const [selectedManager, setSelectedManager] = useState();
  const [formErrors, setFormErrors] = useState([]);
  const [isloading, setLoading] = useState(true);

  const truckData = data.truck && data.truck;
  const trailerData = data.trailer && data.trailer;

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    let inputs = {
      ...data,
      [name]: value,
    };
    setUser({ ...user, [name]: value });
    setData({ ...data, [name]: value });
    setFormErrors(driverValidator(inputs));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let inputs = {
      ...data,
      [name]: value,
    };
    setData({ ...data, [name]: value });
    setFormErrors(driverValidator(inputs));
  };

  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };

  const driversTruck = () => {
    getTrucks().then((res) => setTruck(res.data));
  };
  const getMangerUser = () => {
    getManager().then((res) =>
      setManager(res.data.filter((item) => item.status == 1))
    );
  };
  const driversTrailer = () => {
    getTrailers().then((res) => setTrailer(res.data));
  };

  useEffect(() => {
    setTimeout(() => {
      driversTruck();
      driversTrailer();
      getMangerUser();
      getDriverDetails(id).then((res) => {
        setData({
          ...res.data,
          first_name: res.data.user.first_name,
          last_name: res.data.user.last_name,
          email: res.data.user.email,
          phone_number: res.data.user.phone_number,
          status: res.data.user.status,
        });
        res.data.manager && setSelectedManager(res.data.manager);
        res.data.trailer && setSelectedTrailer(res.data.trailer);
        res.data.truck && setSelectedTruck(res.data.truck);
        setUser(res.data.user);
        setLoading(false);
      });
    }, 1000);
  }, [id]);

  useEffect(() => {
    if (selectedTruck !== undefined) {
      getByIdTrucks(selectedTruck && selectedTruck.id).then((res) =>
        setTruckDetials(res.data)
      );
    }
    if (selectedTrailer !== undefined) {
      getTrailersByid(selectedTrailer && selectedTrailer.id).then((res) =>
        setTrailerDetials(res.data)
      );
    }
  }, [selectedTruck, selectedTrailer]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt();
    let form_data;
    if (selectedManager !== undefined) {
      data["manager"] = selectedManager.id;
    }
    if (selectedTruck !== undefined) {
      data["truck"] = selectedTruck.id;
    }
    if (selectedTrailer !== undefined) {
      data["trailer"] = selectedTrailer.id;
    }
    if (
      data.first_name !== "" &&
      data.last_name !== "" &&
      data.status !== "" &&
      data.private_number !== "" &&
      data.cnp !== "" &&
      data.address !== "" &&
      data.truck !== "" &&
      data.trailer !== "" &&
      data.manager !== "" &&
      emailRegex.test(data.email) &&
      phoneRegex.test(data.phone_number)
    ) {
      console.log("data", data);
      form_data = new FormData();
      user["token_data"] = JSON.parse(token);
      if (data.truck !== null) {
        form_data.append("truck", data.truck.id || data.truck);
      }
      if (data.trailer !== null) {
        form_data.append("trailer", data.trailer.id || data.trailer);
      }
      if (image) form_data.append("image", image);
      form_data.append("user", JSON.stringify(user));
      form_data.append("address", data.address);
      if (data.manager !== null) {
        form_data.append("manager", data.manager.id || data.manager);
      }
      form_data.append("private_number", data.private_number);
      form_data.append("cnp", data.cnp);
      UpdateDriver(id, form_data)
        .then((res) => {
          toastSuccess("succesfully updated Drivers");
          navgate("../driver");
        })
        .catch((err) => console.log(err));
    } else {
      setFormErrors(driverValidator(data));
    }
  };

  return (
    <React.Fragment>
      {isloading ? (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="Update Driver" />

          <Form noValidate className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col lg={6} sm={6} xs={6} md={6}>
                <Card className="mb-3 border-0 shadow-sm rounded-2">
                  <Card.Body>
                    <div className="form-wrapper">
                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Skeleton width={75} height={24} />
                          <Skeleton width={650} height={24} />
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Skeleton width={75} height={24} />
                          <Skeleton width={650} height={24} />
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Skeleton width={75} height={24} />
                          <Skeleton width={309} height={24} />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Skeleton width={75} height={24} />
                          <Skeleton width={309} height={24} />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Skeleton width={75} height={24} />
                          <Skeleton width={650} height={24} />
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Skeleton width={75} height={24} />
                          <Skeleton width={616} height={85} />
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Skeleton width={75} height={24} />
                          <Skeleton width={650} height={24} />
                        </Form.Group>
                      </Row>
                    </div>
                    <div className="form-wrapper">
                      <Row className="mb-3">
                        <Form.Group>
                          <Skeleton width={75} height={24} />
                          <Skeleton width={650} height={24} />
                        </Form.Group>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6} sm={6} xs={6} md={6}>
                <Card className="mb-3 border-0 shadow-sm rounded-2">
                  <Card.Body>
                    <div className="form-wrapper">
                      <Row className="mb-3">
                        <Form.Group>
                          <Skeleton width={75} height={24} />
                          <Skeleton width={650} height={24} />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group>
                          <Skeleton width={640} height={197} />
                        </Form.Group>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>

                <Card className="mb-3 border-0 shadow-sm rounded-2">
                  <Card.Body>
                    <div className="form-wrapper">
                      <Row className="mb-3">
                        <Form.Group>
                          <Skeleton width={75} height={24} />
                          <Skeleton width={650} height={24} />
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group>
                          <Skeleton width={640} height={197} />
                        </Form.Group>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="mb-3 border-0 shadow-sm rounded-2 p-0 m-0 ">
                  <Card.Body className="text-start">
                    <Skeleton width={145} height={24} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <div className="container-fluid">
          <CardHeader headerItems={headerButtons} title="Update Driver" />

          <Form noValidate className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col lg={6} sm={6} xs={6} md={6}>
                <Card className="mb-3 border-0 shadow-sm rounded-2">
                  <Card.Body>
                    <div className="form-wrapper">
                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Form.Label className="fw-600">Last name</Form.Label>
                          <Form.Control
                            type="text"
                            name="last_name"
                            placeholder="Enter Your Last name"
                            onChange={handleUserChange}
                            value={data.last_name}
                            isInvalid={formErrors.last_name}
                          />
                          {formErrors.last_name && (
                            <Form.Control.Feedback type="invalid">
                              {formErrors.last_name}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Form.Label className="fw-600">First name</Form.Label>
                          <Form.Control
                            type="text"
                            name="first_name"
                            placeholder="Enter Your First name"
                            onChange={handleUserChange}
                            value={data.first_name}
                            isInvalid={formErrors.first_name}
                          />
                          {formErrors.first_name && (
                            <Form.Control.Feedback type="invalid">
                              {formErrors.first_name}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Form.Label className="fw-600">Email</Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="Enter the Email Address"
                            onChange={handleUserChange}
                            value={data.email}
                            isInvalid={formErrors.email}
                          />
                          {formErrors.email && (
                            <Form.Control.Feedback type="invalid">
                              {formErrors.email}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label className="fw-600">Phone No:</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone_number"
                            placeholder="Enter the mobile Number"
                            onChange={handleUserChange}
                            value={data.phone_number}
                            isInvalid={formErrors.phone_number}
                          />
                          {formErrors.phone_number && (
                            <Form.Control.Feedback type="invalid">
                              {formErrors.phone_number}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Form.Label className="fw-600"> Status</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="status"
                            onChange={handleUserChange}
                            value={data.status}
                            isInvalid={formErrors.status}
                          >
                            <option value="">Select the status</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </Form.Select>
                          {formErrors.status && (
                            <Form.Control.Feedback type="invalid">
                              {formErrors.status}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Form.Label className="fw-600">
                            Private Number
                          </Form.Label>
                          <Form.Control
                            name="private_number"
                            placeholder="Enter the Private Number"
                            onChange={handleChange}
                            value={data.private_number}
                            isInvalid={formErrors.private_number}
                          />
                          {formErrors.private_number && (
                            <Form.Control.Feedback type="invalid">
                              {formErrors.private_number}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label className="fw-600">CNP Number</Form.Label>
                          <Form.Control
                            name="cnp"
                            placeholder="Enter the Private Number"
                            onChange={handleChange}
                            value={data.cnp}
                            isInvalid={formErrors.cnp}
                          />
                          {formErrors.cnp && (
                            <Form.Control.Feedback type="invalid">
                              {formErrors.cnp}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Form.Label className="fw-600">Address</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="address"
                            placeholder="Enter the Address"
                            onChange={handleChange}
                            value={data.address}
                            style={{ height: "100px" }}
                            isInvalid={formErrors.address}
                          />
                          {formErrors.address && (
                            <Form.Control.Feedback type="invalid">
                              {formErrors.address}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Form.Label className="fw-600">Image</Form.Label>
                          <Form.Control
                            type="file"
                            placeholder="Choose your image"
                            name="image"
                            accept="image/jpeg,image/png,image/gif"
                            onChange={handleImage}
                          />
                        </Form.Group>
                      </Row>
                      <Image
                        className="form_image"
                        alt="No Preview"
                        src={data.image || undraw_profile}
                        width={100}
                        height={100}
                        rounded
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6} sm={6} xs={6} md={6}>
                <Card className="mb-3 border-0 shadow-sm rounded-2">
                  <Card.Body>
                    <div className="form-wrapper">
                      <Row className="mb-3">
                        <Form.Group>
                          <Form.Label className="fw-600 mb-4">
                            Truck info
                          </Form.Label>
                          <Select
                            options={truck}
                            isOptionDisabled={(option) =>
                              option.status.toLowerCase() !== "available"
                            }
                            getOptionLabel={(option) => `${option.truck_name}`}
                            isOptionSelected={(option) =>
                              selectedTruck === option.id ? true : false
                            }
                            onChange={(value) => {
                              let inputs = {
                                ...data,
                                truck: value,
                              };

                              setData({ ...data, truck: value });
                              setFormErrors(driverValidator(inputs));
                              setSelectedTruck(value);
                            }}
                            placeholder="Choose Truck"
                            defaultValue={data.truck}
                          />
                          {formErrors.truck && (
                            <span className="text-danger">
                              {formErrors.truck}
                            </span>
                          )}
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group>
                          {truckDetials ? (
                            <Table striped bordered hover>
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Truck </b>
                                  </td>
                                  <td>
                                    <b>
                                      {truckDetials && truckDetials.truck_name}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Plate Number</b>
                                  </td>
                                  <td>
                                    <b>
                                      {truckDetials &&
                                        truckDetials.plate_number}
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          ) : (
                            ""
                          )}

                          {truckData !== undefined ? (
                            <>
                              <h5 className="textEsist">Existing Data</h5>
                              <Table striped bordered hover>
                                <tbody>
                                  <tr>
                                    <td>
                                      <b>Truck </b>
                                    </td>
                                    <td>
                                      <p>{truckData && truckData.truck_name}</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Plate Number</b>
                                    </td>
                                    <td>
                                      <p>
                                        {truckData && truckData.plate_number}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>

                <Card className="mb-3 border-0 shadow-sm rounded-2">
                  <Card.Body>
                    <div className="form-wrapper">
                      <Row className="mb-3">
                        <Form.Group>
                          <Form.Label className="fw-600 mb-4">
                            Trailer info
                          </Form.Label>
                          <Select
                            options={trailer}
                            isOptionDisabled={(option) =>
                              option.status.toLowerCase() !== "a"
                            }
                            getOptionLabel={(option) =>
                              `${option.trailer_name}`
                            }
                            isOptionSelected={(option) =>
                              selectedTrailer === option.id ? true : false
                            }
                            onChange={(value) => {
                              let inputs = {
                                ...data,
                                trailer: value,
                              };

                              setData({ ...data, trailer: value });
                              setFormErrors(driverValidator(inputs));

                              setSelectedTrailer(value);
                            }}
                            placeholder="Choose trailer"
                            defaultValue={data.trailer}
                          />
                          {formErrors.trailer && (
                            <span className="text-danger">
                              {formErrors.trailer}
                            </span>
                          )}
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group>
                          {trailerDetials ? (
                            <Table striped bordered hover>
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Trailer</b>
                                  </td>
                                  <td>
                                    <b>
                                      {trailerDetials &&
                                        trailerDetials.trailer_name}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Plate Number</b>
                                  </td>
                                  <td>
                                    <b>
                                      {trailerDetials &&
                                        trailerDetials.plate_number}
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          ) : (
                            ""
                          )}
                          {trailerData !== undefined ? (
                            <>
                              <h5 className="textEsist">Existing Data</h5>
                              <Table striped bordered hover>
                                <tbody>
                                  <tr>
                                    <td>
                                      <b>trailer </b>
                                    </td>
                                    <td>
                                      <p>
                                        {trailerData &&
                                          trailerData.trailer_name}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Plate Number</b>
                                    </td>
                                    <td>
                                      <p>
                                        {trailerData &&
                                          trailerData.plate_number}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      </Row>
                      <Row className="mb-5">
                        <Form.Group>
                          <Form.Label className="fw-600 mb-6">
                            Manager
                          </Form.Label>
                          <Select
                            options={manager}
                            getOptionLabel={(option) =>
                              `${option.last_name} ${option.first_name}`
                            }
                            isOptionSelected={(option) =>
                              selectedManager === option.id ? true : false
                            }
                            onChange={(value) => {
                              let inputs = {
                                ...data,
                                manager: value,
                              };

                              setData({ ...data, manager: value });
                              setFormErrors(driverValidator(inputs));

                              setSelectedManager(value);
                            }}
                            defaultValue={
                              data.manager && data.manager.status == 1
                                ? data.manager
                                : null
                            }
                            placeholde="Choose Manager"
                          />
                          {formErrors.manager && (
                            <span className="text-danger">
                              {formErrors.manager}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="mb-3 border-0 shadow-sm rounded-2 p-0 m-0 ">
                  <Card.Body className="text-start">
                    <Button
                      variant="primary"
                      type="submit"
                      className="rounded-5 shadow-sm w-32"
                    >
                      <FontAwesomeIcon icon={faCircleCheck} /> Update Driver
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </React.Fragment>
  );
};

export default DriverUpdateForm;
