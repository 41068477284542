import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button, Card, Modal, Image, Row, Col } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { UpdateDriverManger, getManager } from "../services/users";
import { toastSuccess } from "./ToastNotification";
import { useNavigate } from "react-router-dom";
import undraw_profile from "../../../src/assets/images/undraw_profile.svg";

const DriverMangerModal = ({ driverid, showMod, onClose, onCloseCallback }) => {
  const navgate = useNavigate();
  const [data, setData] = useState([]);
  const [inputtext, setInputText] = useState("");

  useEffect(() => {
    getManager().then((res) =>
      setData(res.data.filter((item) => item.status == 1))
    );
  }, []);

  const filteredData = data.filter((data) => {
    return (
      data.first_name.toLowerCase().includes(inputtext.toLowerCase()) ||
      data.last_name.toLowerCase().includes(inputtext.toLowerCase())
    );
  });

  const handleSearch = (e) => {
    setInputText(e.target.value);
  };

  const handleClose = (showMod) => {
    onClose(!showMod);
    navgate("./");
  };

  const handleSubmit = (id) => {
    const updateData = {
      manager: id,
    };
    driverid.map((item) =>
      UpdateDriverManger(item, updateData).then((res) => {
        toastSuccess("manager updated sucessfully");
        setTimeout(() => {
          onCloseCallback();
          onClose(!showMod);
        }, 600);
      })
    );
  };

  return (
    <Modal className="modal-xl" show={showMod} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Assign Manger </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ width: "100wh" }}>
        <div className="assign_search">
          <div className="searchbar">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              placeholder="Search..."
              value={inputtext}
              onChange={handleSearch}
            />
          </div>
        </div>
        <Row>
          {filteredData.map((item) => (
            <Col key={item.id}>
              <Card className="border-0 mb-3 p-5 shadow-sm ">
                <Card.Body>
                  <Card.Img
                    style={{
                      width: "70px",
                      borderRadius: "50%",
                      height: "70px",
                      borderColor: "#dadada",
                      borderStyle: "solid",
                      objectFit: "cover",
                      borderWidth: 2,
                    }}
                    src={item.image || undraw_profile}
                    alt="Generic placeholder image"
                    as={Image}
                    fluid
                  />
                  <Card.Text className="mt-3 mb-2">
                    <strong>{item.first_name + " " + item.last_name}</strong>
                  </Card.Text>
                  <Card.Text>
                    <Button
                      className="btn-sm"
                      onClick={() => handleSubmit(item.id)}
                    >
                      Assign
                    </Button>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleClose} variant="secondary">
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DriverMangerModal;
