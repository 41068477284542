import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../SearchBox";
import "./table.css";

const customStyles = {
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      // backgroundColor: "rgb(245, 245, 245) !important",
      width: "200px",
    },
  },
  subHeader: {
    style: {
      marginLeft: "11px",
      marginRight: "11px",
      padding: "5px",
    },
  },
};

const Table = ({
  columns,
  row,
  assignDriver = false,
  conditionalRowStyles,
  processDataStatus,
}) => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = row.filter((item) =>
    filterText
      .trim()
      .split(/\s+/) // Split search text into words
      .every((word) =>
        JSON.stringify(item)
          .toLowerCase()
          .replace(/\s+/g, " ") // Replace multiple spaces with a single space
          .includes(word.toLowerCase())
      )
  );
  
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return assignDriver ? (
      <>
        <Button variant="primary" href="assignDriver">
          assignDriver
        </Button>
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </>
    ) : (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle, assignDriver]);

  return (
    <DataTable
      columns={columns}
      data={filteredItems}
      pagination
      paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
      subHeader
      highlightOnHover
      selectableRowsHighlight
      subHeaderComponent={subHeaderComponent}
      customStyles={customStyles}
      conditionalRowStyles={conditionalRowStyles}
      progressPending={processDataStatus}
    />
  );
};

export default Table;
