const LoadsValidator = (value) => {
  let errors = {};
  if (value["loading_location"].trim().length <= 0) {
    errors.loading_location = "Loading Location is required";
  }
  if (value["loading_country"].trim().length <= 0) {
    errors.loading_country = "Loading Country is required";
  }
  if (value["loading_country_code"].trim().length <= 0) {
    errors.loading_country_code = "Loading Country  Code is required";
  }
  if (value["loading_reference"].trim().length <= 0) {
    errors.loading_reference = "Loading Reference is required";
  }
  if (value["unloading_location"].trim().length <= 0) {
    errors.unloading_location = "Unloading Location is required";
  }
  if (value["unloading_country"].trim().length <= 0) {
    errors.unloading_country = "Unloading Country is required";
  }
  if (value["unloading_country_code"].trim().length <= 0) {
    errors.unloading_country_code = "Unloading Country Code is required";
  }
  if (value["unloading_reference"].trim().length <= 0) {
    errors.unloading_reference = "Unloading Reference is required";
  }
  if (!value["driver"]) {
    errors.driver = "Driver is required";
  }
  if (value["delivery_notes"].trim().length <= 0) {
    errors.delivery_notes = "Delivery Notes is required";
  }
  if (value["goods_type"].trim().length <= 0) {
    errors.goods_type = "Goods Type is required";
  }
  if (value["identification_position"].trim().length <= 0) {
    errors.identification_position = "Identification Position is required";
  }
  if (!value["loading_status"]) {
    errors.loading_status = "Loading Status is required";
  }
  if (value["indicative"].trim().length <= 0) {
    errors.indicative = "indicative number is required";
  }
  return errors;
};
export default LoadsValidator;
