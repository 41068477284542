import { dateRegex } from "../../utils";

const secondUnloadingLoationValidator = (value) => {
  let errors = {};

  if (!value["second_unloading_location"]) {
    errors.second_unloading_location = "Unloading location is required";
  }

  if (!value["second_unloading_date"]) {
    errors.second_unloading_date = "Unloading date is required";
  } else if (!dateRegex.test(value["second_unloading_date"])) {
    errors.second_unloading_date = "Invalid date format";
  }

  if (!value["second_unloading_country"]) {
    errors.second_unloading_country = "Unloading country is required";
  }

  if (!value["second_unloading_country_code"]) {
    errors.second_unloading_country_code = "Unloading country code is required";
  }

  if (!value["second_unloading_reference"]) {
    errors.second_unloading_reference = "Unloading reference is required";
  }

  return errors;
};
export default secondUnloadingLoationValidator;
