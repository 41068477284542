import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import "./forms.css";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { toastError, toastSuccess } from "../../Components/ToastNotification";
import CardHeader from "../../Components/CardHeader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";

import { getByIdTrucks, getTrucks } from "./../../services/trucks";
import { useEffect } from "react";
import { getTrailers, getTrailersByid } from "./../../services/trailer";
import { createDriver, getManager } from "../../services/users";

import { useNavigate } from "react-router-dom";
import { getjwt } from "../../services/authServices";
import { getUserType } from "../../services/usertypeApi";
import driverValidator from "../../Components/validators/DriverValidator";
import Select from "react-select";
import { emailRegex, phoneRegex } from "../../utils";

const headerButtons = [
  { buttonTitle: "Back", buttonUrl: "../driver", icon: faArrowLeft },
];

const INITIAL_DATA = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  password: "",
  confirm_password: "",
  status: 0,
  user_type: "",
  address: "",
  private_number: "",
  cnp: "",
  truck: "",
  trailer: "",
  manager: "",
};

const DriverForm = () => {
  const navgate = useNavigate("");
  const [data, setData] = useState(INITIAL_DATA);
  const [usertypeList, setUserTypeList] = useState([]);
  const [image, setImage] = useState("");
  const [truck, setTruck] = useState([]);
  const [selectedTruck, setSelectedTruck] = useState();
  const [truckDetials, setTruckDetials] = useState();
  const [trailer, setTrailer] = useState([]);
  const [selectedTrailer, setSelectedTrailer] = useState();
  const [trailerDetials, setTrailerDetials] = useState();
  const [manager, setManager] = useState([]);
  const [selectedManager, setSelectedManager] = useState();
  const [formErrors, setFormErrors] = useState([]);
  const [userData, setUserData] = useState([]);

  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let inputs = {
      ...data,
      [name]: value,
    };

    setData({ ...data, [name]: value });
    setFormErrors(driverValidator(inputs));
  };

  const driversTruck = () => {
    getTrucks().then((res) => setTruck(res.data));
  };
  const getMangerUser = () => {
    getManager().then((res) => {
      console.log(res.data);
      setManager(res.data.filter((item) => item.status == 1));
    });
  };
  const driversTrailer = () => {
    getTrailers().then((res) => setTrailer(res.data));
  };

  useEffect(() => {
    driversTruck();
    driversTrailer();
    getMangerUser();
    getUserType().then((res) => setUserTypeList(res.data));
  }, []);

  useEffect(() => {
    if (selectedTruck !== undefined) {
      getByIdTrucks(selectedTruck && selectedTruck.id).then((res) =>
        setTruckDetials(res.data)
      );
    }
    if (selectedTrailer !== undefined) {
      getTrailersByid(selectedTrailer && selectedTrailer.id).then((res) =>
        setTrailerDetials(res.data)
      );
    }
  }, [selectedTruck, selectedTrailer]);

  useEffect(() => {
    const filteredUserData = usertypeList.filter(
      (item) => item.user_type === "Sofer"
    );
    setUserData([...filteredUserData]);
  }, [usertypeList]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt();
    let user = {};
    let form_data;
    data["user_type"] = userData[0] && userData[0].id;
    if (selectedTruck !== undefined) {
      data["truck"] = selectedTruck.id;
    }
    if (selectedTrailer !== undefined) {
      data["trailer"] = selectedTrailer.id;
    }
    if (selectedManager !== undefined) {
      data["manager"] = selectedManager.id;
    }
    if (
      data.first_name !== "" &&
      data.last_name !== "" &&
      data.password !== "" &&
      data.confirm_password !== "" &&
      data.user_type !== "" &&
      data.status !== "" &&
      data.private_number !== "" &&
      data.cnp !== "" &&
      data.address !== "" &&
      data.truck !== "" &&
      data.trailer !== "" &&
      data.manager !== "" &&
      emailRegex.test(data.email) &&
      phoneRegex.test(data.phone_number)
    ) {
      form_data = new FormData();

      user = {
        first_name: data.first_name,
        last_name: data.last_name,
        phone_number: data.phone_number,
        email: data.email,
        password: data.password,
        confirm_password: data.confirm_password,
        status: data.status,
        user_type: data.user_type,
        token_data: JSON.parse(token),
      };
      if (image) form_data.append("image", image);
      form_data.append("user", JSON.stringify(user));
      form_data.append("address", data.address);
      form_data.append("truck", data.truck);
      form_data.append("trailer", data.trailer);
      form_data.append("manager", data.manager);
      form_data.append("private_number", data.private_number);
      form_data.append("cnp", data.cnp);
      createDriver(form_data)
        .then((res) => {
          toastSuccess("succesfully created Drivers");
          navgate("../driver");
        })
        .catch((err) => {
          let errObj = err.response.data;

          if (errObj.email) {
            toastError(errObj.email);
          }
          if (errObj.phone_number) {
            toastError(errObj.phone_number);
          }
          if (errObj.password) {
            toastError(errObj.password);
          }
          toastError(errObj);
        });
    } else {
      setFormErrors(driverValidator(data));
    }
  };

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="Add Driver" />
      <Form noValidate className="needs-validation" onSubmit={handleSubmit}>
        <Row>
          <Col lg={6} sm={6} xs={6} md={6}>
            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Last name</Form.Label>
                      <Form.Control
                        type="text"
                        name="last_name"
                        placeholder="Enter Your Last name"
                        onChange={handleChange}
                        value={data.last_name}
                        isInvalid={formErrors.last_name}
                      />
                      {formErrors.last_name && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.last_name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">First name</Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        placeholder="Enter Your First name"
                        onChange={handleChange}
                        value={data.first_name}
                        isInvalid={formErrors.first_name}
                      />
                      {formErrors.first_name && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.first_name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Enter the Email Address"
                        onChange={handleChange}
                        value={data.email}
                        isInvalid={formErrors.email}
                      />
                      {formErrors.email && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.email}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Phone No:</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone_number"
                        placeholder="Enter the mobile Number"
                        onChange={handleChange}
                        value={data.phone_number}
                        isInvalid={formErrors.phone_number}
                      />
                      {formErrors.phone_number && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.phone_number}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label className="fw-600">Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleChange}
                        value={data.password}
                        placeholder="Enter a Password"
                        isInvalid={formErrors.password}
                      />
                      {formErrors.password && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.password}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridConfirmPassword">
                      <Form.Label className="fw-600">
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="confirm_password"
                        placeholder="Enter a Confirm Password"
                        onChange={handleChange}
                        value={data.confirm_password}
                        isInvalid={formErrors.confirm_password}
                      />
                      {formErrors.confirm_password && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.confirm_password}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Usertype</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="user_type"
                        onChange={handleChange}
                        value={data.user_type}
                        isInvalid={formErrors.user_type}
                      >
                        <option value="">Select the type</option>
                        {userData.map((item, idx) => (
                          <option key={idx} value={item.id}>
                            {item.user_type}
                          </option>
                        ))}
                      </Form.Select>
                      {formErrors.user_type && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.user_type}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600"> Status</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="status"
                        onChange={handleChange}
                        value={data.status}
                        isInvalid={formErrors.status}
                      >
                        <option value="">Select the status</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </Form.Select>
                      {formErrors.status && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.status}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Private Number</Form.Label>
                      <Form.Control
                        name="private_number"
                        placeholder="Enter the Private Number"
                        onChange={handleChange}
                        value={data.private_number}
                        isInvalid={formErrors.private_number}
                      />
                      {formErrors.private_number && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.private_number}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">CNP Number</Form.Label>
                      <Form.Control
                        name="cnp"
                        placeholder="Enter the Private Number"
                        onChange={handleChange}
                        value={data.cnp}
                        isInvalid={formErrors.cnp}
                      />
                      {formErrors.cnp && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.cnp}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="address"
                        placeholder="Enter the Address"
                        onChange={handleChange}
                        value={data.address}
                        style={{ height: "100px" }}
                        isInvalid={formErrors.address}
                      />
                      {formErrors.address && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.address}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Image</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="choose your file"
                        name="image"
                        accept="image/jpeg,image/png,image/gif"
                        onChange={handleImage}
                      />
                    </Form.Group>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} sm={6} xs={6} md={6}>
            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label className="fw-600 mb-4">
                        Truck info
                      </Form.Label>
                      <Select
                        options={truck}
                        isOptionDisabled={(option) =>
                          option.status.toLowerCase() !== "available"
                        }
                        getOptionLabel={(option) => `${option.truck_name}`}
                        isOptionSelected={(option) =>
                          selectedTruck === option.id ? true : false
                        }
                        onChange={(value) => {
                          let inputs = {
                            ...data,
                            truck: value,
                          };

                          setData({ ...data, truck: value });
                          setFormErrors(driverValidator(inputs));
                          setSelectedTruck(value);
                        }}
                        placeholder="Choose Truck"
                      />
                      {formErrors.truck && (
                        <span className="text-danger">{formErrors.truck}</span>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group>
                      <Table striped bordered hover>
                        <tbody>
                          <tr>
                            <td>
                              <b>Truck </b>
                            </td>
                            <td>
                              <b>{truckDetials && truckDetials.truck_name}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Plate Number </b>
                            </td>
                            <td>
                              <b>{truckDetials && truckDetials.plate_number}</b>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Form.Group>
                  </Row>
                </div>
              </Card.Body>
            </Card>

            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label className="fw-600 mb-4">
                        Trailer info
                      </Form.Label>
                      <Select
                        options={trailer}
                        isOptionDisabled={(option) =>
                          option.status.toLowerCase() !== "a"
                        }
                        getOptionLabel={(option) => `${option.trailer_name}`}
                        isOptionSelected={(option) =>
                          selectedTrailer === option.id ? true : false
                        }
                        onChange={(value) => {
                          let inputs = {
                            ...data,
                            trailer: value,
                          };

                          setData({ ...data, trailer: value });
                          setFormErrors(driverValidator(inputs));

                          setSelectedTrailer(value);
                        }}
                        placeholder="Choose Trailer"
                      />
                      {formErrors.trailer && (
                        <span className="text-danger">
                          {formErrors.trailer}
                        </span>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group>
                      <Table striped bordered hover>
                        <tbody>
                          <tr>
                            <td>
                              <b>Trailer</b>
                            </td>
                            <td>
                              <b>
                                {trailerDetials && trailerDetials.trailer_name}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Plate Number</b>
                            </td>
                            <td>
                              <b>
                                {trailerDetials && trailerDetials.plate_number}
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Form.Group>
                  </Row>
                </div>
              </Card.Body>
            </Card>

            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label className="fw-600 mb-4"> Manager </Form.Label>

                      <Select
                        options={manager}
                        getOptionLabel={(option) =>
                          `${option.last_name} ${option.first_name}`
                        }
                        isOptionSelected={(option) =>
                          selectedManager === option.id ? true : false
                        }
                        onChange={(value) => {
                          let inputs = {
                            ...data,
                            manager: value,
                          };

                          setData({ ...data, manager: value });
                          setFormErrors(driverValidator(inputs));

                          setSelectedManager(value);
                        }}
                        placeholder="Choose Manager"
                      />
                      {formErrors.manager && (
                        <span className="text-danger">
                          {formErrors.manager}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={12} sm={12} xs={12} md={12}>
            <Card className="mb-3 border-0 shadow-sm rounded-2 p-0 m-0 ">
              <Card.Body className="text-start">
                <Button
                  variant="primary"
                  type="submit"
                  className="rounded-5 shadow-sm w-25"
                >
                  <FontAwesomeIcon icon={faCircleCheck} /> Create Driver
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default DriverForm;
