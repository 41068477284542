import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect, useContext } from "react";
import "./users.css";
import Card from "react-bootstrap/Card";
import Table from "../Components/Tables";
import TableActionButton from "../Components/TableActionButton";
import CardHeader from "../Components/CardHeader";
import { getUsers, deleteUser } from "../services/users";
import { userContext } from "../context/UserContext";
import { Role } from "../../constants/role";

const headerButtons = [
  {
    buttonTitle: "Add User",
    buttonUrl: "/dashboard/add/users",
    icon: faUserPlus,
  },
];

const Users = () => {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const { role } = useContext(userContext);

  const userList = () => {
    getUsers().then((res) => {
      console.log(res.data, role);
      if (role == Role.Super) {
        setData(res.data.filter((item) => item.status != -1 && item.user_type));
      } else if (role == Role.Admin) {
        setData(
          res.data.filter(
            (item) =>
              item.status != -1 &&
              item.is_superuser == 0 &&
              item.user_type &&
              item.user_type.user_type.toLowerCase() != Role.Admin &&
              item.user_type.user_type.toLowerCase() != Role.Super
          )
        );
      } else {
        setData(
          res.data.filter(
            (item) =>
              item.status != -1 &&
              item.is_superuser == 0 &&
              item.user_type &&
              item.user_type.user_type.toLowerCase() != Role.Admin &&
              item.user_type.user_type.toLowerCase() != Role.Super &&
              item.user_type.user_type.toLowerCase() != Role.Manager
          )
        );
      }
      setPending(false);
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      userList();
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const columns = [
    {
      name: "serialNo",
      selector: (row) => data.indexOf(row) + 1,
      sortable: true,
    },
    {
      name: "name",
      selector: (row) => row.last_name + " " + row.first_name,
      sortable: true,
    },
    {
      name: "email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.phone_number,
      sortable: true,
    },
    {
      name: "usertype",
      selector: (row) => row.user_type.user_type,
      sortable: true,
    },
    {
      name: "status",
      selector: (row) => (row.status == 1 ? "Active" : "Inactive"),
    },
    {
      name: "",
      cell: (row) => (
        <TableActionButton
          detailPath={`/dashboard/users/detail/${row.id}`}
          UpdatePath={`/dashboard/users/update/${row.id}`}
          deleteId={row.id}
          deleteFun={deleteUser}
          refreshdata={userList}
          rowType={row.user_type?.user_type ?? ""}
        />
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="USERS" />

      <Card className="mb-3 border-0 shadow-sm p-2 rounded-2">
        <Card.Body>
          <div className="row">
            <Table columns={columns} row={data} processDataStatus={pending} />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Users;
