import React, { useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import "./forms.css";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { toastError, toastSuccess } from "../../Components/ToastNotification";
import { useNavigate } from "react-router-dom";
import CardHeader from "../../Components/CardHeader";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Validater from "../../Components/validators/RegisterValidater";
import { createUser } from "../../services/users";
import { useEffect } from "react";
import { getUserType } from "./../../services/usertypeApi";
import { getjwt } from "../../services/authServices";
import { userContext } from "../../context/UserContext";
import { Role } from "../../../constants/role";
import { phoneRegex } from "../../utils";

const headerButtons = [
  { buttonTitle: "Back to users", buttonUrl: "../users", icon: faArrowLeft },
];

const INITIAL_DATA = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  password: "",
  confirm_password: "",
  status: "",
  user_type: "",
};

const UserForm = () => {
  const navgate = useNavigate();
  const [data, setData] = useState(INITIAL_DATA);
  const [image, setImage] = useState("");
  const [userType, setUserType] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const { role } = useContext(userContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let inputs = {
      ...data,
      [name]: value,
    };
    setData({ ...data, [name]: value });
    setFormErrors(Validater(inputs));
  };

  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };

  useEffect(() => {
    console.log(role);
    getUserType().then((res) => {
      if (role == Role.Super) {
        setUserType(res.data);
      } else if (role == Role.Admin) {
        setUserType(
          res.data.filter((item) => item.user_type.toLowerCase() != Role.Admin)
        );
      } else {
        setUserType(
          res.data.filter(
            (item) =>
              item.user_type.toLowerCase() != Role.Admin &&
              item.user_type.toLowerCase() != Role.Manager
          )
        );
      }
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt();
    let form_data;
    if (
      data.first_name !== "" &&
      data.last_name !== "" &&
      data.phone_number !== "" &&
      data.email !== "" &&
      phoneRegex.test(data.phone_number) &&
      data.status !== "" &&
      data.user_type !== ""
    ) {
      form_data = new FormData();
      if (image) form_data.append("image", image);
      form_data.append("first_name", data.first_name);
      form_data.append("last_name", data.last_name);
      form_data.append("phone_number", data.phone_number);
      form_data.append("email", data.email);
      form_data.append("password", data.password);
      form_data.append("confirm_password", data.confirm_password);
      form_data.append("status", data.status);
      form_data.append("user_type", data.user_type);
      form_data.append("token_data", JSON.parse(token));
      createUser(form_data)
        .then((res) => {
          if (res.status === 201) {
            toastSuccess("Successfully created user");
            navgate("../users");
          }
        })
        .catch((res) => {
          let errObj = res.response.data;
          if (errObj.email) {
            errObj.email.map((err) => toastError(err));
          }
          if (errObj.phone_number) {
            errObj.phone_number.map((err) => toastError(err));
          }
        });
    } else {
      setFormErrors(Validater(data));
    }
  };

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="ADD USER" />

      <Card className="mb-3 border-0 shadow-sm rounded-5">
        <Card.Body>
          <div className="form-wrapper">
            <Form
              noValidate
              className="needs-validation"
              onSubmit={handleSubmit}
            >
              <Row className="mb-3">
                <Form.Group className="mb-3" as={Col}>
                  <Form.Label className="fw-600">Last name</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    placeholder="Enter Your Last name"
                    onChange={handleChange}
                    value={data.last_name}
                    isInvalid={formErrors.last_name}
                  />
                  {formErrors.last_name && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.last_name}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label htmlFor="text" className="fw-600">
                    First name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    placeholder="Enter Your first name"
                    onChange={handleChange}
                    value={data.first_name}
                    className="border-2"
                    isInvalid={formErrors.first_name}
                  />
                  {formErrors.first_name && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.first_name}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Phone no</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone_number"
                    placeholder="Enter the mobile number"
                    onChange={handleChange}
                    value={data.phone_number}
                    isInvalid={formErrors.phone_number}
                  />
                  {formErrors.phone_number && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.phone_number}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label htmlFor="email" className="fw-600 ">
                    Email address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email Address"
                    name="email"
                    onChange={handleChange}
                    value={data.email}
                    isInvalid={formErrors.email}
                  />

                  {formErrors.email && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.email}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label htmlFor="Password" className="fw-600">
                    Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter a Password"
                    name="password"
                    onChange={handleChange}
                    value={data.password}
                    isInvalid={formErrors.password}
                  />
                  {formErrors.password && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.password}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter a Confirm Password"
                    name="confirm_password"
                    onChange={handleChange}
                    value={data.confirm_password}
                    isInvalid={formErrors.confirm_password}
                  />
                  {formErrors.confirm_password && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.confirm_password}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Image</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Choose your image"
                    name="image"
                    accept="image/jpeg,image/png,image/gif"
                    onChange={handleImage}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Status</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="status"
                    onChange={handleChange}
                    value={data.status}
                    isInvalid={formErrors.status}
                  >
                    <option value="">Select the status</option>
                    <option value="0">Not Active</option>
                    <option value="1">Active</option>
                  </Form.Select>
                  {formErrors.status && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.status}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>

              <Row className="mb-5">
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Usertype</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="user_type"
                    onChange={handleChange}
                    value={data.user_type}
                    isInvalid={formErrors.user_type}
                  >
                    <option value="">Select the UserType</option>
                    {userType.map((item, idx) => (
                      <option key={idx} value={item.id}>
                        {item.user_type}
                      </option>
                    ))}
                  </Form.Select>
                  {formErrors.user_type && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.user_type}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              <hr></hr>
              <Button
                variant="primary"
                type="submit"
                className="shadow rounded-5"
              >
                <FontAwesomeIcon icon={faCircleCheck} /> Create user
              </Button>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserForm;
