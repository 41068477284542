import React, { useState } from "react";
import LiveInput from "./LiveInput";
import LiveUserList from "./LiveUserList";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Welcome from "./Welcome";

function LiveLoads() {
  const [selectedContact, setSelectedContact] = useState();
  const [onlineUser, setOnlineUser] = useState([]);
  const [room, setRoom] = useState();
  const [loadId, setLoadId] = useState();
  const [bRefreshList, setRefreshList] = useState(false);

  const handleSelected = (driver) => {
    setSelectedContact(driver);
  };

  const handleRoomSelected = (driver) => {
    setRoom(driver);
  };
  const handleLoadSelected = (load) => {
    setLoadId(load);
  };

  const updateRefreshStatus = () => {
    setRefreshList(!bRefreshList);
  };

  return (
    <div className="container-fluid">
      <Row style={{ height: "calc(100% - 10px)" }}>
        <Col sm={3} style={{ maxHeight: "100%" }}>
          <LiveUserList
            onlineUsers={onlineUser}
            chatChange={handleSelected}
            chatRoom={handleRoomSelected}
            chatLoad={handleLoadSelected}
            refreshList={bRefreshList}
          />
        </Col>
        <Col sm={9}>
          {selectedContact === undefined ? (
            <Welcome />
          ) : (
            <LiveInput
              chatselected={selectedContact}
              ChangeOnlineUser={setOnlineUser}
              roomId={room}
              loadId={loadId}
              refreshCallback={updateRefreshStatus}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default LiveLoads;
