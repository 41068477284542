import React from "react";
import "./loadspinner.css";

const LoadSpinner = ({ showTitle = false }) => {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
      {showTitle && <div className="loading-title">Please wait...</div>}
    </div>
  );
};

export default LoadSpinner;
