import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import CardHeader from "./../Components/CardHeader";
import { Table, Card, Col, Image, Badge, Button, Row } from "react-bootstrap";
import undraw_profile from "../../assets/images/undraw_profile.svg";
import "./tickects.css";
import { getCurrentUser, getUserDetails } from "./../services/authServices";
import { generateUserImageLink } from "../utils";
import getTimeFormated from "./../utils/timeFormat";

const TicketDetailsChat = ({ item }) => {
  const currentUser = getCurrentUser();

  const [user, setUser] = useState(undefined);

  useEffect(() => {
    setUser(undefined);
    getUserDetails(item.author).then((res) => setUser(res.data));
  }, [item]);

  const getUserAvatar = () => {
    if (!user) return undraw_profile;

    if (user.image) {
      if (user.image.length == 0) {
        return undraw_profile;
      } else {
        return generateUserImageLink(user.image);
      }
    } else {
      return undraw_profile;
    }
  };

  const getUserName = () => {
    if (!user) return "-";

    return `${user?.first_name ?? ""} ${user?.last_name ?? ""}`;
  };

  const getFulldateTime = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const time = getTimeFormated(date);
    const withHyphens = [year, month, day].join("-");
    return withHyphens + " " + time;
  };

  if (item.author === currentUser.user_id) {
    return (
      <Row className="mb-3">
        <Card className="mt-3 driver-card  w-50 manager-message  border-0 shadow-sm">
          <Card.Body className="text-end">
            <div className="ticket-chat-mine">
              <div className="ticket-avatar">
                <img
                  src={getUserAvatar()}
                  alt="userImage"
                  style={{
                    objectFit: "cover",
                    borderRadius: 25,
                  }}
                  width="50"
                  height="50"
                />

                <div className="text-muted chat_name">{getUserName()}</div>
              </div>
              <div className="ticket-comment">
                <p className="driver-fs-st">{item.message}</p>
                <small>{getFulldateTime(item.created)}</small>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Row>
    );
  } else {
    return (
      <Row className="mb-3">
        <Card className="mt-3  w-50 driver-message border-0 shadow-sm">
          <Card.Body className="text-start">
            <div className="ticket-chat-other">
              <div className="ticket-avatar">
                <img
                  src={getUserAvatar(item.author)}
                  alt="userImage"
                  style={{
                    objectFit: "cover",
                    borderRadius: 25,
                  }}
                  width="50"
                  height="50"
                />

                <div className="text-muted chat_name">
                  {getUserName(item.author)}
                </div>
              </div>
              <div className="ticket-comment">
                <p className="driver-fs-st">{item.message}</p>
                <small>{getFulldateTime(item.created)}</small>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Row>
    );
  }
};

export default TicketDetailsChat;
